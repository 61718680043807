import axios from "axios";
import React, { useState, useEffect } from "react";
import moment from "moment";

import { AVAILABLE_PRINTERS } from "../packingStation/common";
import { AlignedLabels } from "../packingStation/Layout";
import { RouteComponentProps } from "react-router-dom";
import { Loading } from "../../common";

const CURR_YEAR = moment().format("YY");

interface RouteProps {
  poNumber?: string;
}

export const ReceivingStation = (props: RouteComponentProps<RouteProps>) => {
  const [loading, setLoading] = useState(true);

  let routePoNumber = props.match.params.poNumber;
  if (routePoNumber) routePoNumber = routePoNumber.substring(2);

  const [printerInputsDirty, setPrinterInputsDirty] = useState(false);
  const [poNumber, setPoNumber] = useState(routePoNumber || CURR_YEAR);
  const [whiteLabelPrinter, setWhiteLabelPrinter] = useState("");
  const [yellowLabelPrinter, setYellowLabelPrinter] = useState("");
  const [userFeedback, setUserFeedback] = useState("");

  useEffect(() => {
    async function init() {
      const {
        data: { whiteLabelPrinter, yellowLabelPrinter },
      } = await axios.get("/api/siteConfig");

      setWhiteLabelPrinter(whiteLabelPrinter);
      setYellowLabelPrinter(yellowLabelPrinter);
      setLoading(false);
    }
    init();
  }, []);

  const savePrinters = async () => {
    try {
      setPrinterInputsDirty(false);
      await axios.post("/api/siteConfig", {
        yellowLabelPrinter,
        whiteLabelPrinter,
      });
    } catch (e) {
      console.error(e);
      setUserFeedback(e);
      alert(e);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <section>
      <div className="alert alert-info ">
        <div className=" d-flex flex-column align-items-start">
          <label>
            White Labels:
            <select
              name="whiteLabelPrinter"
              required
              className="form-control mr-2 ml-2"
              style={{ width: 250 }}
              value={whiteLabelPrinter}
              onChange={(e) => {
                setWhiteLabelPrinter(e.target.value);
                setPrinterInputsDirty(true);
              }}
            >
              <option value="">-- select-- </option>
              {AVAILABLE_PRINTERS.map((printer) => (
                <option value={printer} key={printer}>
                  {printer}
                </option>
              ))}
            </select>
          </label>
          <label>
            Yellow Labels:
            <select
              name="yellowLabelPrinter"
              className="form-control mr-2 ml-2"
              required
              style={{ width: 250 }}
              value={yellowLabelPrinter}
              onChange={(e) => {
                setYellowLabelPrinter(e.target.value);
                setPrinterInputsDirty(true);
              }}
            >
              <option value="">-- select-- </option>
              {AVAILABLE_PRINTERS.map((printer) => (
                <option value={printer} key={printer}>
                  {printer}
                </option>
              ))}
            </select>
          </label>
          <button
            onClick={savePrinters}
            className="btn btn-primary mt-1"
            disabled={!printerInputsDirty}
          >
            Save
          </button>
        </div>
      </div>

      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          if (poNumber.length < 5) return;

          props.history.push(`/stations/receiving/po/PO${poNumber}`);
        }}
      >
        <AlignedLabels>
          <strong>PO</strong>
          <input
            className="form-control"
            autoFocus
            autoComplete="off"
            type="number"
            required
            minLength={5}
            value={poNumber}
            onChange={(e) => setPoNumber(e.target.value)}
          />
        </AlignedLabels>
        {userFeedback}
        <button type="submit" className="btn btn-primary m-2" disabled={poNumber.length < 5}>
          Go
        </button>
      </form>
    </section>
  );
};
