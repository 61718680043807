import React from "react";
import PropTypes from "prop-types";
import {
  MetricTypePropTypes,
  AppContextPropTypes,
  MetricPropTypes,
} from "../../../../../propTypes";
import { Translate } from "../../../../common";

export default class ShowMetricsSummary extends React.Component<{
  metricTypes: any[];
  metrics: any;
  pass: number | null;
}> {
  static propTypes = {
    metricTypes: PropTypes.arrayOf(MetricTypePropTypes).isRequired,
    metrics: MetricPropTypes.isRequired,
    pass: PropTypes.number,
  };

  static contextTypes = AppContextPropTypes;

  render() {
    const { props } = this;

    return (
      <div>
        {props.metricTypes.map((metricType) => {
          return (
            <p key={metricType.name}>
              <strong>
                {metricType.labelPrefix}
                <Translate msg={metricType.i18nLabel} />:
              </strong>{" "}
              {props.metrics[metricType.name]}{" "}
              {metricType.i18nSuffix && <Translate msg={metricType.i18nSuffix} />}
            </p>
          );
        })}

        {props.pass !== null && (
          <div className={`alert ${props.pass ? "alert-info" : "alert-danger"}`}>
            <strong>
              <Translate msg="result" />:
            </strong>{" "}
            {props.pass ? <Translate msg="pass" /> : <Translate msg="fail" />}
            {props.pass === 0 && [<br />, <br />, <Translate msg="reworkRequired" />]}
          </div>
        )}
      </div>
    );
  }
}
