import PropTypes from "prop-types";
import React from "react";
import {
  AppContextPropTypes,
  MetricTypePropTypes,
  WorkflowComponentPropTypes,
} from "../../../../../propTypes";
import { FormMetricTypeProps, WorkflowComponentProps } from "../../../../../ts/types";
import MetricsForm from "./MetricsForm";
import ShowMetricsSummary from "./ShowMetricsSummary";

export default class DryWeightMetrics extends React.Component<
  WorkflowComponentProps & { metricTypes: FormMetricTypeProps[] }
> {
  static propTypes = {
    ...WorkflowComponentPropTypes,
    metricTypes: PropTypes.arrayOf(MetricTypePropTypes).isRequired,
  };

  static contextTypes = AppContextPropTypes;

  evaluateDryWeightMetrics = async () => {
    return { pass: 1 };
  };

  render() {
    const { props } = this;
    const { workflowState } = props;

    const visible = workflowState.serialNumber && workflowState.product;

    if (!visible) {
      return null;
    }

    const { metrics } = workflowState;

    // show the results if metrics already entered
    if (metrics) {
      return (
        <div>
          <ShowMetricsSummary
            metricTypes={props.metricTypes}
            metrics={metrics}
            pass={null} /* Dry Weight doesn't have pass/fail */
          />
        </div>
      );
    }

    // show the form
    return (
      <div>
        <MetricsForm
          {...props}
          metricTypes={props.metricTypes}
          evaluateMetrics={this.evaluateDryWeightMetrics}
        />
      </div>
    );
  }
}
