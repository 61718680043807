import axios from "axios";
import React from "react";
import styled from "styled-components";
import { LEAK_TEST_STATION_ID } from "../../../constants";
import { AppContextPropTypes } from "../../../propTypes";
import { ProductTypeApiEntity } from "../../../ts/types";
import { FatalError, Loading, Translate } from "../../common";
import { DisplayProductSummary } from "../workflows/components/ShowProductSummary";

const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  align-items: center;
`;

const Grid2 = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  gap: 10px;
  align-items: center;
`;

const initialState = {
  loading: false,
  error: null,
  feedback: null,
  fatalError: false,

  failed: false,
  missing: false,

  product: null,
  serialNumberInput: "",
  serialNumber: "",

  minStartPressureTestPsi: 0,
  startPressureTestPsi: "",
  endPressureTestPsi: "",
  pass: null,
};

type State = {
  fatalError: boolean;
  loading: boolean;
  error: null | boolean;
  feedback: string | null;

  failed: boolean;
  missing: boolean;

  product: ProductTypeApiEntity | null;
  serialNumberInput: string;
  serialNumber: string;

  minStartPressureTestPsi: number;
  pass: null | boolean;

  startPressureTestPsi: string;
  endPressureTestPsi: string;
};

// User configurable assembly line station
export default class DwellDecayStation extends React.Component<{}, State> {
  static contextTypes = AppContextPropTypes;

  nextSerialNumberRef: React.RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);

    this.nextSerialNumberRef = React.createRef<HTMLInputElement>();
    this.state = { ...initialState };
  }

  componentDidMount() {
    this.context.setPageTitle("Dwell/Decay Station");
  }

  resetState = async () => {
    this.setState({ ...initialState });
  };

  handleInputChange = (e: any) => {
    let { name, value } = e.target;
    this.setState({ [name]: value } as State);
  };

  submit = (e: React.FormEvent) => {
    e.preventDefault();

    return false;
  };

  handleSubmitSerialNumber = async (e: React.FormEvent) => {
    e.preventDefault();

    let { serialNumberInput } = this.state;
    serialNumberInput = serialNumberInput.trim();

    this.setState(initialState);
    this.setState({ serialNumber: serialNumberInput });

    const response = await axios.post(`/api/serialNumbers/validatePassed/${serialNumberInput}`, {
      payload: {
        mustHavePassed: [LEAK_TEST_STATION_ID],
      },
    });

    const { failed, missing, product } = response.data;
    const description = product ? product.description.toLowerCase() : "";

    this.setState({
      product: product as ProductTypeApiEntity,
      minStartPressureTestPsi: description.includes("summersea") ? 30 : 80,
    });

    if (failed || missing) {
      this.resetState();
      if (missing) {
        this.setState({ feedback: "Serial Number not found" });
      } else if (failed) {
        this.setState({ feedback: "Serial number failed previous setation" });
      }
    } else {
    }

    return false;
  };

  handleSubmitPressures = async (e: React.FormEvent) => {
    e.preventDefault();

    const { serialNumber, product, startPressureTestPsi, endPressureTestPsi } = this.state;
    const startPressureTestPsiF = parseFloat(startPressureTestPsi);
    const endPressureTestPsiF = parseFloat(endPressureTestPsi);

    const pressureDecayPercent =
      ((startPressureTestPsiF - endPressureTestPsiF) / startPressureTestPsiF) * 100.0;

    const MIN_ALLOWED_PRESSURE_DECAY = 0;
    const MAX_ALLOWED_PRESSURE_DECAY = 2;

    const pass =
      pressureDecayPercent >= MIN_ALLOWED_PRESSURE_DECAY &&
      pressureDecayPercent <= MAX_ALLOWED_PRESSURE_DECAY;

    if (!product) {
      throw new Error();
    }

    const ptid = product.ptid;

    try {
      await axios.post(`/api/stations/dwellDecayTest/${serialNumber}`, {
        payload: {
          ptid,
          startPressureTestPsi: startPressureTestPsiF,
          endPressureTestPsi: endPressureTestPsiF,
          pass,
        },
      });

      this.setState({ pass });

      this.nextSerialNumberRef.current && this.nextSerialNumberRef.current.focus();
    } catch (e) {
      this.setState({ fatalError: true });
    }

    return false;
  };

  render() {
    const {
      loading,
      error,
      feedback,

      serialNumber,
      serialNumberInput,
      product,

      minStartPressureTestPsi,
      startPressureTestPsi,
      endPressureTestPsi,
      pass,
    } = this.state;

    return (
      <div>
        {error && <FatalError />}
        {loading && <Loading />}

        {feedback && <div className="alert alert-warning">{feedback}</div>}

        <section>
          {serialNumber === "" && (
            <form onSubmit={this.handleSubmitSerialNumber}>
              <Grid>
                <label>Serial Number: </label>
                <input
                  type="text"
                  required
                  autoFocus
                  className="form-control-sm"
                  minLength={1}
                  maxLength={30}
                  value={serialNumberInput}
                  name="serialNumberInput"
                  onChange={this.handleInputChange}
                />
                <button className="btn btn-primary">Submit</button>
              </Grid>
            </form>
          )}

          {product && (
            <div>
              <DisplayProductSummary product={product} />

              <div className="d-flex align-items-center"></div>

              {pass === null && (
                <form onSubmit={this.handleSubmitPressures}>
                  <Grid>
                    <strong>Serial Number: </strong>
                    <div>{serialNumber}</div>
                    <span />

                    <strong>Start Test Pressure:</strong>
                    <input
                      type="number"
                      autoFocus
                      required
                      className="form-control-sm"
                      min={minStartPressureTestPsi}
                      step={0.00001}
                      value={startPressureTestPsi}
                      name="startPressureTestPsi"
                      onChange={this.handleInputChange}
                    />
                    <span />

                    <strong>End Test Pressure:</strong>
                    <input
                      type="number"
                      required
                      className="form-control-sm"
                      min={0}
                      step={0.00001}
                      value={endPressureTestPsi}
                      name="endPressureTestPsi"
                      onChange={this.handleInputChange}
                    />
                    <button type="submit" className="btn btn-primary">
                      <Translate msg="submit" />
                    </button>
                  </Grid>
                </form>
              )}

              {pass !== null && (
                <div>
                  <Grid2>
                    <strong>Start Test Pressure: </strong>
                    <div> {startPressureTestPsi}</div>
                    <strong>End Test Pressure: </strong>
                    <div> {endPressureTestPsi}</div>
                    <strong>Decay %: </strong>
                    <div>
                      {(
                        ((parseFloat(startPressureTestPsi) - parseFloat(endPressureTestPsi)) /
                          parseFloat(startPressureTestPsi)) *
                        100.0
                      ).toFixed(3)}
                    </div>
                    <strong>Pass: </strong> <div>{pass ? "True" : "False"}</div>
                  </Grid2>

                  <form onSubmit={this.handleSubmitSerialNumber}>
                    <Grid>
                      <label>Next Serial Number: </label>
                      <input
                        ref={this.nextSerialNumberRef}
                        type="text"
                        required
                        autoFocus
                        className="form-control-sm"
                        minLength={1}
                        maxLength={30}
                        value={serialNumberInput}
                        name="serialNumberInput"
                        onChange={this.handleInputChange}
                      />
                      <button className="btn btn-primary">Submit</button>
                    </Grid>
                  </form>
                </div>
              )}
            </div>
          )}
        </section>
      </div>
    );
  }
}
