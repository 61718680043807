import { ProductConfig } from "../../../ts/types";

export const DEBUG_PRINTER = "Debug";

export const AVAILABLE_PRINTERS = [
  "Zebra4",
  "Zebra5",
  "Zebra6",
  "Zebra7",
  "Zebra8",
  "Zebra9",
  "Zebra10",
  "Zebra105SL",
  "Zebra11",
  "Zebra12",
  "ZebraPrime ZT230 Office",
  "Operations HP – Laserjet M4555 MFP",
  "Receiving Team Printer",
  "RCV Yellow Labels",
  "ReceivingYellow",
  "ReceivingWhite",
  DEBUG_PRINTER,
] as const;

export const calculateBoxWeight = ({
  productConfig,
  quantity,
}: {
  productConfig: ProductConfig;
  quantity: number;
}): number => {
  const { unit_weight, tray_weight, box_weight, unitsPerBox, unitsPerTray } = productConfig;

  // trays are always packed even in half full boxes
  const totalTrayWeights = tray_weight * (unitsPerBox / unitsPerTray);

  const totalUnitWeight = unit_weight * quantity;

  const totalBoxWeight = box_weight + totalTrayWeights + totalUnitWeight;

  return totalBoxWeight;
};

export const calculateFullBoxWeight = ({ productConfig }: { productConfig: ProductConfig }) => {
  const { unitsPerBox } = productConfig;

  return calculateBoxWeight({ productConfig, quantity: unitsPerBox });
};

export const calculatePalletWeight = ({
  productConfig,
  boxes,
}: {
  productConfig: ProductConfig;
  boxes: { quantity: number }[];
}) => {
  const { pallet_weight } = productConfig;

  let totalWeight = pallet_weight;

  for (let box of boxes) {
    if (!box.quantity) {
      alert("error");
    }

    totalWeight += calculateBoxWeight({ productConfig, quantity: box.quantity });
  }

  return totalWeight;
};
