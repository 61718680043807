import ChangeProduct from "./ChangeProduct";
import NextSerialNumberForm from "./NextSerialNumberForm";
import SetProductByBarcode from "./SetProductByBarcode";
import SetProductBySerialNumber from "./SetProductBySerialNumber";
import SetSerialNumber from "./SetSerialNumber";
import ShowProductSummary from "./ShowProductSummary";

import MetricsForm from "./metrics/MetricsForm";
import LeakTestMetrics from "./metrics/LeakTestMetrics";
import DryWeightMetrics from "./metrics/DryWeightMetrics";
import { AlignmentTestMetrics } from "./metrics/AlignmentTestMetrics";
import { AccousticTestMetrics } from "./metrics/AccousticTestMetrics";
import { DwellDecayMetrics } from "./metrics/DwellDecayMetrics";
import WetWeightMetrics from "./metrics/WetWeightMetrics";
import WetWeightHints from "./metrics/WetWeightHints";
import FunctionalTestMetrics from "./metrics/FunctionalTestMetrics";
import FunctionalTestHints from "./metrics/FunctionalTestHints";
import SubPartMetrics from "./metrics/SubPartMetrics";
import FailureReasonEntryMetrics from "./metrics/FailureReasonEntryMetrics";
import { RepairStationMetrics } from "./metrics/RepairStationMetrics";
import NoiseTestMetrics from "./metrics/NoiseTestMetrics";
import NoiseTestHints from "./metrics/NoiseTestHints";
import FinalInspectionMetrics from "./metrics/FinalInspectionMetrics";
import { Dict } from "../../../../ts/types";

export const WorkflowComponent: Dict<any> = {
  ChangeProduct,
  NextSerialNumberForm,
  SetProductByBarcode,
  SetProductBySerialNumber,
  SetSerialNumber,
  ShowProductSummary,
  MetricsForm,

  LeakTestMetrics,
  DryWeightMetrics,
  AlignmentTestMetrics,
  AccousticTestMetrics,
  DwellDecayMetrics,
  WetWeightMetrics,
  WetWeightHints,
  FunctionalTestMetrics,
  FunctionalTestHints,
  SubPartMetrics,
  FailureReasonEntryMetrics,
  RepairStationMetrics,
  NoiseTestMetrics,
  NoiseTestHints,
  FinalInspectionMetrics,
};

export default WorkflowComponent;
