import PropTypes from "prop-types";
import React from "react";
import { AppContextPropTypes, WorkflowComponentPropTypes } from "../../../../../propTypes";
import {
  FormMetricTypeProps,
  FormSubmitData,
  WorkflowComponentProps,
} from "../../../../../ts/types";
import { Translate } from "../../../../common";
import MetricsForm from "./MetricsForm";
import ShowMetricsSummary from "./ShowMetricsSummary";

type Props = WorkflowComponentProps & {
  entryType: string;
};

export default class SubPartMetrics extends React.Component<Props> {
  static propTypes = {
    ...WorkflowComponentPropTypes,
    entryType: PropTypes.oneOf(["coldplates", "subpart"]),
  };

  static contextTypes = AppContextPropTypes;

  state: { imageNotFound: boolean } = { imageNotFound: false };

  handleMetricsSubmit = ({ metrics, pass }: FormSubmitData) => {
    const { props } = this;
    props.workflowApi.updateState({ metrics, pass });
  };

  validate = async (metrics: { metrics: FormSubmitData }) => {
    const uniqueValues = new Set();

    console.log("validating uniqueness", metrics);

    for (const [, value] of Object.entries(metrics)) {
      if (value && uniqueValues.has(value)) {
        return ["errors.duplicateEntry", value];
      }
      uniqueValues.add(value);
    }
    return null;
  };

  handleImageError = () => {
    this.setState({ imageNotFound: true });
  };

  render() {
    const { props, state, context } = this;
    const { workflowState } = props;

    const shouldRender = workflowState.product && workflowState.serialNumber;

    if (!shouldRender) {
      return null;
    }

    const { i18n } = context;
    const { product } = workflowState;

    const metricTypes: FormMetricTypeProps[] = [];

    if (props.entryType === "coldplates") {
      // one entry for each of the 'heads' (product.headcount)
      const headcount = product.headcount || 0;

      for (let i = 0; i < headcount; i++) {
        metricTypes.push({
          i18nLabel: "",
          labelPrefix: `${i18n("metrics.coldPlate")} ${i + 1}`,
          type: "text",
          pattern: product.sp_sn_pattern || undefined,
          name: `subpart${i + 1}`,
          required: true,
          autoFocus: i === 0,
          widthPx: 300,
        });
      }
    } else if (props.entryType === "subpart") {
      //  one entry only
      metricTypes.push({
        i18nLabel: "metrics.partSerialNumber",
        type: "text",
        pattern: product.sp_sn_pattern || undefined,
        name: `subpart1`,
        required: true,
        autoFocus: true,
        widthPx: 300,
      });
    } else {
      throw new Error("unknown entry type" + props.entryType);
    }

    return (
      <section>
        <p>
          <strong>
            <Translate msg="metrics.formatPattern" />:
          </strong>{" "}
          {product.sp_sn_pattern}
        </p>
        {props.entryType === "coldplates" && (
          <p>
            <strong>Number of Heads:</strong> {product.headcount}
          </p>
        )}
        {workflowState.metrics ? (
          <ShowMetricsSummary
            metricTypes={metricTypes}
            metrics={workflowState.metrics}
            pass={workflowState.pass}
          />
        ) : (
          <MetricsForm
            {...props}
            validateData={this.validate}
            evaluateMetrics={async () => ({ pass: 1 })}
            metricTypes={metricTypes}
          />
        )}
        {props.entryType === "coldplates" && (
          <div>
            {state.imageNotFound ? (
              <div className="alert alert-warning">
                Warning: This product has no image configured.
              </div>
            ) : (
              <img
                style={{ width: "85%" }}
                src={`/api/images/${product.imageurl}-scanorder`}
                onError={this.handleImageError}
                alt="scan order locations"
              />
            )}
          </div>
        )}
      </section>
    );
  }
}
