import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import { PrintedItemsContext } from "./PrintedItems";
import { PrintReceivedItem } from "./PrintReceivedItem";
import { OrderLineItem } from "../../../ts/types";

/* 
    PO examples for testing:
    =-=-=-=

    PO 1909611
    PO 1909440
    PO 1909080 (2 items)
    PO 1909272 ( many items)
    PO 1909352 ( many items  - many duplicates)
*/

const SectionWrapper = styled.section``;

const TableWrapper = styled.div`
  font-size: smaller !important;

  table tbody:nth-child(odd) {
    background-color: #ececec;
  }

  td {
    border: none;
  }

  .number {
    text-align: right !important;
  }

  .fulfilled {
    background-color: lightgreen !important;
  }
`;

interface RouteProps {
  poNumber: string;
}

export const PurchaseOrderStation = (props: RouteComponentProps<RouteProps>) => {
  const { poNumber } = props.match.params;

  const [error, setError] = useState("");
  const [items, setItems] = useState<OrderLineItem[] | null>(null);
  const [selectedItem, setSelectedItem] = useState<OrderLineItem | null>(null);
  const [printedItemsState] = useContext(PrintedItemsContext);

  useEffect(() => {
    const loadPurchaseOrder = async () => {
      if (!poNumber) return;

      try {
        const { data: items }: any = await axios.get(`/api/netSuite/purchaseOrder/${poNumber}`);

        console.log("items", items);

        setItems(items as OrderLineItem[]);
      } catch (e) {
        console.log(e);
        setError("Error querying netsuite:" + e.toString());
      }
    };

    loadPurchaseOrder();
  }, [poNumber]);

  if (items === null) {
    return <div>Querying netsuite, please wait&hellip;</div>;
  }

  if (!items.length) {
    return (
      <div>
        Purchase order <strong>{poNumber}</strong> not found!
        <br />
        <br />
        <Link className="btn btn-primary" to={`/stations/receiving/${poNumber}`}>
          Back
        </Link>
      </div>
    );
  }

  if (selectedItem) {
    return (
      <div>
        <PrintReceivedItem {...selectedItem} />
        <br />
        <br />

        <button className="btn btn-secondary" onClick={() => setSelectedItem(null)}>
          Back to PO {poNumber}
        </button>
      </div>
    );
  }

  const visibleItems = items.filter((i) => i.itemCount > 0);
  const expenseItemCount = items.length - visibleItems.length;

  return (
    <SectionWrapper>
      <div className="d-flex align-center">
        <h5 className="mr-3">
          <strong>Purchase Order: </strong>
          {poNumber}
        </h5>
        <Link to="/stations/receiving">[change]</Link>
      </div>
      <small>Expense items not shown: {expenseItemCount}</small>
      <TableWrapper>
        <table className="table table-sm " style={{ maxWidth: 1000 }}>
          <thead className="thead-light">
            <tr>
              <th>Part Number</th>
              <th style={{ width: 50 }}>Rev.</th>
              <th style={{ width: 140 }} className="number">
                Ordered
              </th>
              <th style={{ width: "auto" }} className="number">
                Received
              </th>
            </tr>
          </thead>

          {visibleItems.map((item) => {
            const itemKey = `${item.poNumber}/${item.id}`;

            const isAlreadyPrinted = printedItemsState && printedItemsState.items.includes(itemKey);

            return (
              <tbody key={item.id} className={item.received >= item.ordered ? "fulfilled" : ""}>
                <tr>
                  <td className="text-nowrap">{item.partNumber}</td>
                  <td>{item.revision}</td>
                  <td className="number">{item.ordered}</td>
                  <td className="number">{item.received}</td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    {item.description}
                    <br />
                    Expected:
                    {item.expectedReceiptDate &&
                      moment.utc(item.expectedReceiptDate).format("YYYY-MM-DD")}
                  </td>
                  <td>
                    <button
                      onClick={() => setSelectedItem(item)}
                      className={`btn btn-sm ${
                        isAlreadyPrinted ? "btn-secondary" : "btn-primary"
                      } ml-3 mt-2`}
                    >
                      {isAlreadyPrinted ? "Printed" : "Print"}
                    </button>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </TableWrapper>
      <div>{error}</div>
    </SectionWrapper>
  );
};
