import _ from "lodash";

import en, { AppLocale } from "./en"; // english
import cn from "./cn"; // simplified chinese
import zh from "./zh"; // traditional chinese
import { Dict } from "../ts/types";

export const TRANSLATIONS: Dict<AppLocale> = {
  en,
  cn,
  zh,
};

export let currentLocale = "cn-en";

export function translate(locale: string, msg: string, ...args: string[] | number[]) {
  if (msg === "") {
    return "";
  }
  let result = _.get(TRANSLATIONS[locale], msg);
  if (!result) {
    return `Missing: '${msg}'`;
  }

  result = result.replace(/\$1/g, args[0]);
  result = result.replace(/\$2/g, args[1]);
  result = result.replace(/\$3/g, args[2]);

  return result;
}

/**
 * Main translation function
 * @param key The translation key to look up
 * @param args Any additional parameters to be formatted into the result
 * @returns {string}
 */
export default function i18n(key: string, ...args: any) {
  if (key === "") {
    return "";
  }
  let result = "";

  // show chinese and english (current functionality)
  if (currentLocale === "cn-en") {
    // Note: _.get allows keys like 'parent.child' to lookup nested object value
    const cn = translate("cn", key, ...args);
    const en = translate("en", key, ...args);
    result = cn && en && `${cn} (${en})`;
  } else {
    result = translate(currentLocale, key, ...args);
  }

  return result;
}

export function setLocale(locale: string) {
  currentLocale = locale;
}
