import axios from "axios";
import React from "react";
import PropTypes from "prop-types";

import Form from "../common/form/Form";
import { WorkflowComponentPropTypes, AppContextPropTypes } from "../../../../propTypes";
import { ErrorMessage, Loading, Translate } from "../../../common";
import { WorkflowComponentProps, FormSubmitData } from "../../../../ts/types";

/**
 * Validates that a serial number exists and has passed the specified previous stations
 */

export default class SetProductBySerialNumber extends React.Component<
  WorkflowComponentProps & {
    mustHavePassed: boolean;
    showOnlyWhenDone: boolean;
  }
> {
  static propTypes = {
    ...WorkflowComponentPropTypes,

    mustHavePassed: PropTypes.arrayOf(PropTypes.number).isRequired,
    showOnlyWhenDone: PropTypes.bool,
  };

  static contextTypes = AppContextPropTypes;

  state = {
    loading: false,
    failed: null,
    missing: null,
  };

  handleFormSubmit = async (formData: FormSubmitData) => {
    const { props } = this;
    const { serialNumber } = formData;

    this.setState({ failed: null, missing: null, loading: true });

    try {
      const response = await axios.post(`/api/serialNumbers/validatePassed/${serialNumber}`, {
        payload: {
          mustHavePassed: this.props.mustHavePassed,
        },
      });

      const { failed, missing, product } = response.data;

      if (failed || missing) {
        this.setState({ failed, missing });
      } else {
        if (!product) {
          throw new Error("missing product");
        }
        props.workflowApi.resetState();

        props.workflowApi.updateState({
          serialNumber,
          product,
        });
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { props, state } = this;
    const { workflowState } = props;

    const i18nLabel = props.showOnlyWhenDone ? "nextSerialNumber" : "serialNumber";

    let visible = null;

    if (props.showOnlyWhenDone) {
      visible = !!workflowState.metrics;
    } else {
      visible = !workflowState.serialNumber;
    }

    if (!visible) {
      return null;
    }

    return (
      <div>
        <Form
          inputs={[
            {
              i18nLabel,
              type: "text",
              name: "serialNumber",
              required: true,
              pattern: ".{3,30}",
              size: 30,
              autoComplete: "off",
            },
          ]}
          trimInputs={true}
          onSubmit={this.handleFormSubmit}
        />
        {state.loading && <Loading />}
        {state.failed && (
          <ErrorMessage>
            <Translate msg="errors.productFailedPrevious" />
          </ErrorMessage>
        )}
        {state.missing && (
          <ErrorMessage>
            <Translate msg="errors.productNotTested" />
          </ErrorMessage>
        )}
      </div>
    );
  }
}
