import Axios from "axios";
import React, { useEffect, useState } from "react";
import {
  DRY_WEIGHT_STATION_ID,
  LEAK_TEST_STATION_ID,
  WET_WEIGHT_STATION_ID,
} from "../../../constants";
import { NewTestResult, ProductTypeApiEntity, TestRow } from "../../../ts/types";
import { Translate } from "../../common";
import { DisplayProductSummary } from "../workflows/components/ShowProductSummary";

export const CorsairStation: React.FC = () => {
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [tests, setTests] = useState<TestRow[] | null>(null);
  const [testResults, setTestResults] = useState<(boolean | null)[]>([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [product, setProduct] = useState<ProductTypeApiEntity | null>(null);
  const [passed, setPassed] = useState<boolean | null>(null);

  const reset = async () => {
    const { data: tests } = await Axios.get<TestRow[]>("/api/tests");
    setSerialNumber("");
    setTests(tests);
    setTestResults(new Array(tests.length).fill(true));
    setLoaded(false);
    setProduct(null);
    setPassed(null);
  };

  useEffect(() => {
    reset();
  }, []);

  const loadSerialNumber = async () => {
    setLoaded(true);
    setError("");

    try {
      const response = await Axios.post(`/api/serialNumbers/validatePassed/${serialNumber}`, {
        payload: {
          mustHavePassed: [LEAK_TEST_STATION_ID, DRY_WEIGHT_STATION_ID, WET_WEIGHT_STATION_ID],
        },
      });

      const { failed, missing, product } = response.data;

      if (failed) {
        setError("Serial number failed previous station, send to repair station.");
      } else if (missing) {
        setError("Serial number not found.");
      } else {
        setProduct(product);
      }
    } catch (e) {
      setError("Error");
    } finally {
    }
  };

  const saveTestResults = async () => {
    if (!tests) throw new Error();

    const payload: NewTestResult[] = tests.map((test, index) => {
      return {
        test_id: test.id,
        user_id: 100,
        pass: testResults[index] ? 1 : 0,
        serialnumber: serialNumber,
        number_value: null,
        text_value: null,
      };
    });

    setLoading(true);
    try {
      await Axios.post<null>("/api/test_results", {
        payload,
      });
      setLoading(false);

      setPassed(payload.every((p) => p.pass === 1));
    } catch (e) {
      setError("Error");
    } finally {
      setLoading(false);
    }
  };

  if (!tests) return <div>Loading...</div>;

  if (!loaded || !product) {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          loadSerialNumber();
        }}
      >
        Serial Number: &nbsp;
        <input
          className="ml-3"
          autoFocus
          autoCapitalize="false"
          autoComplete="false"
          type="text"
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value)}
        />
        <button className="ml-3 btn btn-primary" type="submit">
          Go
        </button>
        {error && <div className="alert alert-warning">{error}</div>}
      </form>
    );
  }

  return (
    <div>
      <DisplayProductSummary product={product} />

      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveTestResults();
        }}
      >
        <h5>Serial Number: {serialNumber}</h5>
        <div style={{ display: "inline-grid", gridTemplateColumns: "auto auto auto", gridGap: 15 }}>
          {tests.map((test, index) => (
            <>
              <div className="mr-3">{test.name}</div>

              <label className="mr-3">
                <input
                  type="radio"
                  checked={testResults[index] === true}
                  name={`radio_${test.id}`}
                  style={{ transform: "scale(1.5)" }}
                  onChange={() => {
                    const updated = [...testResults];
                    updated[index] = true;
                    setTestResults(updated);
                  }}
                />
                &nbsp; <Translate msg="pass" />
              </label>

              <label className="mr-3">
                <input
                  type="radio"
                  checked={testResults[index] === false}
                  name={`radio_${test.id}`}
                  style={{ transform: "scale(1.5)" }}
                  onChange={() => {
                    const updated = [...testResults];
                    updated[index] = false;
                    setTestResults(updated);
                  }}
                />
                &nbsp; <Translate msg="fail" />
              </label>
            </>
          ))}
        </div>

        {passed !== null && (
          <div className={`alert ${passed ? "alert-info" : "alert-danger"}`}>
            {passed ? " Passed" : " Failed. Please send to rework station."}
            <br />
            <button
              autoFocus
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();

                reset();
              }}
            >
              Next
            </button>
          </div>
        )}
        {passed === null && (
          <div className="mt-3">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading || testResults.includes(null)}
            >
              Submit
            </button>
            {loading && "Please wait..."}

            <button
              type="button"
              className="btn ml-3"
              onClick={() => {
                reset();
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
    </div>
  );
};
