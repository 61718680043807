import React from "react";
import { AppContextPropTypes } from "../../../../propTypes";
import { FormSubmitData, WorkflowComponentProps } from "../../../../ts/types";
import Form from "../common/form/Form";

export default class NextSerialNumberForm extends React.Component<
  WorkflowComponentProps & { resetProduct?: boolean }
> {
  static contextTypes = AppContextPropTypes;

  validateSerialNumber = (formData: FormSubmitData) => {
    const { props } = this;

    const { product } = props.workflowState;
    const { serialNumber } = formData;

    // always allow serial numbers 100-199 for testing
    const serialNumberWhitelist = /^1[0-9][0-9]$/;
    if (serialNumberWhitelist.test(serialNumber)) {
      return;
    }

    const sn_pattern = product.sn_pattern || "[a-zA-Z0-9]{3,30}";

    const serialNumberRegex = RegExp(`^${sn_pattern}$`);
    const result = serialNumberRegex.test(serialNumber);

    if (!result) {
      const validationErrorArgs = ["invalidFormat", product.sn_pattern];
      return validationErrorArgs;
    }
  };

  handleFormSubmit = ({ serialNumber }: { serialNumber: string }) => {
    const { props } = this;

    const product = props.workflowState.product;

    props.workflowApi.resetState();
    props.workflowApi.updateState({ product, serialNumber });
  };

  render() {
    const { props } = this;
    const { workflowState } = props;

    const visible = typeof workflowState.pass !== "undefined";

    if (!visible) {
      return null;
    }

    return (
      <Form
        noValidate={true}
        inputs={[
          {
            i18nLabel: "nextSerialNumber",
            type: "text",
            name: "serialNumber",
            required: true,
            pattern: workflowState.product.sn_pattern || ".{3,30}",
            size: 30,
            autoComplete: "off",
            autoFocus: true,
          },
        ]}
        trimInputs={true}
        validateData={this.validateSerialNumber}
        onSubmit={this.handleFormSubmit}
      />
    );
  }
}
