import axios from "axios";
import React from "react";

import { WorkflowComponentPropTypes, AppContextPropTypes } from "../../../../../propTypes";
import { FatalError, Translate } from "../../../../common";
import { WorkflowComponentProps, Dict } from "../../../../../ts/types";
import { currentLocale } from "../../../../../i18n";

const initialState = {
  reason: "",
  reasonOther: "",
  error: false,
  loading: false,
};

type State = {
  reason: string | null;
  reasonOther: string;
  error: boolean;
  loading: boolean;
};

export const failureReasons: string[] = [
  "Leak",
  "Coldplate defective",
  "Functional defective",
  "Tube defective",
  "Bad or incorrect label",
  "Radiator defective",
  "TIM damaged",
  "Bad Threads",
  "Assembled the wrong way",
  "Component felt off",
  "Other Cosmetic Issues",
  "Missing or Loose Component",
  "Subpart Entry Error",
  "Station Sequence Order Error",
  "Child Serial Entry Error",
  "Other",
];

export const reasonsTradChinese: Dict<string> = {
  Leak: "洩漏",
  "Coldplate defective": "冷板不良",
  "Functional defective": "功能缺陷",
  "Tube defective": "管子有缺陷",
  "Bad or incorrect label": "標籤不良或不正確",
  "Radiator defective": "散熱器損壞",
  "TIM damaged": "TIM損壞",
  "Bad Threads": "壞線程",
  "Assembled the wrong way": "組裝方式錯誤",
  "Component felt off": "組件感到脫落",
  "Other Cosmetic Issues": "其他化妝品問題",
  "Missing or Loose Component": "組件缺失或鬆動",
  "Subpart Entry Error": "子部件輸入錯誤",
  "Station Sequence Order Error": "站順序順序錯誤",
  "Child Serial Entry Error": "子序列輸入錯誤",
  Other: "其他",
};

export const reasonsSimplifiedChinese: Dict<string> = {
  Leak: "洩漏",
  "Coldplate defective": "冷却板不良",
  "Functional defective": "功能缺陷",
  "Tube defective": "灯管不良",
  "Bad or incorrect label": "标签错误或不正确",
  "Radiator defective": "散热器不良",
  "TIM damaged": "TIM损坏",
  "Bad Threads": "坏线程",
  "Assembled the wrong way": "组装方式错误",
  "Component felt off": "组件脱落",
  "Other Cosmetic Issues": "其他化妆品问题",
  "Missing or Loose Component": "组件缺失或松动",
  "Subpart Entry Error": "子零件输入错误",
  "Station Sequence Order Error": "电台序列顺序错误",
  "Child Serial Entry Error": "子序列输入错误",
  Other: "其他",
};

export default class FailureReasonEntryMetrics extends React.Component<
  WorkflowComponentProps,
  State
> {
  static propTypes = {
    ...WorkflowComponentPropTypes,
  };

  static contextTypes = AppContextPropTypes;

  state = initialState;

  save = async (e: React.FormEvent) => {
    e.preventDefault();

    const { props, state, context } = this;

    const { reason, reasonOther } = state;

    const metrics = {
      reason,
      reasonOther: reasonOther ? reasonOther : null,
    };

    const pass = 1; // repair station always is a 'pass'

    try {
      const response = await axios.post(
        `/api/serialNumbers/${props.workflowState.serialNumber}/metrics`,
        {
          payload: {
            stationId: props.workflowProps.station.id,
            userId: context.user.uid,
            productId: props.workflowState.product.ptid,
            pass,
            metrics,
          },
        }
      );

      const result = response.data;

      if (result.status !== "success") {
        throw new Error("success expected");
      }

      props.workflowApi.updateState({ metrics, pass });
      this.setState(initialState);
    } catch (e) {
      this.setState({ error: true });
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { props, state } = this;
    const { workflowState } = props;

    const visible = workflowState.product && workflowState.serialNumber;

    if (!visible) {
      return null;
    }

    const formIncomplete = !state.reason || (state.reason === "Other" && !state.reasonOther);

    // if metrics already entered, show the summary
    if (workflowState.metrics) {
      return (
        <div>
          <p>
            <strong>
              <Translate msg="metrics.failureReason" />:{" "}
            </strong>
            {workflowState.metrics.reason}{" "}
            {currentLocale.includes("cn") && reasonsTradChinese[workflowState.metrics.reason]}
            {currentLocale.includes("zh") && reasonsSimplifiedChinese[workflowState.metrics.reason]}
          </p>
        </div>
      );
    }

    // otherwise, show the form
    return (
      <div className="">
        <form className="form" onSubmit={this.save}>
          <div className="form-group mb-2 d-flex align-items-center">
            <label className="mr-1">
              <strong>
                <Translate msg="metrics.failureReason" />:
              </strong>
            </label>

            <select
              style={{ maxWidth: "360px " }}
              className="form-control"
              name="reason"
              value={state.reason}
              onChange={(e) => this.setState({ reason: e.target.value })}
            >
              <option value={""}>-- select --</option>
              {failureReasons.map((failureReason) => (
                <option key={failureReason} value={failureReason}>
                  {currentLocale.includes("en") && failureReason}{" "}
                  {currentLocale.includes("cn") && reasonsTradChinese[failureReason]}
                  {currentLocale.includes("zh") && reasonsSimplifiedChinese[failureReason]}
                </option>
              ))}
            </select>
          </div>
          {state.reason === "Other" && (
            <div className="form-group mb-2">
              <label className="mr-1">
                <strong>
                  <Translate msg="details" />:
                </strong>
              </label>
              <br />
              <textarea
                cols={60}
                rows={5}
                name="reasonOther"
                onChange={(e) => this.setState({ reasonOther: e.target.value })}
                value={state.reasonOther}
                required
              />
            </div>
          )}
          <div>
            <button className="btn btn-primary m-3" disabled={formIncomplete}>
              <Translate msg="submit" />
            </button>
          </div>
        </form>

        {state.error && <FatalError />}
      </div>
    );
  }
}
