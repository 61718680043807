import moment from "moment";
import React, { useEffect, useState } from "react";
import { ClientApi } from "../../../../../clientApi/ClientApi";
import { DWELL_TEST_STATION_ID } from "../../../../../constants";
import {
  DwellTestMetrics,
  IsoDateString,
  ProductTypeApiEntity,
  WorkflowComponentProps,
} from "../../../../../ts/types";
import { Translate } from "../../../../common";

const initialResults: DwellTestMetrics = {
  starting_time: null,
  starting_temp: null,
  starting_pressure: null,
  final_time: null,
  final_temp: null,
  final_pressure: null,
  temp_adjusted_pressure_delta: null,
};

const toKelvin = (celsius: number) => {
  return celsius + 273.15;
};

const checkPass = (results: DwellTestMetrics, config: ProductTypeApiEntity): [boolean, string] => {
  const {
    starting_time,
    starting_pressure,
    starting_temp,
    final_time,
    final_temp,
    final_pressure,
  } = results;

  if (!final_pressure || !starting_pressure) return [false, "Missing pressures"];
  if (!final_temp || !starting_temp) return [false, "Missing ambient temperature inputs"];
  if (!starting_time || !final_time) return [false, "Missing times"];

  const duration = moment.duration(moment(final_time).diff(moment(starting_time)));
  if (duration.asMinutes() < config.dwell_duration_minutes)
    return [false, `Dwell duration too short: ${duration} hours`];

  if (Math.abs(config.dwell_start_psi - starting_pressure) > config.dwell_start_psi_tolerance)
    return [
      false,
      `Start pressure out of bounds.
      ${config.dwell_start_psi} +/- ${config.dwell_start_psi_tolerance} psi`,
    ];

  const delta = Math.abs(starting_pressure - final_pressure);
  if (delta > config.dwell_end_max_psi_drop) {
    return [false, `Pressure drop above ${config.dwell_end_max_psi_drop} psi`];
  }

  return [true, ""];
};

type CachedAmbient = {
  at: IsoDateString;
  temperature: number;
};

const getCachedAmbient = (): number | null => {
  const json = localStorage.getItem("cachedAmbient");
  const ambient = json ? (JSON.parse(json) as CachedAmbient) : null;

  if (ambient && moment.utc(ambient.at).isAfter(moment.utc().subtract(2, "hours"))) {
    return ambient.temperature;
  }
  return null;
};

const setCachedAmbient = (val: CachedAmbient) => {
  localStorage.setItem("cachedAmbient", JSON.stringify(val));
};

export const getAdjustedDelta = ({
  starting_pressure,
  final_pressure,
  starting_temp,
  final_temp,
}: DwellTestMetrics): number | null => {
  if (!starting_pressure || !final_pressure || !final_temp || !starting_temp) return null;

  //  PV=nRT  -> P2 = P1 * (T2 / T1)
  return (starting_pressure - final_pressure) * (toKelvin(final_temp) / toKelvin(starting_temp));
};

export const DwellDecayMetrics: React.FC<WorkflowComponentProps> = ({
  workflowState,
  workflowApi,
  workflowProps,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [metrics, setMetrics] = useState(initialResults);
  const [pass, setPass] = useState<boolean | null>(null);
  const [failReason, setFailReason] = useState("");

  const [stage, setStage] = useState<null | "start" | "final" | "finished">(null);

  const reset = () => {
    setMetrics({
      ...initialResults,
    });
  };

  const loadMetrics = async () => {
    if (!workflowState.serialNumber) throw new Error("null");

    try {
      const row = await ClientApi.SerialNumberMetrics.getLatestResults<DwellTestMetrics>(
        workflowState.serialNumber,
        DWELL_TEST_STATION_ID
      );

      if (row && row.metrics.final_pressure === null) {
        setMetrics({ ...row.metrics, final_temp: getCachedAmbient() });
        setStage("final");
      } else {
        setStage("start");
        setMetrics({ ...initialResults, starting_temp: getCachedAmbient() });
      }
    } catch (e) {
      console.error(e);
      setErrorMessage("Unexpected error");
    }
  };

  useEffect(() => {
    if (workflowState.serialNumber) {
      loadMetrics();
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowState.serialNumber]);

  const { product } = workflowState;
  const visible = workflowState.serialNumber && product;
  if (!visible) {
    return null;
  }

  const save = async (): Promise<void> => {
    try {
      if (!workflowState.serialNumber) throw new Error("serialNumber null");

      const metricsToSave = { ...metrics };

      const now = new Date().toISOString();

      if (metrics.starting_time === null) {
        metricsToSave.starting_time = now;
      } else {
        if (!metrics.final_temp === null || !metrics.final_pressure) {
          throw new Error("");
        }
        metricsToSave.final_time = now;
        metricsToSave.temp_adjusted_pressure_delta = getAdjustedDelta(metrics);

        setStage("finished");
      }
      setMetrics(metricsToSave);

      const [passResult, failReasonResult] = checkPass(metricsToSave, product);
      setPass(passResult);
      setFailReason(failReasonResult);

      await ClientApi.SerialNumberMetrics.save<DwellTestMetrics>(workflowState.serialNumber, {
        stationId: workflowProps.station.id,
        userId: 100, // TODO: context.user.uid,
        productId: workflowState.product.ptid,
        pass: passResult,
        metrics: metricsToSave,
      });

      window.localStorage.setItem("decayStation_ambientTime", JSON.stringify(now));
      window.localStorage.setItem(
        "decayStation_ambientTemp",
        JSON.stringify(metrics.starting_temp)
      );

      workflowApi.updateState({ metrics: metrics, pass: passResult ? 1 : 0 });

      reset();
    } catch (e) {
      setErrorMessage("Error");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  if (stage === "finished") {
    return (
      <div>
        <div className={`alert ${pass ? "alert-info" : "alert-danger"}`}>
          <strong>
            <Translate msg="result" />:
          </strong>{" "}
          {pass ? <Translate msg="pass" /> : <Translate msg="fail" />}
          {!pass && (
            <div>
              Reason: {failReason}
              <br />
              <Translate msg="reworkRequired" />
            </div>
          )}
        </div>
      </div>
    );
  }

  const elapsed: moment.Duration | null = metrics.starting_time
    ? moment.duration(moment.utc().diff(moment.utc(metrics.starting_time)))
    : null;

  const dwellTimeFailed = elapsed && elapsed?.asMinutes() <= product.dwell_duration_minutes;

  // show the form
  return (
    <div>
      <pre>{/* JSON.stringify(workflorProps.,null,2) */}</pre>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          save();
        }}
      >
        <div style={{ display: "inline-grid", gridTemplateColumns: "auto auto auto", gridGap: 15 }}>
          {stage === "final" && (
            <>
              <span>Start Time (開始時間):</span>
              <input
                type="text"
                readOnly={true}
                disabled={true}
                required
                value={
                  metrics.starting_time
                    ? moment(metrics.starting_time).format("YYYY-MM-DD hh:mm:ss A")
                    : "missing"
                }
              />
              <span></span>
            </>
          )}

          <span>Start Ambient Temperature (起始環境溫度):</span>
          <input
            type="number"
            disabled={stage === "final"}
            required
            autoFocus={metrics.starting_temp === null}
            min={0}
            max={40}
            step={0.001}
            value={metrics.starting_temp ?? undefined}
            onChange={(e) => {
              const temperature = parseFloat(e.target.value);
              setMetrics({ ...metrics, starting_temp: temperature || null });

              if (!isNaN(temperature)) {
                setCachedAmbient({
                  at: moment.utc().format(),
                  temperature: temperature,
                });
              }
            }}
          />
          <span>&deg;C</span>

          <span>Start Pressure (啟動壓力):</span>
          <input
            type="number"
            disabled={stage === "final"}
            required
            min={product.dwell_start_psi - product.dwell_start_psi_tolerance}
            max={product.dwell_start_psi + product.dwell_start_psi_tolerance}
            step={0.001}
            autoFocus={metrics.starting_temp !== null}
            value={metrics.starting_pressure ?? undefined}
            onChange={(e) =>
              setMetrics({ ...metrics, starting_pressure: parseFloat(e.target.value) || null })
            }
          />
          <span>psi</span>

          {stage === "final" && (
            <>
              <div>Elapsed Hours (经过时间):</div>
              <div>{elapsed?.asHours().toFixed(2)}</div>
              <div>hrs (小时)</div>

              {dwellTimeFailed ? (
                <>
                  Error: {(product.dwell_duration_minutes / 60).toFixed(1)} elapsed hours required
                  before testing.
                  <br />
                  测试前经过的小时数
                </>
              ) : (
                <>
                  <span>Final Ambient Temperature (最終環境溫度):</span>
                  <input
                    type="number"
                    autoFocus={metrics.final_temp === null}
                    min={0}
                    max={40}
                    step={0.001}
                    required
                    value={metrics.final_temp ?? undefined}
                    onChange={(e) =>
                      setMetrics({ ...metrics, final_temp: parseFloat(e.target.value) })
                    }
                  />
                  <span>'C</span>
                  <span>Final Pressure (最終壓力):</span>
                  <input
                    autoFocus={metrics.final_temp !== null}
                    type="number"
                    step={0.001}
                    required
                    value={metrics.final_pressure ?? undefined}
                    onChange={(e) =>
                      setMetrics({ ...metrics, final_pressure: parseFloat(e.target.value) })
                    }
                  />
                  <span>psi</span>
                  {metrics.starting_pressure !== null && metrics.final_pressure !== null && (
                    <>
                      <span>Pressure Delta (壓力差):</span>
                      <span>{(metrics.starting_pressure - metrics.final_pressure).toFixed(2)}</span>
                      <span>psi</span>

                      <span>
                        Temperature Adjusted
                        <br />
                        Pressure Delta:
                      </span>
                      <span>{getAdjustedDelta(metrics)?.toFixed(2)}</span>
                      <span>psi</span>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>

        <div className="alert alert-info mt-3">
          <strong>Product Dwell Settings:</strong>
          <div
            style={{
              display: "inline-grid",
              gridTemplateColumns: "auto auto",
              textAlign: "right",
              gap: 10,
            }}
          >
            <div>Start PSI:</div>
            <div>{product.dwell_start_psi.toFixed(1)}</div>
            <div>Start PSI Tolerance:</div>
            <div>+/- {product.dwell_start_psi_tolerance.toFixed(1)}</div>

            <div>Duration (minimum hours):</div>
            <div>{(product.dwell_duration_minutes / 24).toFixed(1)}</div>
            <div>End Max PSI Drop:</div>
            <div>{product.dwell_end_max_psi_drop.toFixed(1)}</div>
          </div>
        </div>

        <div className="mt-3">
          <button type="submit" className="btn btn-primary" disabled={dwellTimeFailed || loading}>
            Submit
          </button>

          <button
            type="submit"
            className="btn btn-tertiary ml-3"
            onClick={(e) => {
              e.preventDefault();
              reset();
              setStage(null);
              workflowApi.resetState();
            }}
          >
            Cancel
          </button>
          {loading && "Please wait..."}
        </div>
      </form>

      {errorMessage && <div>Error: {errorMessage}</div>}
    </div>
  );
};
