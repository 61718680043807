import axios from "axios";
import { SerialNumberMetricRow } from "../ts/types";

export const ClientApi = {
  SerialNumberMetrics: {
    getLatestResults: async <T>(
      serialNumber: string,
      stationId: number
    ): Promise<SerialNumberMetricRow<T> | null> => {
      const { data } = await axios.get<SerialNumberMetricRow<T>>(
        `/api/serialNumbers/${serialNumber}/stationData/${stationId}/latest`,
        {
          params: {},
        }
      );

      return data;
    },

    save: async <T>(
      serialNumber: string,
      payload: {
        stationId: number;
        userId: number;
        productId: number;
        pass: boolean;
        metrics: T;
      }
    ): Promise<void> => {
      const { data } = await axios.post<{ status: "success" | "fail" }>(
        `/api/serialNumbers/${serialNumber}/metrics`,
        {
          payload,
        }
      );

      if (data.status !== "success") {
        throw new Error("success expected");
      }
    },
  },
};
