import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import MainTemplate from "./components/app/MainTemplate";
import { DashboardCount } from "./components/DashboardCount";
import { LotNumberGeneration } from "./components/lotNumbers/LotNumberGeneration";
import { ProductionDashboard } from "./components/ProductionDashboard";
import { ProductionDashboardConfig } from "./components/ProductionDashboardConfig";
import ChooseStation from "./components/station/ChooseStation";
import { CorsairStation } from "./components/station/corsair/CorsairStation";
import BoxingStation from "./components/station/packingStation/BoxingStation";
import DwellDecayStation from "./components/station/packingStation/DwellDecayStation";
import LoadTestRackStation from "./components/station/packingStation/LoadTestRackStation";
import PackingLookup from "./components/station/packingStation/PackingLookup";
import PalletStation from "./components/station/packingStation/PalletStation";
import { PrintedItemsProvider } from "./components/station/receiving/PrintedItems";
import { PurchaseOrderStation } from "./components/station/receiving/PurchaseOrderSummary";
import { ReceivingStation } from "./components/station/receiving/ReceivingStation";
import { SerialNumberGen } from "./components/station/serialNumberGen/SerialNumberGen";
import Station from "./components/station/Station";
import { TestReportStation } from "./components/station/testReport/TestReportStation";

const { REACT_APP_API_URL, NODE_ENV } = process.env;

export const BASE_URL = REACT_APP_API_URL;

axios.defaults.baseURL = REACT_APP_API_URL;

(window as any).moment = moment;

console.log("BASE_URL", BASE_URL, NODE_ENV);

export const App: React.FunctionComponent = () => {
  // force refresh the page midnight to help ease need for manual refresh
  useEffect(() => {
    const msTillMidnight = moment().endOf("day").diff(moment());

    window.setTimeout(() => {
      window.location.reload();
    }, msTillMidnight);
  }, []);

  return (
    <PrintedItemsProvider>
      <BrowserRouter basename="/">
        <MainTemplate>
          <Switch>
            <Route path="/dashboard" component={ProductionDashboard} />
            <Route path="/dashboard/bay10" component={ProductionDashboard} />
            <Route path="/dashboard/bay14" component={ProductionDashboard} />
            <Route exact path="/admin/dashboardConfig" component={ProductionDashboardConfig} />
            <Route exact path="/stations" render={(props) => <ChooseStation />} />
            <Route
              path="/stations/receiving/po/:poNumber"
              render={(props) => <PurchaseOrderStation {...props} />}
            />
            <Route
              path="/stations/receiving/:poNumber?"
              render={(props) => <ReceivingStation {...props} />}
            />
            <Route path="/stations/loadTestRack" render={(props) => <LoadTestRackStation />} />
            <Route path="/stations/dwellDecayStation" render={(props) => <DwellDecayStation />} />
            <Route path="/stations/boxing" render={(props) => <BoxingStation />} />
            <Route path="/stations/pallet" render={(props) => <PalletStation />} />
            <Route path="/stations/packingLookup" component={PackingLookup} />
            <Route path="/stations/serialNumberGen" render={(props) => <SerialNumberGen />} />
            <Route path="/stations/dashboardCount" render={(props) => <DashboardCount />} />
            <Route path="/stations/corsair" render={(props) => <CorsairStation />} />
            <Route path="/stations/testReport" render={(props) => <TestReportStation />} />
            <Route path="/stations/:stationId" render={(props) => <Station {...props} />} />
            <Route
              exact
              path="/lotNumbers/calgary"
              render={(props) => <LotNumberGeneration site="Calgary" />}
            />
            <Route
              exact
              path="/lotNumbers/simps"
              render={(props) => <LotNumberGeneration site="Simps" />}
            />
            <Route
              exact
              path="/lotNumbers/remava"
              render={(props) => <LotNumberGeneration site="Remava" />}
            />
            <Route path="/" render={(props) => <ChooseStation />} />
          </Switch>
        </MainTemplate>
      </BrowserRouter>
    </PrintedItemsProvider>
  );
};

export default App;
