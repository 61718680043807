import axios from "axios";
import React, { useEffect, useState } from "react";
import { AVAILABLE_PRINTERS } from "../station/packingStation/common";
import { useAppState } from "../contexts/AppContext";

// TODO: this page can be deleted once receiving station is accepted (for Calgary)

type Props = {
  site: "Calgary" | "Simps" | "Remava";
};

export const LotNumberGeneration: React.FC<Props> = ({ site }) => {
  const { user } = useAppState();

  const [lastLotNumber, setLastLotNumber] = useState("");
  const [quantity, setQuantity] = useState("");
  const [transid, setTransid] = useState("");
  const [lotNumbers, setLotNumbers] = useState<string[] | null>(null);
  const [error, setError] = useState(false);
  const [printer, setPrinter] = useState("");

  const refreshLastLotNumber = async () => {
    const response = await axios.get<{ lotnumber: string }>(
      `/api/lotNumbers/${site.toLowerCase()}`
    );

    setLastLotNumber(response.data.lotnumber);
  };

  const generateLotNumbers = async () => {
    if (!lastLotNumber || !quantity) return;

    setError(false);
    try {
      const response = await axios.post<string[]>("/api/lotNumbers/", {
        payload: {
          site: site,
          userId: user!.uid,
          quantity,
          transid,
        },
      });
      await refreshLastLotNumber();
      setLotNumbers(response.data);
    } catch (e) {
      console.error(e);
      setError(true);
    }
  };

  useEffect(() => {
    refreshLastLotNumber();

    const savedPrinter = localStorage.getItem("printer") || "";
    setPrinter(savedPrinter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h5>{site} Lot Number Generation</h5>
      <br />

      <label className="d-flex align-items-center">
        Printer (reserved for future functionality):
        <select
          name="printer"
          className="form-control mr-2 ml-2"
          style={{ width: 250 }}
          value={printer}
          onChange={(e) => {
            localStorage.setItem("printer", e.target.value);
            setPrinter(e.target.value);
          }}
        >
          <option value="">-- select-- </option>
          {AVAILABLE_PRINTERS.map((printer) => (
            <option value={printer} key={printer}>
              {printer}
            </option>
          ))}
        </select>
      </label>

      <br />
      <p>
        Last Lot Number used: <strong>{lastLotNumber}</strong>
      </p>

      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <br />
        Quantity of Lot Numbers needed:
        <input
          style={{ width: "60px" }}
          className="form-control"
          min="1"
          max="500"
          step=""
          type="number"
          name="quantity"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
        <br />
        Related Transaction:
        <input
          style={{ width: "220px" }}
          className="form-control"
          maxLength={10}
          type="text"
          name="transid"
          value={transid}
          onChange={(e) => setTransid(e.target.value)}
        />
        <br />
        <button
          className="btn btn-primary"
          onClick={() => generateLotNumbers()}
          disabled={!(parseInt(quantity) > 0)}
        >
          Generate Lot Numbers
        </button>
        {error && (
          <div className="alert alert-danger mt-3">
            Error while connecting to the upstream QPT database, please check your connection and
            try again, or contact support.
          </div>
        )}
        {lotNumbers && <h5>Generated Lot Numbers</h5>}
        {lotNumbers && lotNumbers.map((lotNumber) => <div key={lotNumber}>{lotNumber}</div>)}
        {/* WIP: allow printing & re-printing */}
        {/* {lotNumbers && (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => {
                printLotNumbers();
              }}
            >
              Print Lot Numbers
            </button>
          </div>
        )} */}
      </form>
    </div>
  );
};
