import axios from "axios";
import _ from "lodash";
import React from "react";
import { AppContextPropTypes, WorkflowComponentPropTypes } from "../../../../../propTypes";
import { LeakLocationTypesLookup, WorkflowComponentProps } from "../../../../../ts/types";
import { Loading, Translate } from "../../../../common";
import LeakLocationForm from "./leakTest/LeakLocationForm";
import LeakLocationButton from "./leakTest/ProductLeakLocation";

export default class LeakTestMetrics extends React.Component<WorkflowComponentProps> {
  static propTypes = {
    ...WorkflowComponentPropTypes,
  };

  static contextTypes = AppContextPropTypes;

  state: {
    error: null | string;
    leakLocationTypes: LeakLocationTypesLookup | null;
  } = {
    error: null,
    leakLocationTypes: null,
  };

  loadLeakTestLocations = async () => {
    this.setState({ error: false });
    try {
      const leakLocationsResponse = await axios.get("/api/leakTestLocations");
      const leakLocationTypes = _.keyBy(leakLocationsResponse.data, "location_id");

      this.setState({ leakLocationTypes });
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  componentDidMount() {
    this.loadLeakTestLocations();
  }

  render() {
    const { props, state } = this;
    const { workflowState } = props;

    const visible = workflowState.serialNumber && workflowState.product && state.leakLocationTypes;

    if (!visible) {
      return null;
    }

    const { product, leakLocation, dryWeight } = workflowState;
    const { leakLocationTypes } = state;

    if (!leakLocationTypes) {
      return <Loading />;
    }

    const leakLocationEntered = typeof leakLocation === "number";
    const dryWeightEntered = typeof dryWeight === "number";

    let summary = null;

    // Display entered stuff
    if (leakLocationEntered) {
      const leakLocationType =
        product && product.metadata.leakLocations.find((leak) => leak.index === leakLocation);
      const leakLocationId = leakLocationType && leakLocationType.locationId;
      const productLeakLocationType =
        leakLocationId && (leakLocationId || null) && leakLocationTypes[leakLocationId];

      summary = (
        <div>
          <div>
            <div className={`alert ${leakLocation === 0 ? "alert-info" : "alert-danger"}`}>
              <strong>
                <Translate msg="result" />:{" "}
              </strong>
              <span>
                {leakLocation !== 0 ? <Translate msg="fail" /> : <Translate msg="pass" />}
              </span>
            </div>
            <div className="d-inline-flex ml-3">
              <LeakLocationButton
                leakLocationTypes={leakLocationTypes}
                product={product}
                productLeakLocationIndex={leakLocation || -1}
              />
            </div>

            {
              /* Show "Rework Required" message if leak test failed */
              productLeakLocationType && (
                <div className="alert alert-danger">
                  <strong>
                    <Translate msg="reworkRequired" />
                  </strong>
                </div>
              )
            }
            {dryWeightEntered && (
              <p>
                <strong>
                  <Translate msg="metrics.dryWeight" />:{" "}
                </strong>
                {dryWeight && dryWeight.toFixed(2)}
              </p>
            )}
          </div>
        </div>
      );
    }

    return (
      <div>
        {state.error && (
          <div className="alert alert-danger">An error occured, please reload the page.</div>
        )}
        {summary}

        {!leakLocationEntered && (
          <LeakLocationForm {...props} leakLocationTypes={leakLocationTypes} />
        )}

        {/* <pre>wfs: {JSON.stringify(workflowState, 0, 2)}</pre> */}
      </div>
    );
  }
}
