import React from "react";
import { WorkflowComponentProps } from "../../../../../ts/types";
import { Translate } from "../../../../common";

export default class NoiseTestHints extends React.Component<WorkflowComponentProps> {
  render() {
    const { props } = this;
    const { workflowState } = props;

    const visible = !!workflowState.metrics;

    if (!visible) {
      return false;
    }

    const { minNoiseDecibels, maxNoiseDecibels } = workflowState.product.metadata;

    return (
      <div>
        <h5>
          <Translate msg="details" />
        </h5>
        <p>
          <strong>
            <Translate msg="metrics.minAllowedDecibels" />:
          </strong>{" "}
          {minNoiseDecibels || "Not Set"}
        </p>
        <p>
          <strong>
            <Translate msg="metrics.maxAllowedDecibels" />:
          </strong>{" "}
          {maxNoiseDecibels || "Not Set"}
        </p>
      </div>
    );
  }
}
