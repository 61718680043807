import axios from "axios";
import React, { RefObject } from "react";
import styled from "styled-components";
import { AppContextPropTypes } from "../../../propTypes";
import { BoxWithQuantity, PalletConfigApi, ProductConfig } from "../../../ts/types";
import { FatalError } from "../../common";
import {
  AVAILABLE_PRINTERS,
  calculateBoxWeight,
  calculatePalletWeight,
  DEBUG_PRINTER,
} from "./common";
import { Grid4, LabelGrid2 } from "./Layout";

const PalletConfigGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;

  align-items: center;

  label {
    text-align: right;
  }

  input {
    width: 75px !important;
  }
`;

const PalletContentGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;

  gap: 5px;
`;

type PalletStationState = {
  loading: boolean;
  error: string | null;

  validation: boolean;
  printer: string;
  printerStatus: string | null;
  availablePrinters: readonly string[];

  // Configuration
  palletConfig: PalletConfigApi | null;
  productConfigName: string;
  productConfig: ProductConfig | null;
  productConfigs: readonly ProductConfig[] | null;

  unitsToShip: number;
  boxesToShip: number;
  workOrder: string;
  purchaseOrder: string;
  customerPartNumber: string;

  confirmed: boolean;

  // pallet form state
  autoPrintPalletLabels: boolean;
  palletSearch: string;
  palletSerial: string;
  boxSerial: string;
  palletFeedback: string;
  boxes: BoxWithQuantity[];

  // override shit
  boxesPerPallet: number;
  currentPallet: number;
  totalPallets: number;
  palletWeight: number;
  totalBoxes: number;
};

export default class PalletStation extends React.Component {
  static contextTypes = AppContextPropTypes;

  state: PalletStationState = {
    loading: false,
    error: null,

    validation: localStorage.DEBUG ? false : true,
    printer: localStorage.getItem("printer") || "",
    printerStatus: null,
    availablePrinters: AVAILABLE_PRINTERS,

    // config shit
    confirmed: false,
    palletConfig: null,
    productConfigName: localStorage.productConfigName || "",
    productConfig: null,
    productConfigs: null,

    unitsToShip: 0,
    boxesToShip: 0,
    workOrder: "",
    purchaseOrder: "",
    customerPartNumber: "",

    // pallet form state
    autoPrintPalletLabels: true,
    palletSearch: "",
    palletSerial: "",
    boxSerial: "",
    palletFeedback: "",
    boxes: [],

    // override shit
    boxesPerPallet: 0,
    currentPallet: 1,
    totalPallets: 10,
    palletWeight: 0,
    totalBoxes: 1,
  };

  scanBoxRef: RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);
    this.scanBoxRef = React.createRef<HTMLInputElement>();
  }

  componentDidMount() {
    this.context.setPageTitle("Pallet Station");

    this.loadPalletConfig(true);
    this.loadProductConfigs();
  }

  loadProductConfigs = async () => {
    try {
      const { data: productConfigs } = await axios.get<ProductConfig[]>("/api/productConfigs");

      const productConfig = productConfigs.find(
        (c) => c.productname === this.state.productConfigName
      );

      this.setState({
        productConfigs,
        productConfig,
        boxesPerPallet: productConfig ? productConfig.boxesPerPallet : 0, // populate with the default
      });
    } catch (e) {
      console.error(e);
      this.setState({ error: e });
    }
  };

  loadPalletConfig = async (updateProductConfig = false) => {
    try {
      const response = await axios.get("/api/palletConfig");

      const palletConfig = response.data;

      await this.setStateAsync({ ...palletConfig, palletConfig });

      if (updateProductConfig) {
        this.loadProductConfigs();
      }
    } catch (e) {
      console.error(e);
      this.setState({ error: e });
    }
  };

  savePalletConfig = async () => {
    const {
      workOrder,
      purchaseOrder,
      customerPartNumber,
      unitsToShip,
      boxesToShip,
      productConfigName,
    } = this.state;

    try {
      await axios.post("/api/palletConfig", {
        payload: {
          workOrder,
          purchaseOrder,
          customerPartNumber,
          unitsToShip,
          boxesToShip,
          productConfigName,
        },
      });

      this.setState({ confirmed: true });

      await this.loadPalletConfig();
    } catch (e) {
      console.error(e);
      this.setState({ error: e });
    }
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  preventFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    return false;
  };

  setStateAsync = (newState: any): Promise<void> =>
    new Promise((resolve) => this.setState(newState, () => resolve()));

  newPallet = async () => {
    this.setState({ palletSerial: "", boxes: [] });

    try {
      const { data: sequenceId } = await axios.post<string>(
        "/api/stations/packing/pallet/serialNumber"
      );
      console.log("sequenceId", sequenceId);

      const palletSerial = sequenceId.padStart(6, "0");

      await this.setStateAsync({
        boxSerial: "",
        palletSerial,
      });
      this.scanBoxRef.current && this.scanBoxRef.current.focus();
    } catch (e) {
      console.error(e);
      this.setState({ error: "Error creating serial number!  Refresh and try again..." });
    }
  };

  addBoxToPallet = async () => {
    this.setState({ palletFeedback: "" });

    let { boxSerial, palletSerial, boxes, boxesPerPallet, productConfig, workOrder } = this.state;

    if (!productConfig) throw new Error("missing productConfig");

    if (boxes.find((box) => box.serial === boxSerial)) {
      this.setState({ palletFeedback: "Box already on pallet" });
      return;
    }

    try {
      if (!boxSerial) {
        throw new Error("boxSerial blank!");
      }

      const { data: units } = await axios.get(`/api/stations/packing/box/${boxSerial}`);

      const quantity = units.length;

      if (!quantity) {
        this.setState({
          palletFeedback: `Box '${boxSerial}' not found.`,
        });
        return;
      }

      if (boxes.length >= boxesPerPallet) {
        this.setState({
          palletFeedback: `Pallet is full already, start a new pallet or remove a box`,
        });
        return;
      }

      if (quantity !== productConfig.unitsPerBox) {
        alert(`WARNING:\n This box has only ${quantity} units in it!   Press OK to continue`);
      }

      const updatedBoxes = [...boxes, { serial: boxSerial, quantity, units }];

      await axios.post(`/api/stations/packing/pallet/${palletSerial}`, {
        payload: {
          userId: 1,
          boxes: updatedBoxes.map((box) => box.serial),
          workOrder,
        },
      });

      await this.setStateAsync({
        boxes: updatedBoxes,
        boxSerial: "",
      });

      await this.loadPalletConfig();

      await this.printBoxSummaryLabel({ boxSerial });

      if (updatedBoxes.length >= boxesPerPallet) {
        this.palletFinished();
      }

      this.scanBoxRef.current && this.scanBoxRef.current.focus();
    } catch (e) {
      console.error(e);
      this.setState({ error: "Error!" });
    }
  };

  removeFromPallet = async (boxSerial: string) => {
    try {
      const { boxes, palletSerial } = this.state;

      const updatedBoxes = boxes.filter((box) => box.serial !== boxSerial);

      const { data } = await axios.post(`/api/stations/packing/pallet/${palletSerial}`, {
        payload: {
          userId: 1,
          boxes: updatedBoxes.map((box) => box.serial),
          pallet: palletSerial,
        },
      });

      await this.setStateAsync({ boxes: updatedBoxes });
      await this.loadPalletConfig();

      console.log("data", data);
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  printBoxSummaryLabel = async ({ boxSerial }: { boxSerial: string }) => {
    const { boxes, workOrder, productConfig, boxesToShip } = this.state;

    if (!productConfig) {
      throw new Error("missing productConfig");
    }

    const boxIndex = boxes.findIndex((b) => b.serial === boxSerial);
    const box = boxes[boxIndex];

    if (!box) {
      alert("Box not found! " + boxSerial);
    }

    try {
      const { data } = await axios.get(
        `/api/workOrders/${workOrder}/box/${boxSerial}/packageNumber`
      );

      const { packageNumber } = data;

      await this.printSummaryLabel({
        packageType: "3S",
        address: productConfig.address || "",
        packageId: box.serial,
        quantity: box.quantity,
        orderNum: packageNumber,
        orderTotal: boxesToShip,
        countLabel: "PACKAGE COUNT:",
        packageWeightLabel: "PACKAGE WEIGHT:",
        packageWeight:
          calculateBoxWeight({ productConfig, quantity: box.quantity }).toFixed(2) + " kg",
        revision: "", // TODO: ?
      });
    } catch (e) {
      console.error(e);
      this.setState({ error: "Error: " + e.toString() });
    }
  };

  clearPalletForm = async () => {
    await this.setStateAsync({
      palletSearch: "",
      palletSerial: "",
      boxSerial: "",
      palletFeedback: "",
      boxes: [],
    });
  };

  loadPallet = async (palletSearch: string) => {
    await this.clearPalletForm();

    try {
      const { data: boxes } = await axios.get<BoxWithQuantity[]>(
        `/api/stations/packing/pallet/${palletSearch}`
      );

      await this.setStateAsync({ boxes, palletSerial: palletSearch });
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  palletFinished = async () => {
    const { boxes, boxesPerPallet, autoPrintPalletLabels, currentPallet } = this.state;

    if (boxes.length < boxesPerPallet) {
      if (!window.confirm("Pallet not full, are you sure?")) {
        return;
      }
    }

    if (autoPrintPalletLabels) {
      // print 2 pallet labels: for front and back of pallet
      await this.printPalletSummaryLabel();
      await this.printPalletSummaryLabel();
    }
    await this.loadPalletConfig();

    await this.setStateAsync({ currentPallet: parseInt((currentPallet as unknown) as string) + 1 });
  };

  printPalletSummaryLabel = async () => {
    const { printer, palletSerial, productConfig, boxes, currentPallet, totalPallets } = this.state;

    if (!productConfig) throw new Error("missing productConfig");

    if (!printer) {
      this.setState({ printerStatus: "Printer must be selected first." });
      return;
    }

    const totalUnits = boxes.reduce((acc, box) => box.quantity + acc, 0);

    try {
      await this.printSummaryLabel({
        packageType: "4S",
        address: productConfig.address || "",
        packageId: palletSerial,
        quantity: totalUnits, //  no longer "boxes.length" //  as per Ian 2018-11-10
        orderNum: currentPallet,
        orderTotal: totalPallets,

        countLabel: "PALLET COUNT:",
        packageWeightLabel: "PALLET WEIGHT:",
        packageWeight: calculatePalletWeight({ productConfig, boxes }).toFixed(2) + " kg",
        revision: "", // TODO: ?
      });
    } catch (e) {
      console.error(e);
      this.setState({ error: "Error! " + e.toString() });
    }
  };

  // Prints a "Summary" label
  printSummaryLabel = async ({
    packageId,
    packageType,
    address,
    quantity,
    orderNum,
    orderTotal,
    countLabel,
    packageWeight,
    packageWeightLabel,
    revision,
    specialDescription,
    description,
  }: {
    packageId: string;
    packageType: string;
    address: string;
    quantity: number;
    orderNum: number;
    orderTotal: number;
    countLabel: string;
    packageWeight: string; // e.g. '123 kg'
    packageWeightLabel: string;
    revision: string;
    specialDescription?: string;
    description?: string;
  }) => {
    const { purchaseOrder, customerPartNumber } = this.state;

    const labelParams = {
      // PKG ID barcode
      PACKAGE_TYPE: packageType || "??",
      ADDRESS: address || "NOT SET!",

      PKG_SERIAL: packageId,
      SUPPLIER_CODE: "20258235+", // 20258235+

      // purchase order barcode
      PURCHASE_ORDER_PREFIX: "K",
      PURCHASE_ORDER: purchaseOrder, // 56676421 // TODO: confirm same as "workOrder"
      REVISION: revision, // beside barcode e.g. "Rev: 6A"  (TODO: always blank!)

      // customer part barcode
      CUSTOMER_PART_PREFIX: "P",
      CUSTOMER_PART: customerPartNumber,

      // quantity barcode
      QUANTITY: quantity, // e.g. 8

      // text fields
      ZSPECIAL: specialDescription,
      DESCRIPTION: description,
      PACKAGE_NUM: orderNum,
      PACKAGE_COUNT_LABEL: countLabel,
      PACKAGE_TOTAL: orderTotal,
      PACKAGE_WEIGHT_LABEL: packageWeightLabel,
      PACKAGE_WEIGHT: packageWeight,
    };

    console.log(labelParams);

    await this.printBartenderLabel({
      labelTemplate: "PalletStation_BoxAndPalletSummary.btw",
      labelParams,
    });
  };

  printBartenderLabel = async ({
    labelTemplate,
    labelParams,
    path,
  }: {
    labelTemplate: string;
    labelParams: any; // TODO:
    path?: string;
  }) => {
    const { printer } = this.state;

    if (!printer) {
      this.setState({ printerStatus: "Printer must be selected first." });
      return;
    }

    if (printer === DEBUG_PRINTER) {
      const printDebugMsg = `
        PATH: ${labelTemplate} \n
        PARAMS: \n
        ${JSON.stringify(labelParams, null, 2)} \n
      `;
      //alert(printDebugMsg);
      console.log("PRINTED:\n" + printDebugMsg);
      return;
    }

    this.setState({ printerStatus: "Printing..." });
    try {
      // Note: we can't POST directly to BarTender API directly because of BarTender CORS restrictions
      const response = await axios.post(`/api/bartender/print`, {
        payload: {
          printer,
          labelTemplate,
          labelParams,
          path,
        },
      });
      console.log("response", response);

      const success =
        response.data &&
        response.data.includes("BarTender successfully sent the print job to the spooler") &&
        response.data.includes("Finished sending print job to printer");

      if (success) {
        this.setState({ printerStatus: "Label printed." });

        // clear after 3 seconds
        window.setTimeout(() => {
          this.setState({ printerStatus: "" });
        }, 3000);
      } else if (response.data.includes("does not support the page size specified")) {
        // most common error:  make more use friendly
        this.setState({
          printerStatus:
            "Printer Error: Selected printer does not support the label/page size specified.",
        });
      } else {
        this.setState({ printerStatus: `Printer Error.  Message from Printer: ${response.data}` });
      }
    } catch (e) {
      console.error(e);
      // HTTP status error, our error detected in response JSON data.
      this.setState({
        printerStatus: `HTTP Error: ${e.response.status || "Network error"}`,
      });
    }
  };

  render() {
    const {
      loading,
      error,

      /* printer shit */
      printer,
      printerStatus,
      availablePrinters,

      // pallet config shit
      palletConfig,
      confirmed,

      workOrder,
      purchaseOrder,
      customerPartNumber,
      productConfigName,
      productConfig,
      productConfigs,
      unitsToShip,
      boxesToShip,

      /* pallet state */
      autoPrintPalletLabels,
      palletSearch,
      palletSerial,
      boxSerial,
      palletFeedback,
      boxes,

      boxesPerPallet,

      currentPallet,
      totalPallets,
    } = this.state;

    if (loading || !palletConfig || !productConfigs) {
      return <div>Loading&hellip;</div>;
    }

    let expectedFullPackedBoxWeight = null;
    let expectedFullPalletWeight = null;
    if (productConfig) {
      const {
        unitsPerBox,
        unitsPerTray,
        unit_weight,
        tray_weight,
        pallet_weight,
        box_weight,
      } = productConfig;

      expectedFullPackedBoxWeight =
        box_weight + tray_weight * (unitsPerBox / unitsPerTray) + unit_weight * unitsPerBox;
      expectedFullPalletWeight = pallet_weight + expectedFullPackedBoxWeight * boxesPerPallet;
    }

    console.log("productConfig", productConfig);

    return (
      <section>
        {error && <FatalError />}

        {palletConfig && (
          <div className="alert alert-info ">
            <Grid4>
              <label>Product Config:</label>
              <select
                required
                name="product"
                className="form-control"
                style={{ width: 250 }}
                value={productConfigName}
                onChange={(e) => {
                  const name = e.target.value;
                  const productConfig = productConfigs.find((x) => x.productname === name);
                  if (!productConfig) throw new Error("missing productConfig");

                  this.setState({
                    productConfig,
                    productConfigName: name,
                    boxesPerPallet: productConfig.boxesPerPallet,
                    customerPartNumber: productConfig.productname,
                  });
                  localStorage.setItem("productConfigName", name);
                }}
              >
                <option value="">-- select-- </option>
                {productConfigs.map((item) => (
                  <option value={item.productname} key={item.productname}>
                    {item.productname} - {item.description}
                  </option>
                ))}
              </select>

              <label>Printer:</label>
              <select
                name="printer"
                className="form-control"
                style={{ width: 250 }}
                value={printer}
                onChange={(e) => {
                  localStorage.setItem("printer", e.target.value);
                  this.setState({ printer: e.target.value });
                }}
              >
                <option value="">-- select-- </option>
                {availablePrinters.map((printer) => (
                  <option value={printer} key={printer}>
                    {printer}
                  </option>
                ))}
              </select>

              <label>Work Order:</label>
              <input
                className="form-control-sm"
                required
                autoComplete="off"
                autoFocus
                type="text"
                name="workOrder"
                maxLength={30}
                minLength={3}
                value={workOrder}
                onChange={this.handleInputChange}
                style={{ width: 200 }}
              />
              <label>Customer PO:</label>
              <input
                className="form-control-sm"
                required
                autoComplete="off"
                type="text"
                name="purchaseOrder"
                maxLength={30}
                minLength={3}
                value={purchaseOrder}
                onChange={this.handleInputChange}
                style={{ width: 200 }}
              />
              <label>Customer Part Number:</label>
              <input
                className="form-control-sm"
                required
                autoComplete="off"
                type="text"
                name="customerPartNumber"
                maxLength={30}
                minLength={3}
                value={customerPartNumber}
                onChange={this.handleInputChange}
                style={{ width: 200 }}
              />
              <label>Units to Ship:</label>
              <div>
                <input
                  className="form-control-sm"
                  type="number"
                  min={1}
                  name="unitsToShip"
                  value={unitsToShip}
                  onChange={this.handleInputChange}
                  style={{ width: 100 }}
                />
                (packed: {palletConfig.packedUnits})
              </div>
              <label>Boxes to Ship:</label>
              <div>
                <input
                  className="form-control-sm"
                  type="number"
                  min={1}
                  name="boxesToShip"
                  value={boxesToShip}
                  onChange={this.handleInputChange}
                  style={{ width: 100 }}
                />
                (packed: {palletConfig.packedBoxes})
              </div>
            </Grid4>

            <button
              className="btn btn-primary"
              onClick={this.savePalletConfig}
              disabled={confirmed}
            >
              Save
            </button>
          </div>
        )}

        <div className="alert alert-warning">Print Status: {printerStatus || "Idle"}</div>

        {!palletConfig || !productConfig || !confirmed ? (
          <div className="alert alert-warning">Please complete and save the config section</div>
        ) : (
          <div className="d-flex">
            <div className="ml-3  card border-secondary" style={{ minWidth: 480, minHeight: 450 }}>
              <div className="card-header">Pallet Packing</div>
              <div className="card-body d-flex flex-column">
                <form className="mb-2 d-flex  align-items-center" onSubmit={this.preventFormSubmit}>
                  <span className="indent">Search:</span>
                  <input
                    className="form-control-sm ml-1 mr-1"
                    placeholder="enter pallet #"
                    type="text"
                    name="palletSearch"
                    maxLength={30}
                    minLength={3}
                    value={palletSearch}
                    onChange={this.handleInputChange}
                    style={{ width: 200 }}
                  />
                  <button
                    className="btn mr-1"
                    disabled={!palletSearch}
                    onClick={() => this.loadPallet(palletSearch)}
                  >
                    Go
                  </button>
                  <button
                    className="btn btn-tertriary mr-1"
                    onClick={() => this.setState({ palletSearch: "" })}
                    disabled={!palletSearch}
                  >
                    Clear
                  </button>
                </form>
                <label className="mb-2 d-flex  align-items-center">
                  <span className="indent">Pallet:</span>
                  <input
                    className="form-control-sm ml-1 mr-1"
                    disabled={true}
                    type="text"
                    name="palletSerial"
                    maxLength={30}
                    minLength={3}
                    value={palletSerial}
                    style={{ width: 200 }}
                  />

                  <button className="btn" onClick={this.newPallet}>
                    New
                  </button>
                </label>

                <form className="mb-2 d-flex align-items-center" onSubmit={this.preventFormSubmit}>
                  <span className="indent">Scan Box:</span>
                  <input
                    ref={this.scanBoxRef}
                    className="form-control-sm ml-1 mr-1"
                    type="text"
                    name="boxSerial"
                    maxLength={30}
                    minLength={3}
                    value={boxSerial}
                    onChange={this.handleInputChange}
                    style={{ width: 200 }}
                    disabled={!palletSerial}
                  />
                  <button
                    className="btn btn-tertriary mr-1"
                    onClick={this.addBoxToPallet}
                    disabled={!boxSerial}
                  >
                    Add to Pallet
                  </button>
                </form>

                {palletFeedback && (
                  <div className="alert alert-danger mt-1">Error: {palletFeedback} </div>
                )}
                <div className="d-flex">
                  <span className="indent">Contents:</span>
                  <div
                    className="d-flex flex-column pl-2 pr-2 pt-2 pb-2"
                    style={{
                      border: "2px solid lightgrey",
                      height: 300,
                      width: 400,
                      marginLeft: 4,
                      overflowY: "scroll",
                    }}
                  >
                    <PalletContentGrid>
                      {boxes
                        .slice()
                        .reverse()
                        .map((box, index) => [
                          <div
                            key={"c1" + index}
                            className="mr-1 d-flex  align-items-center"
                            style={{
                              font: "Deja Vu Sans Mono",
                              color: "blue",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              alert("visit boxing station to view the box contents");
                            }}
                            data-box={box}
                          >
                            {box.serial}
                          </div>,
                          <button
                            key={"c2" + index}
                            className="btn btn-secondary btn-sm"
                            onClick={() => this.printBoxSummaryLabel({ boxSerial: box.serial })}
                          >
                            <small>Print</small>
                          </button>,

                          <div key={"c3" + index}>
                            <button
                              className="btn btn-sm btn-tertriary"
                              onClick={(e) => {
                                this.removeFromPallet(box.serial);
                              }}
                            >
                              ✖
                            </button>
                          </div>,
                        ])}
                    </PalletContentGrid>
                  </div>
                </div>

                <div>
                  <button
                    className="btn btn-tertiary mr-3 mb-3 mt-3"
                    onClick={this.palletFinished}
                    disabled={!palletSerial || !boxes.length}
                  >
                    Pallet Packed ({boxes.length}/{boxesPerPallet} boxes)
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-tertriary mr-2"
                    onClick={this.printPalletSummaryLabel}
                    disabled={!palletSerial || !boxes.length}
                  >
                    Print Pallet Label
                  </button>

                  <label>
                    Auto Print after Pallet packed
                    <input
                      className="m-2"
                      type="checkbox"
                      name="autoPrintPalletLabels"
                      checked={autoPrintPalletLabels}
                      onChange={() =>
                        this.setState({ autoPrintPalletLabels: !autoPrintPalletLabels })
                      }
                    />
                  </label>
                </div>
                <div />
              </div>
            </div>

            <div className="m-3">
              <div>
                <h5>Current Pallet</h5>
                <PalletConfigGrid>
                  <label className="d-flex align-items-center" style={{ gridColumn: "span 2" }}>
                    Boxes to pack on this pallet:
                    <input
                      className="form-control-sm ml-1 mr-1"
                      type="number"
                      min={1}
                      required
                      name="boxesPerPallet"
                      value={boxesPerPallet}
                      onChange={this.handleInputChange}
                      style={{ width: 100 }}
                    />
                  </label>

                  <label>Packed boxes:</label>
                  <span>{boxes.length}</span>

                  <label>Packed weight:</label>
                  <span>
                    {calculatePalletWeight({ productConfig, boxes })} kg
                    {" / "}
                    {expectedFullPalletWeight} kg
                  </span>

                  <h5 className="mt-3">Pallet Settings</h5>
                  <br />

                  <label>Pallet Count:</label>
                  <form onSubmit={this.preventFormSubmit}>
                    <input
                      className="form-control-sm ml-1 mr-1"
                      type="number"
                      required
                      min={1}
                      max={totalPallets}
                      name="currentPallet"
                      value={currentPallet}
                      onChange={this.handleInputChange}
                      style={{ width: 100 }}
                    />
                    {" of "}
                    <input
                      className="form-control-sm ml-1 mr-1"
                      type="number"
                      required
                      min={1}
                      name="totalPallets"
                      value={totalPallets}
                      onChange={this.handleInputChange}
                      style={{ width: 100 }}
                    />
                    <button style={{ display: "none" }} type="submit">
                      late night workaround: this only exists to trigger validation
                    </button>
                  </form>
                </PalletConfigGrid>
              </div>
              <br /> <br />
              <h5>Product Config Details:</h5>
              <LabelGrid2>
                <span>Unit Weight:</span>
                <span>{productConfig.unit_weight}</span>

                <span>Tray Weight:</span>
                <span>{productConfig.tray_weight}</span>

                <span>Box Weight:</span>
                <span>{productConfig.box_weight}</span>

                <span>Pallet Weight:</span>
                <span>{productConfig.pallet_weight}</span>

                <span>Units per Box:</span>
                <span>{productConfig.unitsPerBox}</span>

                <span>Units per Tray:</span>
                <span>{productConfig.unitsPerTray}</span>

                <span>Boxes per Pallet:</span>
                <span>{productConfig.boxesPerPallet}</span>
              </LabelGrid2>
              {expectedFullPackedBoxWeight && (
                <span>Expected full box weight: {expectedFullPackedBoxWeight.toFixed(2)} kg</span>
              )}
              <br />
              {expectedFullPalletWeight && (
                <span>Expected full pallet weight: {expectedFullPalletWeight.toFixed(2)} kg </span>
              )}
              <div>
                Address:
                <div>{productConfig.address}</div>
              </div>
            </div>
          </div>
        )}

        <div className="alert alert-tertiary mt-3 ">
          <h3>Links</h3>
          <a
            className="btn btn-secondary"
            href="http://sigma/BarTender/PrintPortal/Print/Documents/Product - HPE Rework Carton 4x6.btw"
            target="_blank"
            rel="noopener noreferrer"
          >
            Label Override
          </a>

          <br />
        </div>
      </section>
    );
  }
}
