import React, { useEffect, useState } from "react";
import Axios from "axios";
import { ProductionDashboardData } from "../ts/types";
import styled, { keyframes } from "styled-components";
import moment from "moment";

/*
Notes:

Summersea Coldplate Loop
- prod name:  900-00709
- latest ptid:   562

7 Kingdoms Manifold
- 900-00584, ptid: 563

Eldora Coldplate Loop
- 900-00837,  ptid 572

Summersea Coldplate Heads
- 900-00421  ptid 409

Eldora Coldplate Heads
900-00837 ,  ptid 508 

*/

const GREEN = "#2BF53E";
const YELLOW = "#FFFF00";
const RED = "#FF0000";
const COOLIT_BLUE = "#024784";

const getStatusStyles = (
  actual: number,
  planned: number,
  data: ProductionDashboardData
): { color?: string; backgroundColor?: string } => {
  if (planned === 0) {
    return { color: "white", backgroundColor: "white" };
  }
  const percent = (actual / planned) * 100;
  if (percent >= data.pct_overperform) return { color: "white", backgroundColor: COOLIT_BLUE };
  if (percent >= 100) return { backgroundColor: GREEN };
  if (percent >= data.pct_underperform) return { backgroundColor: YELLOW };

  return { backgroundColor: RED }; // color: "white",
};

(window as any).wtest = getStatusStyles;

const Clock = styled.div`
  display: flex;
  justify-content: space-between;
  color: blue;
  letter-spacing: 4px;
`;

const LastUpdatedTime = styled.div`
  font-weight: normal;
  font-size: 1.5vmax;
  text-align: center;
`;

const Tile = styled.div`
  font-size: 5vmax;
  text-align: center;
  overflow: hidden;
`;

const StyledTable = styled.table`
  width: 100vw;
  height: 100vh;

  border-collapse: collapse;
  table-layout: fixed;

  .target {
    font-size: 2.8vmax !important;
  }

  tr td,
  tr th {
    &:first-child {
      text-align: left;
      padding: 1vw;
    }
    font-weight: bold;
    font-size: 3.7vmax;
    vertical-align: middle;
    text-align: center;
    border: 1px solid black;
  }
`;

const blink = keyframes`
  40% {
    opacity: 1
  }
  60% {
    opacity: 0
  }
`;

const WarningText = styled.span`
  color: red;

  animation: ${blink} 1s ease-in-out infinite;
`;

export const ProductionDashboard: React.FunctionComponent = () => {
  const [items, setItems] = useState<ProductionDashboardData[]>([]);
  const [time, setTime] = useState("");
  const [refreshTime, setRefreshTime] = useState("");
  const [verbose, setVerbose] = useState(false);

  const refreshData = async () => {
    const { data } = await Axios.get<ProductionDashboardData[]>("/api/productionDashboard");
    setItems(data);
    setRefreshTime(moment().format("h:mm A"));
    return data;
  };

  const refreshClock = () => {
    setTime(moment().format("h:mm:ss"));
  };

  useEffect(() => {
    let interval: number | null = null;
    refreshData().then((data) => {
      const minimumRefreshIntervalSecs =
        Math.min(...data.map((x) => x.min_refresh_freq_secs)) || 50;

      console.log(data);
      console.log("minimumRefreshIntervalSecs: ", minimumRefreshIntervalSecs);
      interval = window.setInterval(refreshData, minimumRefreshIntervalSecs * 1000);
    });

    const clockInterval = window.setInterval(refreshClock, 270);

    return () => {
      interval && clearInterval(interval);
      clearInterval(clockInterval);
    };
  }, []);

  return (
    <section>
      <StyledTable className="">
        <thead>
          <tr>
            <th className="no-border">
              <Clock>
                {time}
                <span>{moment().format("A")}</span>
              </Clock>
              <div>
                <LastUpdatedTime>Last Update:{refreshTime}</LastUpdatedTime>
              </div>

              <div
                style={{ fontSize: 13, color: "blue", textAlign: "center" }}
                onClick={() => {
                  setVerbose(!verbose);
                }}
              >
                Show Details
              </div>
            </th>
            <th style={{ width: "22vw" }} colSpan={2}>
              Hourly Rate
            </th>
            <th style={{ width: "22vw" }} colSpan={2}>
              Daily Target
            </th>
            <th style={{ width: "28vw" }} colSpan={2}>
              Weekly Target
            </th>
          </tr>
          <tr>
            <th className="target">Production Lines</th>
            <th className="target" style={{ textAlign: "center" }}>
              Actual
            </th>
            <th className="target">Plan</th>
            <th className="target">Actual</th>
            <th className="target">Plan</th>
            <th className="target">Actual</th>
            <th className="target">Plan</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => {
            const { line_down } = item;

            const hourlyStyle = getStatusStyles(item.hourly_actual, item.hourly_target, item);
            const dailyStyle = getStatusStyles(item.daily_actual, item.daily_prorated_target, item);
            const weeklyStyle = getStatusStyles(
              item.weekly_actual,
              item.weekly_prorated_target,
              item
            );

            return (
              <tr key={item.ptid}>
                <td>
                  <div>
                    {line_down ? (
                      <WarningText>{item.friendly_description}</WarningText>
                    ) : (
                      item.friendly_description
                    )}
                  </div>
                </td>
                <td style={hourlyStyle}>
                  <Tile>{item.hourly_actual.toFixed(0)}</Tile>
                </td>
                <td style={hourlyStyle}>
                  <Tile>{item.hourly_target}</Tile>
                </td>
                <td style={dailyStyle}>
                  <Tile>{item.daily_actual}</Tile>
                </td>
                <td style={dailyStyle}>
                  <Tile>
                    {item.daily_target}
                    {verbose && (
                      <div style={{ fontSize: 11 }}>
                        Prorated:
                        <br />
                        {item.daily_prorated_target.toFixed(0)}
                      </div>
                    )}
                  </Tile>
                </td>
                <td style={weeklyStyle}>{item.weekly_actual}</td>
                <td style={weeklyStyle}>
                  <Tile>{item.weekly_target}</Tile>
                  {verbose && (
                    <div style={{ fontSize: 11 }}>
                      Prorated:
                      <br />
                      {item.weekly_prorated_target.toFixed(0)}
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </section>
  );
};
