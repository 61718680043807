import axios from "axios";
import React from "react";
import PropTypes from "prop-types";
import {
  WorkflowComponentPropTypes,
  AppContextPropTypes,
  MetricTypePropTypes,
} from "../../../../../propTypes";
import Form from "../../common/form/Form";
import { FatalError, Loading } from "../../../../common";
import {
  WorkflowComponentProps,
  FormSubmitData,
  FormMetricTypeProps,
} from "../../../../../ts/types";

/**
 * This component can be used by stations to save user configured metrics
 */
export default class MetricsForm extends React.Component<
  WorkflowComponentProps & {
    evaluateMetrics: (args: { metrics: FormSubmitData }) => Promise<{ pass: number }>;
    validateData?: (data: FormSubmitData) => any;
    metricTypes: FormMetricTypeProps[];
  }
> {
  static propTypes = {
    ...WorkflowComponentPropTypes,
    validateData: PropTypes.func,

    evaluateMetrics: PropTypes.func.isRequired,
    metricTypes: PropTypes.arrayOf(MetricTypePropTypes).isRequired,
  };

  static contextTypes = AppContextPropTypes;

  state = {
    error: false,
    loading: false,
  };

  saveMetrics = async (formData: FormSubmitData) => {
    console.log("MetricsForm SAVE METRICS", formData);

    const { props, context } = this;
    const { workflowProps, workflowState } = props;

    this.setState({ error: false, loading: false });

    const { pass, ...calculatedMetrics } = await this.props.evaluateMetrics({
      metrics: formData,
    });

    if (pass !== 0 && pass !== 1) {
      throw new Error(`evaluatePassFn should return 0 or 1, was: ${pass}, (${typeof pass})`);
    }

    const metrics = { ...calculatedMetrics, ...formData };

    try {
      const response = await axios.post(
        `/api/serialNumbers/${workflowState.serialNumber}/metrics`,
        {
          payload: {
            stationId: workflowProps.station.id,
            userId: context.user.uid,
            productId: workflowState.product.ptid,
            pass,
            metrics,
          },
        }
      );

      const result = response.data;

      if (result.status !== "success") {
        throw new Error("success expected");
      }

      this.props.workflowApi.updateState({ metrics, pass });
    } catch (e) {
      this.setState({ error: true });
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { props, state } = this;

    const visible = !!props.workflowState.serialNumber;

    if (!visible) {
      return false;
    }

    return (
      <div>
        <Form
          inputs={this.props.metricTypes}
          trimInputs={true}
          onSubmit={this.saveMetrics}
          validateData={this.props.validateData}
        />
        {state.loading && <Loading />}
        {state.error && <FatalError />}
      </div>
    );
  }
}
