import axios from "axios";
import moment from "moment-timezone";
import React from "react";
import {
  ACOUSTIC_TEST_STATION_ID,
  ALIGNMENT_TEST_STATION_ID,
  FUNCTIONAL_TEST_STATION_ID,
  LEAK_TEST_STATION_ID,
  WET_WEIGHT_STATION_ID,
} from "../../../../../constants";
import { AppContextPropTypes, WorkflowComponentPropTypes } from "../../../../../propTypes";
import {
  FinalInspectionResult,
  IsoDateString,
  WorkflowComponentProps,
} from "../../../../../ts/types";
import { Translate } from "../../../../common";
import { failureReasons } from "./FailureReasonEntryMetrics";

type State = {
  loading: boolean;
  error: boolean;

  stationResults: null | FinalInspectionResult;
  pass: "true" | "false" | null;
  notes: string;
  submitted: boolean;

  metrics: any | null;
};

const initialState: State = Object.freeze({
  loading: false,
  error: false,

  // pre-requisite data from endpoint
  stationResults: null,

  // form data
  pass: null, // note: "true" or "false", as strings
  notes: "",
  submitted: false,

  // results
  metrics: null,
});

const StationInspectionRow: React.FC<{
  enabled: boolean;
  pass: boolean | null;
  date?: IsoDateString;
  name: string;
}> = ({ enabled, pass, date, name }) => {
  if (!enabled) return null;

  return (
    <tr>
      <td>{name}</td>
      <td>{date ? moment(date).format("YYYY-MM-DD") : "-"}</td>
      <td>{date ? moment(date).format("hh:mm:ss A") : "-"}</td>
      <td>
        {pass === true && (
          <strong className="text-success">
            <Translate msg="pass" />
          </strong>
        )}
        {pass === false && (
          <strong className="text-danger">
            <Translate msg="fail" />
          </strong>
        )}
        {pass === null && <strong className="text-danger"> Not Found</strong>}
      </td>
    </tr>
  );
};

export default class FinalInspectionMetrics extends React.Component<WorkflowComponentProps, State> {
  static propTypes = WorkflowComponentPropTypes;
  static contextTypes = AppContextPropTypes;

  state = { ...initialState };

  componentDidUpdate() {
    const { props, state } = this;

    if (!props.workflowState.serialNumber || state.loading) {
      return;
    }

    const initialLoadRequired = !state.stationResults;

    const reloadRequired = !props.workflowState.metrics && state.submitted;

    if (initialLoadRequired || reloadRequired) {
      this.loadStationResults();
    }
  }

  loadStationResults = async () => {
    const { workflowState } = this.props;

    this.setState({
      ...initialState,
      loading: true,
    });

    try {
      const response = await axios.get<FinalInspectionResult>(
        `/api/serialNumbers/${workflowState.serialNumber}/finalInspection`
      );
      const stationResults = response.data;

      const { subParts, requiredStationsVisited, passedStations, passedSequence } = stationResults;

      // automatically set to fail if we detect any failures

      if (!requiredStationsVisited) {
        this.setState({ pass: "false", notes: "Missed Station" });
      } else if (!passedStations) {
        this.setState({ pass: "false", notes: "Failed Station" });
      } else if (!passedSequence) {
        this.setState({ pass: "false", notes: "Failed Station Sequence Order" });
      } else if (!subParts) {
        if (workflowState.product.description?.toLowerCase().includes("elite")) {
          // filthy late night hack for corsair
        } else {
          this.setState({ pass: "false", notes: "Missing Subpart Entry" });
        }
      }

      this.setState({
        stationResults,
      });
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { props, state, context } = this;
    const { workflowProps, workflowState } = props;

    this.setState({ error: false, loading: true });

    const metrics = {
      notes: state.notes,
    };

    if (state.pass !== "true" && state.pass !== "false") {
      throw new Error("pass: should be string");
    }
    const pass = state.pass === "true" ? 1 : 0;

    try {
      const response = await axios.post(
        `/api/serialNumbers/${workflowState.serialNumber}/metrics`,
        {
          payload: {
            stationId: workflowProps.station.id,
            userId: context.user.uid,
            productId: workflowState.product.ptid,
            pass,
            metrics,
          },
        }
      );

      const result = response.data;

      if (result.status !== "success") {
        throw new Error("success expected");
      }

      this.setState({ notes: "", pass: null, submitted: true });

      this.props.workflowApi.updateState({ metrics, pass });
    } catch (e) {
      this.setState({ error: true });
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }

    return false; // prevent actual html form from POSTing
  };

  handleInputChange = (e: any) => {
    let { name, value } = e.target;

    this.setState({ [name]: value } as State);
  };

  render() {
    const { props, state } = this;
    const { workflowState } = props;

    const visible = workflowState.serialNumber && workflowState.product;

    if (!visible) {
      return null;
    }

    if (!state.stationResults) {
      return <div>Error: no results</div>;
    }

    const { passedSequence, sequenceTime, requiredStationIds } = state.stationResults;

    if (!requiredStationIds) {
      return null;
    }

    const {
      serialNumberGen,
      alignmentTest,
      leakTest,
      wetWeight,
      //noiseTest,
      functionalTest,
      acousticTest,
      corsairTest,
      //loadTest,

      dwellTest,
      shippingGasTest,
      subParts,
    } = state.stationResults || {};

    const { product } = workflowState;

    const description = (product.description || "").toLowerCase();

    const requiresDwellAndShippingGas =
      product == null ? false : description.includes("eldora") || description.includes("grizzly");

    return (
      <div>
        <table
          className="table table-bordered table-sm"
          style={{
            width: "auto",
          }}
        >
          <thead>
            <tr>
              <th>Station</th>
              <th>Date</th>
              <th>Time</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {product.producttype !== "Closed Loop" && (
              <tr>
                <td>Serial Number Generation</td>
                <td>
                  {serialNumberGen ? moment(serialNumberGen.created_at).format("YYYY-MM-DD") : "-"}
                </td>
                <td>
                  {serialNumberGen ? moment(serialNumberGen.created_at).format("hh:mm:ss A") : "-"}
                </td>
                <td>
                  {serialNumberGen ? (
                    <div>
                      {serialNumberGen.validated_at ? (
                        <strong className="text-success">
                          <Translate msg="pass" />
                        </strong>
                      ) : (
                        <strong className="text-success">
                          <Translate msg="fail" /> Not validated
                        </strong>
                      )}
                    </div>
                  ) : (
                    <strong className="text-danger">
                      <strong className="text-danger">Caution: Not Found</strong>
                    </strong>
                  )}
                </td>
              </tr>
            )}

            <StationInspectionRow
              enabled={requiredStationIds.includes(ALIGNMENT_TEST_STATION_ID)}
              date={alignmentTest?.created_at}
              name="Alignment Test"
              pass={alignmentTest ? alignmentTest.pass === 1 : null}
            />

            <StationInspectionRow
              enabled={requiredStationIds.includes(LEAK_TEST_STATION_ID)}
              date={leakTest?.created_at}
              name="Leak Test"
              pass={leakTest ? leakTest.pass === 1 : null}
            />

            <StationInspectionRow
              enabled={requiredStationIds.includes(WET_WEIGHT_STATION_ID)}
              date={wetWeight?.created_at}
              name="Wet Weight"
              pass={wetWeight ? wetWeight.pass === 1 : null}
            />

            <StationInspectionRow
              enabled={requiredStationIds.includes(FUNCTIONAL_TEST_STATION_ID)}
              date={functionalTest?.created_at}
              name="Functional Test"
              pass={functionalTest ? functionalTest.pass === 1 : null}
            />

            <StationInspectionRow
              enabled={!!corsairTest}
              date={corsairTest?.created_at}
              name="Func Test"
              pass={corsairTest?.pass ?? null}
            />

            <StationInspectionRow
              enabled={requiredStationIds.includes(ACOUSTIC_TEST_STATION_ID)}
              date={acousticTest?.created_at}
              name="Acoustic Test"
              pass={acousticTest ? acousticTest.pass === 1 : null}
            />

            {/* <StationInspectionRow
              enabled={requiredStationIds.includes(isEldora)}
              date={loadTest?.created_at}
              name="Load Test Rack"
              pass={loadTest ? loadTest.pass === 1 : null}
            /> */}

            <StationInspectionRow
              enabled={requiresDwellAndShippingGas}
              date={dwellTest?.created_at}
              name="Dwell Test"
              pass={dwellTest ? dwellTest.passfail === 1 : null}
            />

            <StationInspectionRow
              enabled={requiresDwellAndShippingGas}
              date={shippingGasTest?.created_at}
              name="Shipping Gas Test"
              pass={shippingGasTest ? shippingGasTest.passfail === 1 : null}
            />

            {product.producttype !== "Closed Loop" && (
              <tr>
                <td>Serial Number Association</td>
                <td>{subParts ? moment(subParts.created_at).format("YYYY-MM-DD") : "-"}</td>
                <td>{subParts ? moment(subParts.created_at).format("hh:mm:ss A") : "-"}</td>
                <td>
                  {subParts ? (
                    subParts.metrics &&
                    Object.entries(subParts.metrics).map(([key, value], index) => (
                      <div key={key}>Child Serial Number: {value}</div>
                    ))
                  ) : (
                    <strong className="text-danger"> Not Found</strong>
                  )}
                </td>
              </tr>
            )}

            <tr className="bg-light">
              <td colSpan={3}>Station Sequence Order</td>
              <td>
                {passedSequence ? (
                  <strong className="text-success">
                    <Translate msg="pass" />
                  </strong>
                ) : (
                  <strong className="text-danger">
                    <Translate msg="fail" />
                  </strong>
                )}
                {passedSequence && (
                  <span style={{ marginLeft: "20px" }}> Took {sequenceTime} minutes</span>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <br />

        {workflowState.metrics && (
          <div>
            Result:
            <div className={`alert ${workflowState.pass ? "alert-success" : "alert-danger"}`}>
              {workflowState.pass ? (
                "Pass"
              ) : (
                <span>
                  <Translate msg="fail" />: Send product to appropriate station
                </span>
              )}
            </div>
          </div>
        )}

        {!workflowState.metrics && (
          <div>
            <strong>Standard Inspection Procedure:</strong>

            {!workflowState.metrics && (
              <form onSubmit={this.handleSubmit} noValidate={true}>
                <div className="">
                  <label className="mr-3 form-check-label">
                    <input
                      className=""
                      type="radio"
                      name="pass"
                      value="true"
                      checked={this.state.pass === "true"}
                      onChange={this.handleInputChange}
                    />
                    <strong className="text-success">
                      <Translate msg="pass" />
                    </strong>
                  </label>

                  <label className="">
                    <input
                      className=""
                      type="radio"
                      name="pass"
                      value="false"
                      checked={this.state.pass === "false"}
                      onChange={this.handleInputChange}
                    />
                    <strong className="text-danger">
                      <Translate msg="fail" />
                    </strong>
                  </label>
                </div>

                {this.state.pass === "false" && (
                  <div>
                    Failure Reason:
                    <select
                      className="form-control"
                      style={{ maxWidth: "360px" }}
                      name="notes"
                      value={this.state.notes}
                      onChange={this.handleInputChange}
                    >
                      <option value="">
                        -- select --
                        {/* <Translate msg="select" /> */}
                      </option>
                      {failureReasons.map((failureReason) => (
                        <option key={failureReason} value={failureReason}>
                          {failureReason}
                          {/* <Translate msg={`failureReasons["${failureReason}"]`} /> */}
                        </option>
                      ))}

                      {failureReasons.includes(state.notes) === false && (
                        <option key={state.notes} value={state.notes}>
                          {state.notes}
                        </option>
                      )}
                    </select>
                  </div>
                )}

                <div>
                  <button
                    type="submit"
                    className="btn btn-primary m-3"
                    disabled={state.pass === null || (state.pass === "false" && !state.notes)}
                    onClick={this.handleSubmit}
                  >
                    <Translate msg="submit" />
                  </button>
                </div>
              </form>
            )}
          </div>
        )}

        {state.error && (
          <div className="alert alert-danger">An error occured, please reload the page.</div>
        )}

        {state.loading && <div>Loading, please wait&hellip;</div>}
      </div>
    );
  }
}
