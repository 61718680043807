/* eslint: disable */

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import i18n, { setLocale } from "../../i18n";
import { AppContextPropTypes } from "../../propTypes";
import { SignInForm } from "../auth/SignInForm";
import { Translate } from "../common";
import { ProductionDashboard } from "../ProductionDashboard";
import "./MainTemplate.scss";

const savedUser = JSON.parse((window as any).sessionStorage.getItem("user"));
const savedLocale = (window as any).sessionStorage.getItem("locale") || "cn-en";

export default class App extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  static childContextTypes = AppContextPropTypes;

  state = {
    locale: savedLocale,
    user: savedUser,
    pageTitle: "",
    adminDropdownOpen: false,
    languageDropdownOpen: false,
  };

  getChildContext() {
    return {
      i18n,
      locale: this.state.locale,
      user: this.state.user,
      setPageTitle: this.setPageTitle,
    };
  }

  setPageTitle = (pageTitle: string) => {
    this.setState({ pageTitle });
  };

  changeLocale = (locale: string) => {
    setLocale(locale);
    this.setState({ locale });
    sessionStorage.setItem("locale", locale);
  };

  signIn = async (user: object) => {
    sessionStorage.setItem("user", JSON.stringify(user));
    this.setState({ user });
  };

  signOut = () => {
    sessionStorage.removeItem("user");
    this.setState({ user: null });
  };

  componentDidMount() {
    this.changeLocale(savedLocale);
  }

  render() {
    const { state } = this;
    const { user } = state;

    const { href } = window.location;

    if (
      href.endsWith("/dashboard") ||
      href.endsWith("/dashboard/bay10") ||
      href.endsWith("/dashboard/bay14")
    ) {
      // hack to render without showing the rest of the temlpate
      return <ProductionDashboard />;
    }

    if (!user) {
      return (
        <div className="App">
          <main className="App__main">
            <div>
              <Dropdown
                className="ml-3"
                isOpen={state.languageDropdownOpen}
                toggle={() => this.setState({ languageDropdownOpen: !state.languageDropdownOpen })}
              >
                <DropdownToggle caret color="tertiary">
                  {this.state.locale === "en" && "English"}
                  {this.state.locale === "cn" && "Chinese - 中文简体"}
                  {this.state.locale === "zh" && "Trad Chinese - 繁體中文"}
                  {this.state.locale === "cn-en" && "Chinese+English"}
                </DropdownToggle>
                <DropdownMenu color="primary">
                  <DropdownItem onClick={() => this.changeLocale("en")}>English</DropdownItem>
                  <DropdownItem onClick={() => this.changeLocale("zh")}>
                    Trad Chinese - 繁體中文
                  </DropdownItem>
                  <DropdownItem onClick={() => this.changeLocale("cn")}>
                    Chinese - 中文简体
                  </DropdownItem>
                  <DropdownItem onClick={() => this.changeLocale("cn-en")}>
                    Chinese &amp; English
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <SignInForm onSignIn={this.signIn} />
          </main>
        </div>
      );
    }

    return (
      <div className="App">
        <nav data-e2e="loggedInNav" className="App__nav  bg-light flex-nowrap">
          <div>
            <h5>QPT V3</h5>
            <h6>
              {state.pageTitle && (
                <span data-e2e="pageTitle">
                  {" - "}
                  {state.pageTitle}
                </span>
              )}
            </h6>
          </div>

          {user && user.permissions <= 10 && (
            <Link to="/stations" className="nav-item nav-link">
              <small>
                <Translate msg="menu.chooseStation" lineBreak={false} />
              </small>
            </Link>
          )}

          <div className="ml-auto d-flex">
            {user.permissions <= 10 && (
              <Dropdown
                isOpen={state.adminDropdownOpen}
                toggle={() => this.setState({ adminDropdownOpen: !state.adminDropdownOpen })}
              >
                <DropdownToggle caret color="primary">
                  <Translate msg="menu.admin" />
                </DropdownToggle>
              </Dropdown>
            )}

            <Dropdown
              className="ml-3"
              isOpen={state.languageDropdownOpen}
              toggle={() => this.setState({ languageDropdownOpen: !state.languageDropdownOpen })}
            >
              <DropdownToggle caret color="tertiary">
                {this.state.locale === "en" && "English"}
                {this.state.locale === "zh" && "Trad Chinese - 繁體中文"}
                {this.state.locale === "cn-en" && "Chinese+English"}
              </DropdownToggle>
              <DropdownMenu color="primary">
                <DropdownItem onClick={() => this.changeLocale("en")}>English</DropdownItem>
                <DropdownItem onClick={() => this.changeLocale("zh")}>
                  Trad Chinese - 繁體中文
                </DropdownItem>
                <DropdownItem onClick={() => this.changeLocale("cn")}>
                  Chinese - 中文简体
                </DropdownItem>
                <DropdownItem onClick={() => this.changeLocale("cn-en")}>
                  Chinese &amp; English
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <div className="ml-3 d-flex flex-column text-sm">
              <small>
                {state.user.username}
                <br />
                <Link to={"/"} onClick={this.signOut}>
                  <Translate msg="signOut" />
                </Link>
              </small>
            </div>
          </div>
        </nav>

        <main className="App__main">{this.props.children}</main>

        <footer className="App__footer justify-content-around">Confidential -- Cool IT Systems</footer>
      </div>
    );
  }
}
