export const En = {
  menu: {
    admin: "Admin",
    reports: "Reports",
    chooseStation: "Choose Station",
    lotNumberGeneration: "Lot Number Generation",
  },
  stations: {
    "1": "Serial Number Entry Station",
    "2": "Leak Test Station",
    "3": "Dry Weight Station",
    "4": "Wet Weight Station",
    "5": "Functional Test Station",
    "6": "Noise Test Station",
    "9": "Failure Reason Entry",
    "10": "Repair Station",
    "11": "Sub Part Serial Number Entry (# Coldplates)",
    "12": "Customer Serial Number Association",
    "13": "Alignment Test",
    "14": "SerialNumber Association",
    "15": "Acoustic Screen Test",
    "17": "Simps Dwell / Decay Test",
    "99": "Final Inspection",
    corsair: "Functional Test",
  },
  failureReasons: {
    Leak: "Leak",
    "Coldplate defective": "Coldplate defective",
    "Functional defective": "Functional defective",
    "Tube defective": "Tube defective",
    "Bad or incorrect label": "Bad or incorrect label",
    "Radiator defective": "Radiator defective",
    "TIM damaged": "TIM damaged",
    "Bad Threads": "Bad Threads",
    "Assembled the wrong way": "Assembled the wrong way",
    "Component felt off": "Component felt off",
    "Other Cosmetic Issues": "Other Cosmetic Issues",
    "Missing or Loose Component": "Missing or Loose Component",
    "Subpart Entry Error": "Subpart Entry Error",
    "Station Sequence Order Error": "Station Sequence Order Error",
    "Child Serial Entry Error": "Child Serial Entry Error",
    Other: "Other",
  },
  select: "Select",
  signIn: "Sign In",
  signOut: "Sign Out",
  signInPrompt: "Please scan or enter your operator badge number.",
  loading: "Loading...",
  serialNotFoundSendPrevious: "Serial not found.  Send to previous station",
  reworkRequired: "Product Failed.  Send to Repair Station.",
  warning: "Warning",
  leakAt: " Leak At",
  productId: "Product ID",
  productInfo: "Product Info",
  productName: "Product Name",
  productDescription: "Product Description",
  productBarcode: "Product Barcode",
  productNotFound: "Product Not Found",
  productRevWarning: "Warning: Latest Revision is $1",
  productRevSwitch: "Change to Revision $1",
  serialNumber: "Serial Number",
  submit: "Submit",
  leakLocationPrompt: "Enter Leak Location",
  leakLocation: "Leak Location",
  newProduct: "New Product",
  changeProduct: "Change Product",
  leakTestStation: "Leak Test Station",
  logOff: "Log Off",
  noLeak: "No Leak",
  pass: "Pass",
  fail: "Fail",
  invalidFormat: "Invalid Format. Hint: $1",
  invalidSerialFormat: "Invalid Serial Number Format",
  nextSerialNumber: "Next Serial Number",
  enter: "Enter",
  user: "User",
  result: "Result",
  details: "Details",
  repairActivity: "Repair Activity",
  repairCode: "Repair Code",
  metrics: {
    formatPattern: "Format Pattern",
    decibels126: "Decibels at 12.6 Volts",
    decibels120: "Decibels at 12.0 Volts",
    decibels114: "Decibels at 11.4 Volts",
    current: "Current",
    pump: "Pump",
    voltage: "Voltage",
    coldPlate: "Cold Plate",
    subpart: "Subpart",
    parentSerialNumber: "Parent Serial Number",
    partSerialNumber: "Child Serial Number",
    dryWeight: "Dry Weight",
    wetWeight: "Wet Weight",
    weightDelta: "Weight Delta",
    minWeightDelta: "Product Min Weight Delta",
    maxWeightDelta: "Product Max Weight Delta",
    expectedCurrentRange: "Expected Current Range",
    expectedRpmRange: "Expected RPM Range",
    expectedVoltageRange: "Expected Voltage Range",
    pressure: "Pressure",
    minAllowedDecibels: "Min Allowed Decibels",
    maxAllowedDecibels: "Max Allowed Decibels",
    failureReason: "Failure Reason",
    alignmentTestResult: "Alignment Test Result",
  },
  units: {
    decibels: "decibels",
    grams: "grams",
    milliamps: "milliamps",
    volts: "volts",
    celsius: "Celsius",
    fahrenheit: "Fahrenheit",
    psi: "PSI",

    rpm: "RPM",
  },
  errors: {
    fatal: "An unexpected error occurred, please reload the page and try again.",
    duplicateEntry: "Duplicate entry: $1",
    productFailedPrevious: "Product failed previous station, send to repair station.",
    productNotTested: "Product has not been tested at the previous station.",
  },
};

export type AppLocale = typeof En;

export default En;
