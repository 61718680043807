export const LEAK_TEST_STATION_ID = 2;
export const DRY_WEIGHT_STATION_ID = 3;
export const WET_WEIGHT_STATION_ID = 4;
export const FUNCTIONAL_TEST_STATION_ID = 5;
export const NOISE_TEST_STATION_ID = 6;
export const FAILURE_REASON_ENTRY_ID = 9;
export const REPAIR_STATION_ID = 10;
export const COLDPLATE_ENTRY_ID = 11;
export const SUB_PART_ENTRY_ID = 12;
export const ALIGNMENT_TEST_STATION_ID = 13;
export const SERIALNUMBER_ASSOCIATION_STATION_ID = 14;
export const ACOUSTIC_TEST_STATION_ID = 15;
export const CORSAIR_TEST_STATION_ID = 16;
export const DWELL_TEST_STATION_ID = 17;
export const FINAL_TEST_STATION_ID = 99;

export const STATION_IDS = {
  LEAK_TEST_STATION_ID,
  DRY_WEIGHT_STATION_ID,
  WET_WEIGHT_STATION_ID,
  FUNCTIONAL_TEST_STATION_ID,
  NOISE_TEST_STATION_ID,
  FAILURE_REASON_ENTRY_ID,
  REPAIR_STATION_ID,
  COLDPLATE_ENTRY_ID,
  SUB_PART_ENTRY_ID,
  ALIGNMENT_TEST_STATION_ID,
  SERIALNUMBER_ASSOCIATION_STATION_ID,
  ACOUSTIC_TEST_STATION_ID,
  CORSAIR_TEST_STATION_ID,
  DWELL_TEST_STATION_ID,
  FINAL_TEST_STATION_ID,
};
