import React from "react";
import PropTypes from "prop-types";

import MetricsForm from "./MetricsForm";
import ShowMetricsSummary from "./ShowMetricsSummary";

import { AppContextPropTypes, WorkflowComponentPropTypes } from "../../../../../propTypes";
import {
  FormSubmitData,
  WorkflowComponentProps,
  FormMetricTypeProps,
} from "../../../../../ts/types";

export default class NoiseTestMetrics extends React.Component<
  WorkflowComponentProps & {
    metricTypes: FormMetricTypeProps[];
  }
> {
  static propTypes = {
    ...WorkflowComponentPropTypes,

    metricTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  static contextTypes = AppContextPropTypes;

  evaluateNoiseTestMetrics = async ({ metrics }: { metrics: FormSubmitData }) => {
    const { minNoiseDecibels, maxNoiseDecibels } = this.props.workflowState.product.metadata;

    console.debug("Noise test product metadata: ", this.props.workflowState.product.metadata);
    console.debug("Noise test allowed min/max: ", minNoiseDecibels, maxNoiseDecibels);

    if (!minNoiseDecibels || !maxNoiseDecibels) {
      return { pass: 1 };
    }

    let pass = true;

    pass =
      pass && metrics.decibels126 >= minNoiseDecibels && metrics.decibels126 <= maxNoiseDecibels;
    pass =
      pass && metrics.decibels120 >= minNoiseDecibels && metrics.decibels120 <= maxNoiseDecibels;
    pass =
      pass && metrics.decibels114 >= minNoiseDecibels && metrics.decibels114 <= maxNoiseDecibels;

    return { pass: pass ? 1 : 0 };
  };

  render() {
    const { props } = this;
    const { workflowState } = props;

    const visible = !!workflowState.serialNumber;

    if (!visible) {
      return false;
    }

    if (workflowState.metrics) {
      return (
        <div>
          <ShowMetricsSummary
            metricTypes={props.metricTypes}
            metrics={workflowState.metrics}
            pass={workflowState.pass}
          />
        </div>
      );
    }

    return <MetricsForm {...props} evaluateMetrics={this.evaluateNoiseTestMetrics} />;
  }
}
