import En from "./en";

export const Zh: typeof En = {
  menu: {
    admin: "管理員",
    reports: "報告",
    chooseStation: "選擇工作站",
    lotNumberGeneration: "批號生成",
  },
  stations: {
    "1": "序列號輸入站",
    "2": "泄漏測試站",
    "3": "乾重站",
    "4": "濕重站",
    "5": "功能測試站",
    "6": "噪音測試站",
    "9": "不良品離線紀錄",
    "10": "維修站",
    "11": "成品 – 子部件序列號聯系頁面 ",
    "12": "序列號關聯 ",
    "13": "对齐测试", // TODO: kenny review
    "14": "序列号关联测试",
    "15": "声屏测试结果",
    "17": "停留/衰减测试",
    "99": "最終檢查",
    corsair: "Functional Test",
  },
  failureReasons: {
    Leak: "泄漏",
    "Coldplate defective": "鏟齒不良",
    "Functional defective": "功能不良",
    "Tube defective": "水管不良",
    "Bad or incorrect label": "標簽不良",
    "Radiator defective": "水箱不良",
    "TIM damaged": "導熱膏刮傷",
    "Bad Threads": "螺牙不良",
    "Assembled the wrong way": "組裝錯誤",
    "Component felt off": "零件脫落",
    "Other Cosmetic Issues": "其它外觀不良",
    "Missing or Loose Component": "缺少或鬆散組件",
    "Subpart Entry Error": "錯誤或無序列號關聯紀錄",
    "Station Sequence Order Error": "測試順序錯誤",
    "Child Serial Entry Error": "錯誤或無子部件序列號",

    Other: "其它",
  },
  select: "選擇",
  signIn: "登錄",
  signOut: "退出",
  signInPrompt: "請掃描或輸入您的員工號。",
  loading: "載入中...",
  serialNotFoundSendPrevious: "該序列號前工站未掃描，返回上一工作台",
  reworkRequired: "產品測試失敗。發送到維修站",
  warning: "警告",
  leakAt: " 泄漏在",
  productId: "產品 ID",
  productInfo: "產品信息",
  productName: "產品名稱",
  productDescription: "產品描述",
  productBarcode: "產品條碼",
  productNotFound: "產品未找到",
  productRevWarning: "最新版本是 $1",
  productRevSwitch: "更改為修訂版 $1",
  serialNumber: "流水号 (序列号)",
  submit: "提交結果",
  leakLocationPrompt: "輸入泄漏位置",
  leakLocation: "輸入泄漏位置",
  newProduct: "新產品",
  changeProduct: "改變產品",
  leakTestStation: "泄漏測試站",
  logOff: "注銷",
  noLeak: "没有泄漏",
  pass: "通過",
  fail: "失敗",
  invalidFormat: "格式無效。提示： $1",
  invalidSerialFormat: "無效的序列號格式",
  nextSerialNumber: "下一個序列號",
  enter: "進入",
  user: "用戶",
  result: "結果",
  details: "細節",
  repairActivity: "維修活動",
  repairCode: "维修码",
  metrics: {
    formatPattern: "格式模式",
    decibels126: "在12.6伏特分貝",
    decibels120: "在12.0伏特分貝",
    decibels114: "在11.4伏特分貝",
    current: "當前",
    pump: "泵",
    voltage: "電壓",
    coldPlate: "鏟齒板",
    subpart: "組件",
    parentSerialNumber: "父序列号", // not translated yet
    partSerialNumber: "部件序列号",
    dryWeight: "乾重",
    wetWeight: "濕重",
    weightDelta: "冷卻液重量",
    minWeightDelta: "冷卻液重量下限",
    maxWeightDelta: "冷卻液重量上限",
    expectedCurrentRange: "預期電流範圍",
    expectedRpmRange: "預期的RPM範圍",
    expectedVoltageRange: "預期電壓範圍",
    pressure: "壓力",
    minAllowedDecibels: "允許的最小分貝值",
    maxAllowedDecibels: "允許的最大分貝值",
    failureReason: "失敗原因",
    alignmentTestResult: "校准测试结果", // not translated yet,
  },
  units: {
    decibels: "分貝",
    grams: "克",
    milliamps: "毫安",
    volts: "伏",
    celsius: "攝氏",
    fahrenheit: "華氏",
    psi: "PSI",
    rpm: "RPM",
  },
  errors: {
    fatal: "發生意外錯誤，請重新加載頁面並重試。",
    duplicateEntry: "雙重輸入： $1",
    productFailedPrevious: "產品上一站失敗，發送到維修站。",
    productNotTested: "產品未在前一站進行測試。",
  },
};

export default Zh;
