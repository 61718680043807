import axios from "axios";
import React from "react";
import { RouteComponentProps } from "react-router";
import { AppContextPropTypes } from "../../../propTypes";
import { BoxRow, PalletRow } from "../../../ts/types";
import { FatalError, Loading } from "../../common";

type State = {
  loading: boolean;
  error: boolean;
  serial: string;
  results: null | (BoxRow & PalletRow)[];
};

// User configurable assembly line station
export default class PackingLookup extends React.Component<RouteComponentProps, State> {
  static contextTypes = AppContextPropTypes;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      serial: "",
      results: null,
    };
  }

  componentDidMount() {
    this.context.setPageTitle("Packing Lookup");
  }

  preventFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    return false;
  };

  handleAddtoBox = async () => {};

  lookup = async () => {
    const { serial } = this.state;

    this.setState({ loading: true });
    try {
      const { data } = await axios.post(`/api/stations/packingLookup`, {
        payload: {
          unit: serial,
          box: serial,
          pallet: serial,
        },
      });

      console.log("data", data);

      this.setState({ results: data });
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    } finally {
      this.setState({ loading: false });
    }
  };

  handlePalletFinished = async () => {};

  render() {
    const { loading, error, serial, results } = this.state;

    return (
      <form onSubmit={this.preventFormSubmit}>
        {error && <FatalError />}
        {loading && <Loading />}
        Serial Number Lookup (Unit, Box, or Pallet):
        <input
          className="form-control-sm ml-1"
          autoFocus
          type="text"
          name="serial"
          required
          maxLength={30}
          minLength={3}
          value={serial}
          onChange={(e) => this.setState({ serial: e.target.value })}
          style={{ width: 200 }}
        />
        <button className="btn btn-tertriary m-1" onClick={this.lookup} disabled={!serial}>
          Go
        </button>
        {results && (
          <div>
            <h2>Results:</h2>

            <table className="table table-bordered table-sm table-striped table-hover">
              <thead>
                <tr>
                  <th>Unit</th>
                  <th>Box</th>
                  <th>Pallet</th>
                </tr>
              </thead>
              <tbody>
                {results.map((row) => (
                  <tr>
                    <td>{row.unit}</td>
                    <td>{row.box}</td>
                    <td>{row.pallet}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </form>
    );
  }
}
