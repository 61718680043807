import Axios from "axios";
import React, { useState } from "react";
import { Alert } from "reactstrap";

export const TestReportStation: React.FC<{}> = () => {
  const [files, setFiles] = useState<FileList | null>(null);
  const [uploading, setUploading] = useState(false);
  const [userFeedback, setUserFeedback] = useState<string>("");

  return (
    <section>
      <form
        encType="multipart/form-data"
        onSubmit={async (e) => {
          e.preventDefault();
          if (!files?.length) {
            setUserFeedback("No file");
            return;
          }

          setUploading(true);
          setUserFeedback("");
          try {
            const formData = new FormData();
            formData.append("testReport", files[0]);

            await Axios.post("/api/stations/testReport", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            setUserFeedback("Success");
          } catch (e) {
            console.error("ERR", e);
            setUserFeedback("Error occurred: " + e.response.data);
          } finally {
            setUploading(false);
          }
        }}
      >
        <p>Please upload the completed Test Report spreadsheet:</p>

        <input
          type="file"
          name="testReport"
          className="form-control-file"
          onChange={(e) => setFiles(e.target.files)}
        />
        <br />

        {uploading && <div>Uploading, please wait...</div>}
        {userFeedback && <Alert>{userFeedback}</Alert>}

        <button type="submit" className="btn btn-primary" disabled={uploading}>
          Submit
        </button>
      </form>
    </section>
  );
};
