import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { AppContextPropTypes } from "../../propTypes";
import { Translate, FatalError } from "../common";
import { StationRow, StationConfig, UserRow } from "../../ts/types";
import { NOISE_TEST_STATION_ID } from "../../constants";

const Tile = styled.div`
  padding: 15px;
  width: 165px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;
  flex-wrap: wrap;
`;

type State = {
  stations: null | StationRow[];
  error: null | boolean;
};

type Feature = "serial_number_station" | "calgary_stations";

export const FEATURES = (process.env.REACT_APP_FEATURES?.trim() || "").split(",") as Feature[];

export default class ChooseStation extends React.Component<{}, State> {
  static propTypes = {};

  static contextTypes = AppContextPropTypes;

  state: State = {
    stations: null,
    error: null,
  };

  loadStations = async () => {
    try {
      const response = await axios.get("/api/stations");

      const stations = response.data;

      this.setState({ stations });
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  componentDidMount() {
    this.loadStations();
  }

  render() {
    const { state, context } = this;
    const { user }: { user: UserRow } = context;

    return (
      <section>
        <h5>
          <Translate msg="menu.chooseStation" />
        </h5>

        {state.error && <FatalError />}

        <div className="d-flex flex-wrap">
          {FEATURES.includes("serial_number_station") && (
            <Link to="/stations/serialNumberGen" className="btn btn-primary m-2">
              <Tile>Serial Number Generation</Tile>
            </Link>
          )}

          {state.stations &&
            state.stations.map((station) => {
              const { userPermission } = station.config as StationConfig;

              if (userPermission && user[userPermission] !== 1) {
                return null;
              }

              // noise test disabled
              if (station.id === NOISE_TEST_STATION_ID) return null;

              return (
                <div key={station.id}>
                  <Link to={`/stations/${station.id}`} className="btn btn-primary m-2">
                    <Tile>
                      <Translate msg={`stations.${station.id}`} lineBreak={true} />
                    </Tile>
                  </Link>
                </div>
              );
            })}

          <Link to={`/stations/corsair`} className="btn btn-primary m-2">
            <Tile>
              <Translate msg={`stations.corsair`} lineBreak={true} />
            </Tile>
          </Link>

          {FEATURES.includes("calgary_stations") && (
            <>
              <Link to="/stations/testReport" className="btn btn-primary m-2">
                <Tile>Test Report Station</Tile>
              </Link>

              <Link to="/stations/loadTestRack" className="btn btn-primary m-2">
                <Tile>Load Test Rack Station</Tile>
              </Link>
              <Link to="/stations/dwellDecayStation" className="btn btn-primary m-2">
                <Tile>Calgary Dwell/Decay Station</Tile>
              </Link>
              <Link to="/stations/boxing" className="btn btn-primary m-2">
                <Tile>Boxing Station</Tile>
              </Link>
              <Link to="/stations/pallet" className="btn btn-primary m-2">
                <Tile>Pallet Station</Tile>
              </Link>
              <Link to="/stations/packingLookup" className="btn btn-primary m-2">
                <Tile>Box/Pallet Lookup</Tile>
              </Link>
              <Link to="/stations/receiving" className="btn btn-primary m-2">
                <Tile>Receiving Station</Tile>
              </Link>
              <Link to="/stations/dashboardCount" className="btn btn-primary m-2">
                <Tile>Dashboard Count Update</Tile>
              </Link>
            </>
          )}
        </div>

        <br />

        {user.station8 === 1 && (
          <div>
            <h5>
              <Translate msg={"menu.lotNumberGeneration"} />
            </h5>

            <div className="d-flex flex-wrap">
              {FEATURES.includes("calgary_stations") && (
                <Link to={`/lotNumbers/calgary`} className="btn btn-primary m-2">
                  <Tile>
                    <Translate msg="menu.lotNumberGeneration" lineBreak={true} />- Calgary
                  </Tile>
                </Link>
              )}

              <Link to={`/lotNumbers/simps`} className="btn btn-primary m-2">
                <Tile>
                  <Translate msg="menu.lotNumberGeneration" lineBreak={true} />- Simps
                </Tile>
              </Link>
            </div>
          </div>
        )}

        {FEATURES.includes("calgary_stations") && user.permissions <= 10 && (
          <div>
            <h5>Admin</h5>
            <Link to="/admin/dashboardConfig" className="btn btn-primary m-2">
              Dashboard Config
            </Link>
            <Link to="/dashboard/bay10" className="btn btn-primary m-2">
              Bay 10 Dashboard
            </Link>
            <Link to="/dashboard/bay14" className="btn btn-primary m-2">
              Bay 14 Dashboard
            </Link>
          </div>
        )}
      </section>
    );
  }
}
