import axios from "axios";
import React from "react";
import { AppContextPropTypes } from "../../../../../propTypes";
import { WorkflowComponentProps } from "../../../../../ts/types";
import { Translate } from "../../../../common";

export class AlignmentTestMetrics extends React.Component<WorkflowComponentProps> {
  static contextTypes = AppContextPropTypes;

  state: {
    alignmentPass: string | null;
    formSubmitted: boolean;
    error: boolean;
    loading: boolean;
  } = {
    alignmentPass: null,
    formSubmitted: false,
    error: false,
    loading: false,
  };

  render() {
    const { props, state } = this;
    const { workflowState } = props;

    const visible = workflowState.serialNumber && workflowState.product;

    if (!visible) {
      return null;
    }

    const { metrics, pass } = workflowState;

    if (metrics) {
      return (
        <div>
          <div className={`alert ${pass ? "alert-info" : "alert-danger"}`}>
            <strong>
              <Translate msg="result" />:
            </strong>{" "}
            {pass ? <Translate msg="pass" /> : <Translate msg="fail" />}
            {!pass && (
              <div>
                <br />
                <Translate msg="reworkRequired" />
              </div>
            )}
          </div>
        </div>
      );
    }

    // show the form
    return (
      <div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            const { state, context } = this;
            const { workflowProps, workflowState } = this.props;

            this.setState({ error: false, loading: true });

            if (state.alignmentPass === null) throw new Error("null not expected");

            const pass = parseInt(state.alignmentPass);

            const metrics = { alignmentPass: pass };

            try {
              const response = await axios.post(
                `/api/serialNumbers/${workflowState.serialNumber}/metrics`,
                {
                  payload: {
                    stationId: workflowProps.station.id,
                    userId: context.user.uid,
                    productId: workflowState.product.ptid,
                    pass,
                    metrics,
                  },
                }
              );

              const result = response.data;

              if (result.status !== "success") {
                throw new Error("success expected");
              }

              this.props.workflowApi.updateState({ metrics, pass });
            } catch (e) {
              this.setState({ error: true });
              console.error(e);
            } finally {
              this.setState({ loading: false });
            }

            console.log("SUBMIT DONE");
            return false;
          }}
        >
          <div className="d-flex align-items-center">
            <strong style={{ marginRight: "10px" }}>
              <Translate msg="metrics.alignmentTestResult" />
            </strong>{" "}
            <Translate msg="pass" />
            <input
              type="radio"
              name="alignmentPass"
              onChange={(e) => this.setState({ alignmentPass: "1" })}
              value="1"
              required
              style={{ marginRight: "40px", marginLeft: "10px" }}
            />
            <Translate msg="fail" />
            <input
              type="radio"
              name="alignmentPass"
              onChange={(e) => this.setState({ alignmentPass: "0" })}
              value="0"
              required
              style={{ marginRight: "40px", marginLeft: "10px" }}
            />
          </div>

          <br />
          <button className="btn btn-primary" type="submit" disabled={state.alignmentPass === null}>
            <Translate msg="submit" />
          </button>
        </form>

        {state.error && <div>Error, please reload</div>}
      </div>
    );
  }
}
