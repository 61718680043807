import React from "react";
import { AppContextPropTypes, WorkflowComponentPropTypes } from "../../../../propTypes";
import { FormSubmitData, WorkflowComponentProps } from "../../../../ts/types";
import Form from "../common/form/Form";

export default class SetSerialNumber extends React.Component<WorkflowComponentProps> {
  static propTypes = {
    ...WorkflowComponentPropTypes,
  };

  static contextTypes = AppContextPropTypes;

  validateSerialNumber = (formData: FormSubmitData) => {
    const { props } = this;

    const { product } = props.workflowState;
    const { serialNumber } = formData;

    // always allow serial numbers 100-199 for testing
    const serialNumberWhitelist = /^1[0-9][0-9]$/;
    if (serialNumberWhitelist.test(serialNumber)) {
      return;
    }

    const sn_pattern = product.sn_pattern || "[a-zA-Z0-9]{3,30}";

    const serialNumberRegex = RegExp(`^${sn_pattern}$`);
    const result = serialNumberRegex.test(serialNumber);

    if (!result) {
      const validationErrorArgs = ["invalidFormat", product.sn_pattern];
      return validationErrorArgs;
    }
  };

  handleFormSubmit = ({ serialNumber }: FormSubmitData) => {
    const { props } = this;

    props.workflowApi.updateState({ serialNumber });
  };

  render() {
    const { props } = this;

    const { workflowState } = props;

    const shouldRender = workflowState.product && !workflowState.serialNumber;

    if (!shouldRender) {
      return null;
    }

    return (
      <Form
        noValidate={true}
        inputs={[
          {
            i18nLabel: "serialNumber",
            type: "text",
            name: "serialNumber",
            required: true,
            pattern: workflowState.product.sn_pattern || ".{3,30}",
            size: 30,
            autoComplete: "off",
          },
        ]}
        trimInputs={true}
        validateData={this.validateSerialNumber}
        onSubmit={this.handleFormSubmit}
      />
    );
  }
}
