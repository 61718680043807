import PropTypes from "prop-types";
import React from "react";
import { AppContextPropTypes, StationPropTypes } from "../../../propTypes";
import { StationRow } from "../../../ts/types";
import WorkflowComponents from "./components";

const initialState = Object.freeze({
  error: false,
});

type Props = {
  station: StationRow;
  components: any[];
};
type State = {
  [key: string]: any;
};
export default class GenericWorkflow extends React.Component<Props, State> {
  static propTypes = {
    station: StationPropTypes.isRequired,
    components: PropTypes.array.isRequired,
  };

  static contextTypes = AppContextPropTypes;

  state = initialState;

  resetWorkflowState = () => {
    const keys = Object.keys(this.state);
    const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }), {});
    this.setState({ ...stateReset, ...initialState });
  };

  updateWorkflowState = (args: any) => {
    this.setState(args);
  };

  renderComponent = ({ type, props }: { type: string; props: any }, key: number) => {
    const Component = WorkflowComponents[type];
    if (!Component) {
      throw new Error("component type not recognized: " + type);
    }

    const workflowProps = this.props;
    const workflowState = { ...this.state };

    // This is the API passed to each child component to interact with the workflow state
    const workflowApi = {
      resetState: this.resetWorkflowState,
      updateState: this.updateWorkflowState,
    };

    return (
      <Component
        key={key}
        {...props}
        workflowProps={workflowProps}
        workflowState={workflowState}
        workflowApi={workflowApi}
      />
    );
  };

  render() {
    const { props } = this;

    // Each workflow 'step' is made up or one or more components
    // which are responsible for showing and updating the workflow state

    const children = props.components.map((component, index) =>
      this.renderComponent(component, index)
    );

    return <section>{children}</section>;
  }
}
