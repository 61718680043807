import React, { useReducer } from "react";
import { UserRow } from "../../ts/types";

export type AppState = {
  locale: string;
  user?: UserRow;
};

export const initialState: AppState = Object.freeze({
  locale: sessionStorage.getItem("locale") || "cn-en",
  user: (JSON.parse(sessionStorage.getItem("user") || "null") as UserRow) || undefined,
});

export type AppDispatch = (action: AppAction) => void;

export const AppStateContext = React.createContext<AppState | undefined>(undefined);
export const AppDispatchContext = React.createContext<AppDispatch | undefined>(undefined);

type AppAction =
  | { type: "signIn"; user: UserRow }
  | { type: "signOut" }
  | { type: "setLocale"; locale: "en" | "cn" | "cn-en" | "zh" };

export const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case "signIn":
      return { ...initialState, user: action.user };

    case "signOut":
      return { ...initialState };

    case "setLocale":
      return { ...initialState, locale: action.locale };

    default:
      throw new Error("unhandled action");
  }
};

export const useAppState = (): AppState => {
  const context = React.useContext(AppStateContext);
  if (!context) throw new Error("must be used within AppContextProvider");

  return context;
};

export const useAppDispatch = (): AppDispatch => {
  const context = React.useContext(AppDispatchContext);
  if (!context) throw new Error("must be used within AppContextProvider");

  return context;
};

export const AppContextProvider: React.FC = ({ children }) => {
  const [appState, appDispatch] = useReducer(appReducer, initialState);

  return (
    <AppDispatchContext.Provider value={appDispatch}>
      <AppStateContext.Provider value={appState}>{children}</AppStateContext.Provider>
    </AppDispatchContext.Provider>
  );
};
