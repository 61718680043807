import Axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ProductionDashboardData } from "../ts/types";

const StyledInput = styled.input`
  width: 80px;
`;

type ProductionDashboardDataWithAdd = ProductionDashboardData & { add?: number };

export const DashboardCount: React.FunctionComponent = () => {
  const [items, setItems] = useState<ProductionDashboardDataWithAdd[]>([]);

  const refreshData = async () => {
    const { data: items } = await Axios.get<ProductionDashboardDataWithAdd[]>(
      "/api/productionDashboard"
    );

    items.forEach((item) => (item.add = 0));

    setItems(items);
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <section>
      <div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            const itemAttributesToSave = items.map(({ id, daily_actual, weekly_actual, add }) => ({
              id,
              daily_actual: daily_actual + (add || 0),
              weekly_actual: weekly_actual + (add || 0),
            }));

            await Axios.post("api/productionDashboard", {
              payload: itemAttributesToSave,
            });

            refreshData();
          }}
        >
          <table className="table table-striped table-sm ">
            <tbody>
              <tr>
                <th>Line</th>
                <th>Daily Actual</th>
                <th>Add X</th>
              </tr>
            </tbody>
            <tbody>
              {items.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{item.description}</td>
                    <td>
                      <StyledInput
                        className="form-control"
                        type="number"
                        name="actual"
                        value={item.daily_actual}
                        onChange={(e) => {
                          const newItems = _.cloneDeep(items);
                          newItems[index].daily_actual = parseInt(e.target.value);
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <label>
                        <StyledInput
                          className="form-control"
                          type="number"
                          name="actual"
                          value={item.add}
                          onChange={(e) => {
                            const newItems = _.cloneDeep(items);
                            newItems[index].add = parseInt(e.target.value);
                            setItems(newItems);
                          }}
                        />
                      </label>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button className="btn btn-primary">Save</button>
          &nbsp;
          <button className="btn btn-secondary" onClick={() => refreshData()}>
            Refresh
          </button>
        </form>
      </div>
    </section>
  );
};
