import Axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ProductionDashboardData } from "../ts/types";

const StyledInput = styled.input`
  width: 70px;
`;

export const ProductionDashboardConfig: React.FunctionComponent = () => {
  const [items, setItems] = useState<ProductionDashboardData[]>([]);

  const refreshData = async () => {
    const { data: items } = await Axios.get<ProductionDashboardData[]>("/api/productionDashboard");

    setItems(items);
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <section>
      <div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            const itemAttributesToSave = items.map(
              ({
                id,
                ptid,
                friendly_description,
                daily_target,
                weekly_target,
                hourly_target,
                shift_start_hour,
                shift_length_hours,
                pct_overperform,
                pct_underperform,
                line_down,
                min_refresh_freq_secs,
              }) => ({
                id,
                ptid,
                friendly_description,
                daily_target,
                weekly_target,
                hourly_target,
                shift_start_hour,
                shift_length_hours,
                pct_overperform,
                pct_underperform,
                line_down,
                min_refresh_freq_secs,
              })
            );

            await Axios.post("api/productionDashboard", {
              payload: itemAttributesToSave,
            });

            refreshData();
          }}
        >
          <table className="table table-striped table-sm ">
            <tbody>
              <tr>
                <th>Line</th>
                <th>
                  Hour
                  <br />
                  Target
                </th>
                <th>
                  Daily
                  <br /> Target
                </th>
                <th>
                  Week <br />
                  Target
                </th>
                <th>
                  Shift
                  <br /> Start <br /> (0-23)
                </th>
                <th>
                  Shift
                  <br /> Length
                  <br /> Hrs
                </th>

                <th>% Over</th>
                <th>% Under</th>
                <th>Down?</th>
                <th>
                  Refresh
                  <br />
                  Rate
                  <br />
                  Seconds
                </th>
              </tr>
            </tbody>
            <tbody>
              {items.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <StyledInput
                        style={{ width: "120px" }}
                        className="form-control"
                        type="text"
                        value={item.friendly_description}
                        onChange={(e) => {
                          const newItems = _.cloneDeep(items);
                          newItems[index].friendly_description = e.target.value;
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <StyledInput
                        className="form-control"
                        type="number"
                        value={item.hourly_target}
                        onChange={(e) => {
                          const newItems = _.cloneDeep(items);
                          newItems[index].hourly_target = parseInt(e.target.value);
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <StyledInput
                        className="form-control"
                        type="number"
                        value={item.daily_target}
                        onChange={(e) => {
                          const newItems = _.cloneDeep(items);
                          newItems[index].daily_target = parseInt(e.target.value);
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <StyledInput
                        className="form-control"
                        type="number"
                        value={item.weekly_target}
                        onChange={(e) => {
                          const newItems = _.cloneDeep(items);
                          newItems[index].weekly_target = parseInt(e.target.value);
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <StyledInput
                        className="form-control"
                        type="number"
                        value={item.shift_start_hour}
                        onChange={(e) => {
                          const newItems = _.cloneDeep(items);
                          newItems[index].shift_start_hour = parseFloat(e.target.value);
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <StyledInput
                        className="form-control"
                        type="number"
                        value={item.shift_length_hours}
                        onChange={(e) => {
                          const newItems = _.cloneDeep(items);
                          newItems[index].shift_length_hours = parseFloat(e.target.value);
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <StyledInput
                        className="form-control"
                        type="number"
                        value={item.pct_overperform}
                        onChange={(e) => {
                          const newItems = _.cloneDeep(items);
                          newItems[index].pct_overperform = parseFloat(e.target.value);
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <StyledInput
                        className="form-control"
                        type="number"
                        value={item.pct_underperform}
                        onChange={(e) => {
                          const newItems = _.cloneDeep(items);
                          newItems[index].pct_underperform = parseFloat(e.target.value);
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <StyledInput
                        className="form-control"
                        type="number"
                        value={item.line_down}
                        onChange={(e) => {
                          const newItems = _.cloneDeep(items);
                          newItems[index].line_down = e.target.value === "1" ? 1 : 0;
                          setItems(newItems);
                        }}
                      />
                    </td>
                    <td>
                      <StyledInput
                        className="form-control"
                        type="number"
                        value={item.min_refresh_freq_secs}
                        onChange={(e) => {
                          const newItems = _.cloneDeep(items);
                          newItems[index].min_refresh_freq_secs = parseInt(e.target.value);
                          setItems(newItems);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button className="btn btn-primary">Save</button>
          &nbsp;
          <button className="btn btn-secondary" onClick={() => refreshData()}>
            Refresh
          </button>
        </form>
      </div>
    </section>
  );
};
