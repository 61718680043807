import React from "react";
import { translate } from "../../i18n";
import { AppContextPropTypes } from "../../propTypes";

export const ErrorMessage: React.FC = ({ children }) => (
  <div className="alert alert-danger">{children}</div>
);

export class Loading extends React.Component {
  render() {
    return (
      <div>
        <Translate msg="loading" />{" "}
      </div>
    );
  }
}

export class FatalError extends React.Component {
  render() {
    return (
      <ErrorMessage>
        <Translate msg="errors.fatal" />
        {this.props.children}
      </ErrorMessage>
    );
  }
}

export class HttpError extends React.Component<{ status: number }> {
  render() {
    return <FatalError>{this.props.status >= -1 && "HTTP Status " + this.props.status}</FatalError>;
  }
}

export class ProductFailed extends React.Component {
  render() {
    return (
      <div className="alert alert-danger mt-3">
        <Translate msg="reworkRequired" />{" "}
      </div>
    );
  }
}

export const Warning: React.FC = ({ children }) => (
  <div className="alert alert-warning">{children}</div>
);

export class Translate extends React.Component<{
  msg: string;
  lineBreak?: boolean;
  args: any;
}> {
  static defaultProps = {
    args: [],
    lineBreak: false,
  };
  static contextTypes = AppContextPropTypes;

  render() {
    const { msg, lineBreak, args } = this.props;
    const { locale } = this.context;

    if (!msg) {
      return null;
    }

    if (locale === "cn-en") {
      return (
        <React.Fragment>
          {translate("cn", msg, ...args)}
          {lineBreak && <br />}
          {!lineBreak && " ("}
          {translate("en", msg, ...args)}
          {!lineBreak && ")"}
        </React.Fragment>
      );
    }

    return <React.Fragment>{translate(locale, msg, ...args)}</React.Fragment>;
  }
}
