import axios from "axios";
import React, { RefObject } from "react";
import styled from "styled-components";
import { AppContextPropTypes } from "../../../propTypes";
import { BoxRow, BoxSaveParams, FinalInspectionResult, ProductConfig } from "../../../ts/types";
import { FatalError, Loading } from "../../common";
import { AVAILABLE_PRINTERS, calculateBoxWeight } from "./common";
import { LabelGrid2 } from "./Layout";

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  align-items: center;
  gap: 5px;
`;

type BoxingStationState = {
  loading: boolean;
  error: string | null;
  validation: boolean;

  printer: string;
  printerStatus: string | null;

  // Configuration
  productConfigName: string;
  productConfig: ProductConfig | null;
  productConfigs: readonly ProductConfig[] | null;

  // Unit/Box stuff
  autoPrintBoxLabels: boolean;
  unitSerial: string;
  boxFeedback: string;
  boxSearch: string;
  boxSerial: string;
  boxIsNew: boolean;

  units: BoxRow[];
};

export default class BoxingStation extends React.Component {
  static contextTypes = AppContextPropTypes;

  scanUnitInputRef: RefObject<HTMLInputElement>;

  state: BoxingStationState = {
    loading: false,
    error: null,

    validation: localStorage.DEBUG ? false : true,

    printer: localStorage.getItem("printer") || "",
    printerStatus: null,

    // Configuration
    productConfigName: localStorage.productConfigName || "",
    productConfig: null,
    productConfigs: null,

    // Unit/Box stuff
    autoPrintBoxLabels: true,
    unitSerial: "",
    boxFeedback: "",
    boxSearch: "",
    boxSerial: "",
    boxIsNew: false,
    units: [],
  };

  constructor(props: any) {
    super(props);
    this.scanUnitInputRef = React.createRef<HTMLInputElement>();
  }

  componentDidMount() {
    this.context.setPageTitle("Boxing Station");

    this.loadProductConfigs();
  }

  loadProductConfigs = async () => {
    try {
      const { data: productConfigs } = await axios.get<ProductConfig[]>("/api/productConfigs");

      this.setState({
        productConfigs,
        productConfig: productConfigs.find((c) => c.productname === this.state.productConfigName),
      });
    } catch (e) {
      console.error(e);
      this.setState({ error: e });
    }
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  preventFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    return false;
  };

  setStateAsync = (newState: any): Promise<void> =>
    new Promise((resolve) => this.setState(newState, () => resolve()));

  clearBoxForm = async () => {
    await this.setStateAsync({
      boxIsNew: false,
      boxSearch: "",
      boxSerial: "",
      units: [],
      unitSerial: "",
      boxFeedback: "",
    });
  };

  newBox = async () => {
    console.log("newBox");
    await this.clearBoxForm();

    try {
      const { data: sequenceId } = await axios.post<string>(
        "/api/stations/packing/box/serialNumber"
      );
      console.log("sequenceId", sequenceId);

      const boxSerial = sequenceId.padStart(7, "0");

      await this.setStateAsync({
        boxIsNew: true,
        boxSerial,
      });

      this.scanUnitInputRef.current && this.scanUnitInputRef.current.focus();
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  loadBox = async (boxSearch: string, showAlert = true) => {
    boxSearch = boxSearch.trim();

    if (!boxSearch) {
      alert("Cannot be blank");
    }

    await this.clearBoxForm();

    try {
      const { data: units } = await axios.get<BoxRow[]>(`/api/stations/packing/box/${boxSearch}`);

      console.log("loadBox data", units);

      if (showAlert && !units.length) {
        alert("Box not found, or no units in box");
        return;
      }

      this.setState({
        boxSerial: boxSearch,
        boxSearch: "",
        units,
      });
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  addUnitToBox = async () => {
    this.setState({ boxFeedback: "", unitSerial: "" });

    try {
      const { validation, boxSerial, unitSerial, units, productConfig } = this.state;
      const { unitsPerBox } = productConfig as ProductConfig;

      const { data: inspectionResult } = await axios.get<FinalInspectionResult>(
        `/api/serialNumbers/${unitSerial}/finalInspection`
      );

      console.log("final inspection result", unitSerial, inspectionResult);

      if (validation) {
        if (!inspectionResult.found) {
          this.setState({ boxFeedback: `Unit '${unitSerial}' was not found in records.` });
          return;
        }

        if (!inspectionResult.passedStations) {
          this.setState({
            boxFeedback: `Unit '${unitSerial}' has not passed all required stations.`,
          });
          return;
        }
        if (units.length) {
          const expectedPtid = units[0].ptid;
          const ptid = inspectionResult.productId;

          const productMismatch = ptid !== expectedPtid;

          if (productMismatch) {
            // TODO: allow different revisions of same product in the same box
            //this.setState({
            //  boxFeedback: `Product Type or Revision mismatch (actual ptid: ${ptid} expected ptid: ${expectedPtid})`,
            //});
            //return;
          }
        }
      }
      if (units.find((u) => u.unit === unitSerial)) {
        this.setState({ boxFeedback: `Unit '${unitSerial}' already added to this box.` });
        return;
      }
      if (units.length >= unitsPerBox) {
        this.setState({ boxFeedback: `Box is already full.` });
        return;
      }

      const unitsToSave = units.map((unit) => ({ unit: unit.unit, ptid: unit.ptid }));
      unitsToSave.push({
        unit: unitSerial,
        ptid: inspectionResult.productId || -1,
      });

      // always save the unit
      await axios.post(`/api/stations/packing/box/${boxSerial}`, {
        payload: {
          userId: 1,
          units: unitsToSave,
          box: boxSerial,
        } as BoxSaveParams,
      });

      const { data: updatedUnits } = await axios.get<BoxRow[]>(
        `/api/stations/packing/box/${boxSerial}`
      );

      await this.setStateAsync({
        units: updatedUnits,
      });

      if (updatedUnits.length >= unitsPerBox) {
        this.boxFinished();
      }
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  removeUnitFromBox = async (unit: BoxRow) => {
    const { units } = this.state;

    const updatedUnits = units.filter((u) => u !== unit);

    await this.saveBox(updatedUnits);
  };

  saveBox = async (updatedUnits: readonly BoxRow[]) => {
    const { boxSerial } = this.state;
    try {
      await axios.post(`/api/stations/packing/box/${boxSerial}`, {
        payload: {
          userId: 1,
          units: updatedUnits.map((unit) => ({ unit: unit.unit, ptid: unit.ptid })),
          box: boxSerial,
        } as BoxSaveParams,
      });
      this.setState({ units: updatedUnits });
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  boxFinished = async () => {
    const { units, autoPrintBoxLabels, productConfig } = this.state;

    const { unitsPerBox } = productConfig as ProductConfig;

    if (units.length < unitsPerBox) {
      if (!window.confirm("Box not full, are you sure?")) {
        return;
      }
    }

    try {
      if (autoPrintBoxLabels) {
        await this.printBoxContentsLabel();
      }

      this.newBox();
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  printBoxContentsLabel = async () => {
    const { boxSerial, units } = this.state;

    await this.printContentsLabel({
      packageId: boxSerial,
      contentSerialNumbers: units.map((unit) => unit.unit),
    });
  };

  printContentsLabel = async ({
    packageId,
    contentSerialNumbers,
  }: {
    packageId: string;
    contentSerialNumbers: readonly string[];
  }) => {
    const { productConfig } = this.state;

    if (!productConfig) throw new Error("missing productConfig");

    const labelParams: any = {
      PKG_SERIAL: packageId,
    };

    const labelFieldNames = [
      "T1U1",
      "T1U2",
      "T2U1",
      "T2U2",
      "T3U1",
      "T3U2",
      "T4U1",
      "T4U2",
      "T5U1",
      "T5U2",
      "T6U1",
      "T6U2",
      "T7U1",
      "T7U2",
      "T8U1",
      "T8U2",
      "T9U1",
      "T9U2",
      "T10U1",
      "T10U2",
    ];

    labelFieldNames.forEach((fieldName, index) => {
      const value = contentSerialNumbers[index] || null;
      labelParams[fieldName] = value;
    });

    // TODO: label needs to have field added for "Box ID" before this will work
    labelParams["PKG_SERIAL"] = packageId;
    labelParams["PACKAGE_WEIGHT"] =
      calculateBoxWeight({
        productConfig,
        quantity: contentSerialNumbers.length,
      }).toFixed(2) + " kg";

    console.log("label parameters: ", labelParams);

    await this.printBartenderLabel({
      labelTemplate: "BoxingStation_BoxContents.btw",
      labelParams,
    });
  };

  printBartenderLabel = async ({
    labelTemplate,
    labelParams,
    path,
  }: {
    labelTemplate: string;
    labelParams: string;
    path?: string;
  }) => {
    const { printer } = this.state;

    if (!printer) {
      this.setState({ printerStatus: "Printer must be selected first." });
      return;
    }

    this.setState({ printerStatus: "Printing..." });
    try {
      // Note: we can't POST directly to BarTender API directly because of BarTender CORS restrictions
      const response = await axios.post<string>(`/api/bartender/print`, {
        payload: {
          printer,
          labelTemplate,
          labelParams,
          path,
        },
      });
      console.log("response", response);

      const success =
        response.data &&
        response.data.includes("BarTender successfully sent the print job to the spooler") &&
        response.data.includes("Finished sending print job to printer");

      if (success) {
        this.setState({ printerStatus: "Label printed." });

        // clear after 3 seconds
        window.setTimeout(() => {
          this.setState({ printerStatus: "" });
        }, 3000);
      } else if (response.data.includes("does not support the page size specified")) {
        // most common error:  make more use friendly
        this.setState({
          printerStatus:
            "Printer Error: Selected printer does not support the label/page size specified.",
        });
      } else {
        this.setState({ printerStatus: `Printer Error.  Message from Printer: ${response.data}` });
      }
    } catch (e) {
      console.error(e);
      // HTTP status error, our error detected in response JSON data.
      this.setState({
        printerStatus: `HTTP Error: ${e.response.status || "Network error"}`,
      });
    }
  };

  render() {
    const {
      loading,
      error,
      /* config stuff */
      printer,
      printerStatus,
      productConfigName,
      productConfig,
      productConfigs,
      /* box stuff */
      autoPrintBoxLabels,
      unitSerial,
      boxFeedback,
      boxSearch,
      boxSerial,
      units,
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (!productConfigs) {
      return <div>Loading&hellip;</div>;
    }

    return (
      <section>
        {error && <FatalError />}

        <div className="alert alert-info ">
          <div className="d-flex align-items-center">
            <label className="d-flex align-items-center">
              Product Config:
              <select
                required
                name="product"
                className="form-control mr-2 ml-2"
                style={{ width: 250 }}
                value={productConfigName}
                onChange={(e) => {
                  const name = e.target.value;
                  const productConfig = productConfigs.find((x) => x.productname === name);
                  this.setState({ productConfig, productConfigName: name });
                  localStorage.setItem("productConfigName", name);
                }}
              >
                <option value="">-- select-- </option>
                {productConfigs.map((item) => (
                  <option value={item.productname} key={item.productname}>
                    {item.productname} - {item.description}
                  </option>
                ))}
              </select>
            </label>

            <label className="d-flex align-items-center">
              Printer:
              <select
                name="printer"
                className="form-control mr-2 ml-2"
                style={{ width: 250 }}
                value={printer}
                onChange={(e) => {
                  localStorage.setItem("printer", e.target.value);
                  this.setState({ printer: e.target.value });
                }}
              >
                <option value="">-- select-- </option>
                {AVAILABLE_PRINTERS.map((printer) => (
                  <option value={printer} key={printer}>
                    {printer}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>

        <div className="alert alert-warning">Print Status: {printerStatus || "Idle"}</div>

        {!productConfigName ? (
          <div className="alert alert-warning">Please complete config section</div>
        ) : (
          <div className="d-flex">
            <div className="d-flex flex-column card border-secondary">
              <div className="card-header">Unit / Box Packing</div>
              <div className="card-body">
                <form className="mb-2 d-flex align-items-center" onSubmit={this.preventFormSubmit}>
                  <span className="indent">Search:</span>
                  <input
                    placeholder="enter box #"
                    autoComplete="off"
                    className="form-control-sm ml-3"
                    type="text"
                    name="boxSearch"
                    maxLength={30}
                    minLength={3}
                    value={boxSearch}
                    onChange={this.handleInputChange}
                    style={{ width: 250 }}
                  />{" "}
                  <button
                    className="btn mr-1 "
                    disabled={!boxSearch}
                    onClick={() => this.loadBox(boxSearch)}
                  >
                    Go
                  </button>
                  <button
                    className="btn mr-1 ml-1"
                    disabled={!boxSearch}
                    onClick={() => this.setState({ boxSearch: "" })}
                  >
                    Clear
                  </button>
                </form>
                <label className="mb-2 d-flex align-items-center">
                  <span className="indent">Box:</span>

                  <input
                    autoComplete="off"
                    className="form-control-sm ml-3"
                    readOnly={true}
                    type="text"
                    name="boxSerial"
                    disabled={true}
                    value={boxSerial}
                    onChange={this.handleInputChange}
                    style={{ width: 250 }}
                  />

                  <button className="btn btn-tertriary ml-1 mr-1" onClick={this.newBox}>
                    New
                  </button>
                </label>

                <form onSubmit={this.preventFormSubmit}>
                  <label className="mb-3 indent">Scan Unit:</label>
                  <input
                    ref={this.scanUnitInputRef}
                    autoComplete="off"
                    className="form-control-sm ml-3"
                    autoFocus
                    type="text"
                    name="unitSerial"
                    maxLength={30}
                    minLength={1}
                    value={unitSerial}
                    onChange={this.handleInputChange}
                    style={{ width: 285 }}
                    disabled={!boxSerial}
                  />{" "}
                  <button
                    className="btn "
                    onClick={this.addUnitToBox}
                    disabled={!unitSerial || !boxSerial}
                  >
                    Add to Box
                  </button>
                </form>

                {boxFeedback && <div className="alert alert-error mt-1">{boxFeedback} </div>}

                <div className="d-flex" style={{ paddingLeft: 0 }}>
                  <span className="indent d-flex flex-column">Contents:</span>

                  <div
                    className="d-flex flex-column pl-2 pr-2 pt-2 pb-2"
                    style={{
                      border: "2px solid lightgrey",
                      height: 300,
                      width: 400,
                      marginLeft: 15,
                      overflowY: "scroll",
                    }}
                  >
                    {productConfig && (
                      <ContentGrid className=" mb-1">
                        {units
                          .slice()
                          .reverse()
                          .map((unit, index) => [
                            <div key={`c1${unit.unit}`} style={{ font: "Deja Vu Sans Mono" }}>
                              {unit.unit}
                            </div>,

                            <span key={`c2${unit.unit}`}>
                              Tray #
                              {Math.floor(
                                (units.length - index - 1) / productConfig.unitsPerTray + 1
                              )}
                            </span>,

                            <button
                              key={`c3${unit.unit}`}
                              className="btn btn-sm btn-tertriary mr-1"
                              disabled={index === units.length - 1}
                              onClick={async () => {
                                const mod = [...units];
                                const thisIndex = mod.length - 1 - index;
                                const tmp = mod[thisIndex];
                                mod[thisIndex] = mod[thisIndex - 1];
                                mod[thisIndex - 1] = tmp;

                                console.log("MOD", mod);

                                await this.saveBox(mod);
                              }}
                            >
                              ↓
                            </button>,

                            <button
                              key={`c4${unit.unit}`}
                              className="btn btn-sm btn-tertriary mr-1"
                              disabled={index === 0}
                              onClick={async () => {
                                const mod = [...units];
                                const tmp = mod[mod.length - index];
                                mod[mod.length - index] = mod[mod.length - index - 1];
                                mod[mod.length - index - 1] = tmp;

                                console.log("MOD", mod);

                                await this.saveBox(mod);
                              }}
                            >
                              ↑
                            </button>,

                            <button
                              key={`c5${unit.unit}`}
                              className="btn btn-sm btn-tertriary"
                              onClick={() => {
                                this.removeUnitFromBox(unit);
                              }}
                              data-unit={unit}
                            >
                              ✖
                            </button>,
                          ])}
                      </ContentGrid>
                    )}
                  </div>
                </div>

                <br />

                <div className="mt-3 d-flex align-items-center">
                  <button
                    className="btn btn-tertriary mr-2"
                    onClick={this.printBoxContentsLabel}
                    disabled={!boxSerial || !units.length}
                  >
                    Print Box Contents Label
                  </button>
                </div>
                <div>
                  <label>
                    Auto Print after Box packed
                    <input
                      className="m-2"
                      type="checkbox"
                      name="autoPrintBoxLabels"
                      checked={autoPrintBoxLabels}
                      onChange={() => this.setState({ autoPrintBoxLabels: !autoPrintBoxLabels })}
                    />
                  </label>
                </div>
              </div>
            </div>
            {productConfig && (
              <div className="m-3">
                <h5>Current Box:</h5>
                <LabelGrid2>
                  <span>Units:</span>
                  <span>
                    {units.length}
                    {" / "}
                    {productConfig.unitsPerBox}
                  </span>

                  <span>Packed weight:</span>
                  <span>
                    {calculateBoxWeight({ productConfig, quantity: units.length }).toFixed(2)}
                    {" / "}
                    {calculateBoxWeight({
                      productConfig,
                      quantity: productConfig.unitsPerBox,
                    }).toFixed(2)}
                  </span>
                </LabelGrid2>

                <br />
                <br />
                <h5>Product Config Details:</h5>
                <LabelGrid2>
                  <span>Unit Weight:</span>
                  <span>{productConfig.unit_weight}</span>

                  <span>Tray Weight:</span>
                  <span>{productConfig.tray_weight}</span>

                  <span>Box Weight:</span>
                  <span>{productConfig.box_weight}</span>

                  <span>Pallet Weight:</span>
                  <span>{productConfig.pallet_weight}</span>

                  <span>Units per Box:</span>
                  <span>{productConfig.unitsPerBox}</span>

                  <span>Units per Tray:</span>
                  <span>{productConfig.unitsPerTray}</span>

                  <span>Boxes per Pallet:</span>
                  <span>{productConfig.boxesPerPallet}</span>
                </LabelGrid2>
              </div>
            )}
          </div>
        )}

        <div className="alert alert-tertiary mt-3 ">
          <a
            className="btn btn-secondary"
            href="http://sigma/BarTender/PrintPortal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Print Override
          </a>
          <br />
        </div>
      </section>
    );
  }
}
