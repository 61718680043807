import axios from "axios";
import { PrintStatus, PrintParams } from "../../api/bartender";
import { DEBUG_PRINTER } from "../station/packingStation/common";

export const printBartenderLabel = async ({
  printer,
  labelTemplate,
  labelParams,
  path,
  copies,
}: PrintParams): Promise<PrintStatus> => {
  if (printer === DEBUG_PRINTER) {
    const printDebugMsg = `
      PATH: ${labelTemplate} \n
      PARAMS: \n
      ${JSON.stringify(labelParams, null, 2)} \n
    `;
    alert(printDebugMsg);
    console.log("PRINTED:\n" + printDebugMsg);
    return {
      status: "ok",
    };
  }

  try {
    const response = await axios.post(`/api/bartender/print`, {
      payload: {
        printer,
        labelTemplate,
        labelParams,
        path,
        copies,
      },
    });
    console.log("response", response);

    const success =
      response.data &&
      response.data.includes("BarTender successfully sent the print job to the spooler") &&
      response.data.includes("Finished sending print job to printer");

    if (success) {
      return { status: "ok" };
    } else if (response.data.includes("does not support the page size specified")) {
      // most common error:  make more use friendly
      return {
        status: "printerError",
        msg: "Printer Error: Selected printer does not support the label/page size specified.",
      };
    } else {
      return {
        status: "printerError",
        msg: `Printer Error.  Message from Printer: ${response.data}`,
      };
    }
  } catch (e) {
    console.error(e);
    return {
      status: "httpError",
      msg: `HTTP Error.  Status code: ${e.response.status}`,
    };
  }
};
