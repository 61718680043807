import React from "react";
import { AppContextPropTypes } from "../../../../../../propTypes";
import { LeakLocationTypesLookup, ProductTypeApiEntity } from "../../../../../../ts/types";
import "./ProductLeakLocation.scss";

export default class LeakLocationButton extends React.Component<{
  leakLocationTypes: LeakLocationTypesLookup;
  product: ProductTypeApiEntity;
  productLeakLocationIndex: number;
  onClick?: (productLeakLocationIndex: number) => void;
}> {
  static contextTypes = AppContextPropTypes;

  handleClick = () => {
    this.props.onClick && this.props.onClick(this.props.productLeakLocationIndex);
  };

  handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      this.handleClick();
    }
  };

  render() {
    const { onClick, productLeakLocationIndex } = this.props;
    const { locale } = this.context;

    const styleProps: { cursor?: string } = {};

    if (onClick) {
      styleProps.cursor = "pointer";
    }

    // 'no leak' is not defined in location_lookup database table, so we hardcode it for now
    const locationTypeNoLeak = {
      index: 0,
      location_name: "No Leak",
      alt_name: "没有泄漏",
    };

    let leakLocationType = null;
    if (productLeakLocationIndex === 0) {
      leakLocationType = locationTypeNoLeak;
    } else {
      const productLeakLocation = this.props.product.metadata.leakLocations.find(
        (l) => l.index === productLeakLocationIndex
      );

      if (!productLeakLocation) {
        return <div />;
      }

      leakLocationType = this.props.leakLocationTypes[productLeakLocation.locationId];
    }

    if (!leakLocationType) {
      return <div>Invalid Leak Location</div>;
    }

    const color = productLeakLocationIndex === 0 ? "#00ff00" : "red";

    return (
      <button
        className={`ProductLeakLocation ${onClick && "ProductLeakLocation--EnableHover"}`}
        tabIndex={onClick ? 0 : undefined}
        onClick={this.handleClick}
        onKeyPress={this.handleKeyPress}
        style={{
          ...styleProps,
          border: `2px solid ${color}`,
          color: `${color}`,
          minWidth: "240px",
          marginRight: "5px",
        }}
      >
        <div className="ProductLeakLocation__Index" style={{ border: `2px solid ${color}` }}>
          {productLeakLocationIndex}
        </div>
        <div className="ProductLeakLocation__Label">
          {locale === "en" && <span>{leakLocationType.location_name}</span>}
          {locale === "cn" && <span>{leakLocationType.alt_name}</span>}
          {locale === "zh" && <span>{leakLocationType.alt_name}</span>}
          {locale === "cn-en" && (
            <span>
              {leakLocationType.alt_name}{" "}
              {leakLocationType.alt_name
                ? `(${leakLocationType.location_name})`
                : leakLocationType.location_name}
            </span>
          )}
        </div>
      </button>
    );
  }
}
