import axios from "axios";
import PropTypes from "prop-types";
import React from "react";
import { DRY_WEIGHT_STATION_ID } from "../../../../../constants";
import {
  AppContextPropTypes,
  MetricTypePropTypes,
  WorkflowComponentPropTypes,
} from "../../../../../propTypes";
import { FormSubmitData, WorkflowComponentProps } from "../../../../../ts/types";
import MetricsForm from "./MetricsForm";
import ShowMetricsSummary from "./ShowMetricsSummary";

export default class WetWeightMetrics extends React.Component<
  WorkflowComponentProps & {
    metricTypes: any;
  }
> {
  static propTypes = {
    ...WorkflowComponentPropTypes,
    metricTypes: PropTypes.arrayOf(MetricTypePropTypes).isRequired,
  };

  static contextTypes = AppContextPropTypes;

  evaluateWetWeightMetrics = async ({ metrics }: { metrics: FormSubmitData }) => {
    // Wet Weight depends on previous output from the Dry Weight station

    const { props } = this;

    const { product, serialNumber } = props.workflowState;

    const response = await axios.get(
      `/api/serialNumbers/${serialNumber}/stationData/${DRY_WEIGHT_STATION_ID}/latest`
    );

    const latestDryWeightMetrics = response.data.metrics;

    const { wetWeight } = metrics;
    const { dryWeight } = latestDryWeightMetrics;

    if (typeof dryWeight !== "number" || typeof wetWeight !== "number") {
      throw new Error("expected number");
    }

    const { minWeightDelta, maxWeightDelta } = product.metadata;

    if (minWeightDelta === null) {
      console.warn("product.min2 (minWeightDelta) is null");
    }
    if (maxWeightDelta === null) {
      console.warn("product.min2 (maxWeightDelta) is null");
    }

    const weightDelta = wetWeight - dryWeight;

    let pass = true;
    if (minWeightDelta !== null && maxWeightDelta !== null) {
      pass = weightDelta >= minWeightDelta && weightDelta <= maxWeightDelta;
    }

    console.table({
      minWeightDelta,
      maxWeightDelta,
      wetWeight,
      dryWeight,
      weightDelta,
      pass,
    });

    return { pass: pass ? 1 : 0, weightDelta };
  };

  render() {
    const { props } = this;
    const { workflowState } = props;

    const visible = workflowState.serialNumber && workflowState.product;

    if (!visible) {
      return null;
    }

    const { metrics, pass } = workflowState;

    // the the results if metrics already entered
    if (metrics) {
      return (
        <div>
          <ShowMetricsSummary metricTypes={props.metricTypes} metrics={metrics} pass={pass} />
        </div>
      );
    }

    // show the form
    return (
      <div>
        <MetricsForm
          {...props}
          metricTypes={props.metricTypes}
          evaluateMetrics={this.evaluateWetWeightMetrics}
        />
      </div>
    );
  }
}
