import axios from "axios";
import React, { useState } from "react";
import { UserRow } from "../../ts/types";
import { Translate } from "../common";
import { useAppDispatch } from "../contexts/AppContext";
import logo from "./logo.png";

interface Props {
  onSignIn(user: UserRow): void;
}

export const SignInForm: React.FC<Props> = ({ onSignIn }) => {
  const appDispatch = useAppDispatch();
  const [state, setState] = useState({
    error: false,

    userBarcode: "",

    notFound: false,
  });

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setState({ ...state, error: false, notFound: false, userBarcode: "" });

    try {
      const response = await axios.get<UserRow>(`/api/users/${state.userBarcode}`);
      const user = response.data;

      if (user) {
        onSignIn(user);

        appDispatch({ type: "signIn", user });
      } else {
        setState({ ...state, notFound: true });
      }
    } catch (e) {
      setState({ ...state, error: true });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <form
        onSubmit={handleFormSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "2px solid lightgrey",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <img src={logo} style={{ width: "50%" }} alt="cool it systems logo" />
        <h2>Quality Process Tracker - V3</h2>
        <h2>
          <Translate msg="signIn" />{" "}
        </h2>
        <p>
          <Translate msg="signInPrompt" />
        </p>
        <div>
          <input
            type="text"
            className="text-security mr-2"
            autoFocus={true}
            autoComplete="off"
            name="userBarcode"
            onChange={(e) => {
              setState({ ...state, userBarcode: e.target.value });
            }}
            value={state.userBarcode}
            required
          />
          <button type="submit" className="btn btn-primary">
            <Translate msg="signIn" />
          </button>
        </div>

        {state.notFound && <div className="text-danger">找不到用户 (User not found ) </div>}
      </form>
    </div>
  );
};
