import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { CalgaryLotCode, OrderLineItem } from "../../../ts/types";
import { Loading } from "../../common";
import { printBartenderLabel } from "../../printing/Printing";
import { AlignedLabels } from "../packingStation/Layout";
import { PrintedItemsContext } from "./PrintedItems";

type InventoryLabelParams = {
  partNumber: string;
  revision: string;
  description: string;
  lotCodeWithoutPrefix?: string; // Note:  because the label has "LTC" prefix already, and barcode should scan only the number
};

const StyledButton = styled.button`
  color: black;
  border: 1px solid black;
  margin: 5px;
  padding: 10px 15px;
`;

export const PrintReceivedItem = (item: OrderLineItem) => {
  const [quantity, setQuantity] = React.useState(1);
  const [, setLotCodes] = React.useState<CalgaryLotCode[]>([]);
  const [mostRecentLotCode, setMostRecentLotCode] = React.useState<string | null>(null);

  const [userFeedback, setUserFeedback] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [, dispatchPrintedItems] = useContext(PrintedItemsContext);

  const lookupLotCode = async () => {
    const { data: lotCodes } = await axios.get<CalgaryLotCode[]>(
      `api/lotNumbers/calgary/${item.poNumber}/${item.id}`
    );

    console.log("lotCodes", lotCodes);
    setLotCodes(lotCodes);
    setMostRecentLotCode(lotCodes[0] && lotCodes[0].lotnumber);
    setLoading(false);
  };

  useEffect(() => {
    lookupLotCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id]);

  const generateLotCode = async (): Promise<string> => {
    const {
      data: [lotNumber],
    } = await axios.post("/api/lotNumbers/calgary", {
      payload: {
        userId: 85,
        quantity: 1,
        transid: item.poNumber,
        netsuiteTransactionLineId: item.id,
        partNumber: item.partNumber,
        poNumber: item.poNumber,
      },
    });

    return lotNumber;
  };

  const now = moment(); // TODO: same tz as server
  const todaysLotCodePrefix = "LTC" + now.format("YY") + now.format("DDDD").padStart(3, "0"); // e.g. "99001" (jan 1 1999)

  const isLotCodeFromToday: boolean =
    !!mostRecentLotCode && mostRecentLotCode.startsWith(todaysLotCodePrefix);

  const printLabel = async (labelColor: "yellow" | "white") => {
    if (!(quantity >= 1)) {
      setUserFeedback("Must be at least 1");
      return;
    }

    setUserFeedback("");

    // use the printer set in the receiving station preferences.
    const {
      data: { whiteLabelPrinter, yellowLabelPrinter },
    } = await axios.get("/api/siteConfig");

    const printer = labelColor === "yellow" ? yellowLabelPrinter : whiteLabelPrinter;

    const labelParams: InventoryLabelParams = {
      partNumber: item.partNumber,
      revision: item.revision,
      description: item.description,
    };

    if (item.lotCoded) {
      if (item.nonlot === 1) {
        labelParams.lotCodeWithoutPrefix = "NONLOT";
      } else if (mostRecentLotCode === null || !isLotCodeFromToday) {
        const newLotCode = await generateLotCode();

        labelParams.lotCodeWithoutPrefix = newLotCode.slice(3);

        setMostRecentLotCode(newLotCode);
      } else {
        labelParams.lotCodeWithoutPrefix = mostRecentLotCode.slice(3);
      }
    }

    dispatchPrintedItems &&
      dispatchPrintedItems({ type: "add", payload: `${item.poNumber}/${item.id}` });

    for (let copy = 1; copy <= quantity; copy++) {
      const printStatus = await printBartenderLabel({
        printer,
        labelTemplate: item.lotCoded
          ? "Inventory Label - Lot Coded.btw"
          : "Inventory Label - Non-Lot Coded.btw",
        labelParams,
        copies: 1,
      });

      if (printStatus.status === "ok") {
        setUserFeedback(`Printed (${copy} of ${quantity})`);
      } else {
        setUserFeedback(`Print Error (${copy} of ${quantity}): ${printStatus.msg}`);
      }
    }
  };

  if (loading) {
    return <Loading />;
  }

  const renderLotCode = () => {
    if (!item.lotCoded) {
      return <i>non lotcoded item</i>;
    }

    if (item.nonlot === 1) {
      return <i>NONLOT</i>;
    }

    return (
      <div>
        {mostRecentLotCode ? (
          <span>
            {mostRecentLotCode}
            {"  "}
            {isLotCodeFromToday ? (
              <i>
                <strong>Note:</strong> lot code is from today: existing lotcode will be reused
              </i>
            ) : (
              <i>
                <strong>Note:</strong> lot code date is before today: a new lotcode will be
                generated when printing
              </i>
            )}
          </span>
        ) : (
          <i>press Print to generate</i>
        )}
      </div>
    );
  };

  return (
    <section>
      <h3>Print Label(s)</h3>

      <AlignedLabels>
        <strong>Purchase Order:</strong>
        <div>{item.poNumber}</div>

        <strong>Part Number:</strong>
        <div>{item.partNumber}</div>

        <strong>Description:</strong>
        <div>{item.description}</div>

        <strong>Lot Code:</strong>
        <div>{renderLotCode()}</div>

        <strong># of Labels:</strong>
        <input
          type="number"
          className="form-control"
          value={quantity}
          style={{ width: 75 }}
          max={999}
          min={1}
          required
          autoFocus
          onChange={(e) => setQuantity(parseInt(e.target.value))}
        />
      </AlignedLabels>
      <br />
      <StyledButton
        className="btn btn-tertiary"
        style={{ backgroundColor: "white" }}
        onClick={() => printLabel("white")}
      >
        Print White Label{quantity > 1 && "s"}
      </StyledButton>
      <br />
      <StyledButton
        className="btn btn-tertiary"
        style={{ backgroundColor: "yellow" }}
        onClick={() => printLabel("yellow")}
      >
        Print Yellow Label{quantity > 1 && "s"}
      </StyledButton>
      <div>{userFeedback}</div>
    </section>
  );
};
