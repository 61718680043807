import React from "react";
import { AppContextPropTypes, WorkflowComponentPropTypes } from "../../../../../propTypes";
import { WorkflowComponentProps } from "../../../../../ts/types";
import { Translate } from "../../../../common";

export default class WetWeightHints extends React.Component<WorkflowComponentProps> {
  static propTypes = {
    ...WorkflowComponentPropTypes,
  };

  static contextTypes = AppContextPropTypes;

  render() {
    const { props } = this;
    const { workflowState } = props;

    const visible = workflowState.metrics;

    if (!visible) {
      return null;
    }

    const { product, metrics } = workflowState;

    // the the results if metrics already entered
    return (
      <div className="">
        <h5>
          <Translate msg="details" />
        </h5>
        <p>
          <strong>
            <Translate msg="metrics.minWeightDelta" />:
          </strong>{" "}
          {product.metadata.minWeightDelta || "N/A"}
        </p>
        <p>
          <strong>
            <Translate msg="metrics.maxWeightDelta" />:
          </strong>{" "}
          {product.metadata.maxWeightDelta || "N/A"}
        </p>

        <div>
          <p>
            <strong>
              <Translate msg="metrics.dryWeight" />:
            </strong>{" "}
            {metrics.wetWeight - metrics.weightDelta}
          </p>

          <p>
            <strong>
              <Translate msg="metrics.weightDelta" />:
            </strong>{" "}
            {metrics.weightDelta}
          </p>
        </div>
      </div>
    );
  }
}
