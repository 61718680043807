import axios from "axios";
import React, { RefObject } from "react";
import styled from "styled-components";
import { AppContextPropTypes } from "../../../propTypes";
import { ProductConfig } from "../../../ts/types";
import { FatalError, Loading } from "../../common";
import { AVAILABLE_PRINTERS } from "./common";

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  gap: 5px;
`;

const TEST_RACK_CAPACITY = 20;

type State = {
  loading: boolean;
  error: string | null;
  validation: boolean;

  printer: string;
  printerStatus: string | null;

  // Configuration
  // productConfigName: string;
  productConfig: ProductConfig | null;
  productConfigs: readonly ProductConfig[] | null;

  // Unit/Box stuff
  autoPrintBoxLabels: boolean;
  unitSerial: string;
  boxFeedback: string;
  boxSearch: string;
  boxSerial: string;
  // boxIsNew: boolean;

  units: LoadTestUnit[];

  availablePrinters: readonly string[];
};

type LoadTestUnit = { serial: null | string };

export default class LoadTestRackStation extends React.Component {
  static contextTypes = AppContextPropTypes;

  state: State = {
    loading: false,
    error: null,

    validation: localStorage.DEBUG ? false : true,

    printer: localStorage.getItem("printer") || "",
    printerStatus: null,

    availablePrinters: AVAILABLE_PRINTERS,

    // Configuration TDO REMVOE
    productConfig: null,
    productConfigs: null,

    // Unit/Box stuff
    autoPrintBoxLabels: true,
    unitSerial: "",
    boxFeedback: "",
    boxSearch: "",
    boxSerial: "",
    units: [],
  };

  scanUnitInputRef: RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);
    this.scanUnitInputRef = React.createRef();
  }

  componentDidMount() {
    this.context.setPageTitle("Load Test Rack Station");
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  preventFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    return false;
  };

  setStateAsync = (newState: any) =>
    new Promise<void>((resolve) =>
      this.setState(newState, () => {
        resolve();
      })
    );

  clearBoxForm = () => {
    this.setState({
      boxSerial: "",
      units: [],
      unitSerial: "",
      boxSearch: "",
      boxFeedback: "",
    });
  };

  setLoadTestRack = async () => {
    const { boxSearch } = this.state;

    await this.setStateAsync({ boxSerial: boxSearch, units: [] });

    this.scanUnitInputRef.current && this.scanUnitInputRef.current.focus();
  };

  addUnitToRack = async ({ validation, unitSerial }: { validation: any; unitSerial: string }) => {
    this.setState({ boxFeedback: "", unitSerial: "" });

    try {
      const { boxSerial, units } = this.state;

      const { data: inspectionResult } = await axios.get(
        `/api/serialNumbers/${unitSerial}/finalInspection`
      );

      console.log("final inspection result", unitSerial, inspectionResult);

      if (validation) {
        if (!inspectionResult.found) {
          this.setState({ boxFeedback: `Unit '${unitSerial}' was not found in records.` });
          return;
        }

        if (!inspectionResult.passedStations) {
          this.setState({
            boxFeedback: `Unit '${unitSerial}' has not passed all required stations.`,
          });
          return;
        }
      }
      if (unitSerial !== "SKIPPED" && units.find((u) => u.serial === unitSerial)) {
        this.setState({ boxFeedback: `Unit '${unitSerial}' already added to this rack.` });
        return;
      }
      if (units.length >= TEST_RACK_CAPACITY) {
        this.setState({ boxFeedback: `Rack is already full.` });
        return;
      }

      const updatedUnits = [...units, { serial: unitSerial }];

      // always save the unit
      await axios.post(`/api/stations/packing/box/${boxSerial}`, {
        payload: {
          userId: 1,
          units: updatedUnits.map((unit) => ({
            unit: unit.serial,
            ptid: null,
          })),
          box: boxSerial,
        },
      });

      await this.setStateAsync({
        units: updatedUnits,
      });

      if (updatedUnits.length >= TEST_RACK_CAPACITY) {
        this.rackFinished();
      }
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  removeUnitFromBox = async (unit: LoadTestUnit) => {
    const { units } = this.state;

    const updatedUnits = units.filter((u) => u !== unit);

    await this.saveBox(updatedUnits);
  };

  saveBox = async (updatedUnits: any) => {
    const { boxSerial } = this.state;
    try {
      await axios.post(`/api/stations/packing/box/${boxSerial}`, {
        payload: {
          userId: 1,
          units: updatedUnits.map((unit: any) => ({ unit: unit.unit, ptid: null })),
          box: boxSerial,
        },
      });
      this.setState({ units: updatedUnits });
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  rackFinished = async () => {
    const { units, autoPrintBoxLabels } = this.state;

    if (units.length < TEST_RACK_CAPACITY) {
      if (!window.confirm("Rack not full, are you sure?")) {
        return;
      }
    }

    try {
      if (autoPrintBoxLabels) {
        await this.printAndUploadRackData();
      }
    } catch (e) {
      console.error(e);
      this.setState({ error: true });
    }
  };

  printAndUploadRackData = async () => {
    const { boxSerial, units } = this.state;

    await this.uploadLoadTestRackFile({
      packageId: boxSerial,
      contentSerialNumbers: units.map((unit: any) => unit.serial),
    });

    await this.printContentsLabel({
      packageId: boxSerial,
      contentSerialNumbers: units.map((unit: any) => unit.serial),
    });
  };

  uploadLoadTestRackFile = async ({
    packageId,
    contentSerialNumbers,
  }: {
    packageId: string;
    contentSerialNumbers: string[];
  }) => {
    try {
      const response = await axios.post(`/api/stations/loadTestRack/upload`, {
        payload: {
          rackNumber: packageId,
          serialNumbers: contentSerialNumbers,
        },
      });
      console.debug("upload response", response);
    } catch (e) {
      console.error(e);
      this.setState({
        printerStatus: `File Upload HTTP Error: ${e.response.status || "Network error"}`,
      });
    }
  };

  printContentsLabel = async ({
    packageId,
    contentSerialNumbers,
  }: {
    packageId: string;
    contentSerialNumbers: string[];
  }) => {
    const labelParams: Record<string, string | number | null> = {
      PKG_SERIAL: packageId,
    };

    const labelFieldNames = [
      "T1U1",
      "T1U2",
      "T2U1",
      "T2U2",
      "T3U1",
      "T3U2",
      "T4U1",
      "T4U2",
      "T5U1",
      "T5U2",
      "T6U1",
      "T6U2",
      "T7U1",
      "T7U2",
      "T8U1",
      "T8U2",
      "T9U1",
      "T9U2",
      "T10U1",
      "T10U2",
    ] as const;

    labelFieldNames.forEach((fieldName, index) => {
      const value = contentSerialNumbers[index] || null;
      labelParams[fieldName] = value;
    });

    // TODO: label needs to have field added for "Box ID" before this will work
    labelParams["PKG_SERIAL"] = packageId;
    labelParams["PACKAGE_WEIGHT"] = 0;
    console.log("label parameters: ", labelParams);

    await this.printBartenderLabel({
      labelTemplate: "LoadTestStation_RackContents.btw",
      labelParams,
    });
  };

  printBartenderLabel = async ({ labelTemplate, labelParams, path }: any) => {
    const { printer } = this.state;

    if (!printer) {
      this.setState({ printerStatus: "Printer must be selected first." });
      return;
    }

    this.setState({ printerStatus: "Printing..." });
    try {
      const response = await axios.post(`/api/bartender/print`, {
        payload: {
          printer,
          labelTemplate,
          labelParams,
          path,
        },
      });
      console.log("response", response);

      const success =
        response.data &&
        response.data.includes("BarTender successfully sent the print job to the spooler") &&
        response.data.includes("Finished sending print job to printer");

      if (success) {
        this.setState({ printerStatus: "Label printed." });

        // clear after 3 seconds
        window.setTimeout(() => {
          this.setState({ printerStatus: "" });
        }, 3000);
      } else if (response.data.includes("does not support the page size specified")) {
        // most common error:  make more use friendly
        this.setState({
          printerStatus:
            "Printer Error: Selected printer does not support the label/page size specified.",
        });
      } else {
        this.setState({ printerStatus: `Printer Error.  Message from Printer: ${response.data}` });
      }
    } catch (e) {
      console.error(e);
      // HTTP status error, our error detected in response JSON data.
      this.setState({
        printerStatus: `HTTP Error: ${e.response.status || "Network error"}`,
      });
    }
  };

  render() {
    const {
      loading,
      error,
      /* config stuff */
      printer,
      printerStatus,
      availablePrinters,

      /* box stuff */
      autoPrintBoxLabels,
      unitSerial,
      boxFeedback,
      boxSearch,
      boxSerial,
      units,
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <section>
        {error && <FatalError />}

        <div className="alert alert-info ">
          <div className="d-flex align-items-center">
            <label className="d-flex align-items-center">
              Printer:
              <select
                name="printer"
                className="form-control mr-2 ml-2"
                style={{ width: 250 }}
                value={printer}
                onChange={(e) => {
                  localStorage.setItem("printer", e.target.value);
                  this.setState({ printer: e.target.value });
                }}
              >
                <option value="">-- select-- </option>
                {availablePrinters.map((printer) => (
                  <option value={printer} key={printer}>
                    {printer}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>

        <div className="alert alert-warning">Print Status: {printerStatus || "Idle"}</div>

        <div className="d-flex">
          <div className="d-flex flex-column card border-secondary">
            <div className="card-header">Load Test Rack</div>
            <div className="card-body">
              <form className="mb-2 d-flex align-items-center" onSubmit={this.preventFormSubmit}>
                <span className="indent">Rack:</span>
                <input
                  placeholder="scan rack label"
                  autoComplete="off"
                  className="form-control-sm ml-3"
                  type="text"
                  name="boxSearch"
                  maxLength={30}
                  minLength={1}
                  value={boxSearch}
                  onChange={this.handleInputChange}
                  style={{ width: 250 }}
                />{" "}
                <button
                  className="btn mr-1 "
                  disabled={!boxSearch}
                  onClick={() => this.setLoadTestRack()}
                >
                  Go
                </button>
                <button
                  className="btn mr-1 ml-1"
                  disabled={!boxSearch}
                  onClick={() => this.setState({ boxSearch: "" })}
                >
                  Clear
                </button>
              </form>
              <label className="mb-2 d-flex align-items-center">
                <span className="indent">Rack:</span>

                <input
                  autoComplete="off"
                  className="form-control-sm ml-3"
                  readOnly={true}
                  type="text"
                  name="boxSerial"
                  disabled={true}
                  value={boxSerial}
                  onChange={this.handleInputChange}
                  style={{ width: 250 }}
                />
              </label>

              <form onSubmit={this.preventFormSubmit}>
                <label className="mb-3 indent">Scan Unit:</label>
                <input
                  ref={this.scanUnitInputRef}
                  autoComplete="off"
                  className="form-control-sm ml-3"
                  autoFocus
                  type="text"
                  name="unitSerial"
                  maxLength={30}
                  minLength={1}
                  value={unitSerial}
                  onChange={this.handleInputChange}
                  style={{ width: 285 }}
                  disabled={!boxSerial}
                />{" "}
                <button
                  className="btn "
                  onClick={() =>
                    this.addUnitToRack({ validation: true, unitSerial: this.state.unitSerial })
                  }
                  disabled={!unitSerial || !boxSerial}
                >
                  Add to Rack
                </button>
                <button
                  className="btn "
                  onClick={() => this.addUnitToRack({ validation: false, unitSerial: "SKIPPED" })}
                  disabled={!boxSerial}
                >
                  Skip Shelf
                </button>
              </form>

              {boxFeedback && <div className="alert alert-error mt-1">{boxFeedback} </div>}

              <div className="d-flex" style={{ paddingLeft: 0 }}>
                <span className="indent d-flex flex-column">Units:</span>

                <div
                  className="d-flex flex-column pl-2 pr-2 pt-2 pb-2"
                  style={{
                    border: "2px solid lightgrey",
                    height: 300,
                    width: 400,
                    marginLeft: 15,
                    overflowY: "scroll",
                  }}
                >
                  <ContentGrid className=" mb-1">
                    {units
                      .slice()
                      .reverse()
                      .map((unit, index) => [
                        <div key={`c1${unit.serial}`} style={{ font: "Deja Vu Sans Mono" }}>
                          {unit.serial}
                        </div>,

                        <button
                          key={`c3${unit.serial}`}
                          className="btn btn-sm btn-tertriary mr-1"
                          disabled={index === units.length - 1}
                          onClick={async () => {
                            const mod = [...units];
                            const thisIndex = mod.length - 1 - index;
                            const tmp = mod[thisIndex];
                            mod[thisIndex] = mod[thisIndex - 1];
                            mod[thisIndex - 1] = tmp;

                            console.log("MOD", mod);

                            await this.saveBox(mod);
                          }}
                        >
                          ↓
                        </button>,

                        <button
                          key={`c4${unit.serial}`}
                          className="btn btn-sm btn-tertriary mr-1"
                          disabled={index === 0}
                          onClick={async () => {
                            const mod = [...units];
                            const tmp = mod[mod.length - index];
                            mod[mod.length - index] = mod[mod.length - index - 1];
                            mod[mod.length - index - 1] = tmp;

                            console.log("MOD", mod);

                            await this.saveBox(mod);
                          }}
                        >
                          ↑
                        </button>,

                        <button
                          key={`c5${unit.serial}`}
                          className="btn btn-sm btn-tertriary"
                          onClick={() => {
                            this.removeUnitFromBox(unit);
                          }}
                          data-unit={unit}
                        >
                          ✖
                        </button>,
                      ])}
                  </ContentGrid>
                </div>
              </div>

              <br />

              <div className="mt-3 d-flex align-items-center">
                <button
                  className="btn btn-tertriary mr-2"
                  onClick={this.printAndUploadRackData}
                  disabled={!boxSerial || !units.length}
                >
                  Print Rack Contents Label
                </button>
              </div>
              <div>
                <label>
                  Auto Print after Rack loaded
                  <input
                    className="m-2"
                    type="checkbox"
                    name="autoPrintBoxLabels"
                    checked={autoPrintBoxLabels}
                    onChange={() => this.setState({ autoPrintBoxLabels: !autoPrintBoxLabels })}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
