import axios from "axios";
import React from "react";
import { AppContextPropTypes, WorkflowComponentPropTypes } from "../../../../propTypes";
import { WorkflowComponentProps } from "../../../../ts/types";
import { Translate } from "../../../common";
import Form from "../common/form/Form";

export default class SetProductByBarcode extends React.Component<WorkflowComponentProps> {
  static propTypes = WorkflowComponentPropTypes;

  static contextTypes = AppContextPropTypes;

  state = {
    productNotFound: false,
  };

  handleSubmit = async (formData: any) => {
    const { props } = this;

    this.setState({ productNotFound: false });

    const { productBarcode } = formData;

    props.workflowApi.resetState();

    const response = await axios.get(`/api/products/${productBarcode}`);

    const product = response.data || null;

    if (!product) {
      this.setState({ productNotFound: true });
    } else {
      props.workflowApi.updateState({ product });
    }
  };

  render() {
    const { props, state } = this;
    const { productNotFound } = state;
    const { workflowState } = props;

    const shouldRender = !workflowState.product;

    if (!shouldRender) {
      return null;
    }

    return (
      <div>
        <Form
          inputs={[
            {
              i18nLabel: "productBarcode",
              type: "text",
              name: "productBarcode",
              required: true,
              pattern: "[A-Za-z0-9]{3,30}",
            },
          ]}
          trimInputs={true}
          onSubmit={this.handleSubmit}
        />
        {productNotFound && (
          <div className="alert alert-warning">
            <Translate msg="productNotFound" />
          </div>
        )}
      </div>
    );
  }
}
