import axios from "axios";
import React from "react";
import { RouteComponentProps } from "react-router";
import { AppContextPropTypes } from "../../propTypes";
import { StationConfig, StationRow } from "../../ts/types";
import { FatalError } from "../common";
import GenericWorkflow from "./workflows/GenericWorkflow";

// User configurable assembly line station
export default class Station extends React.Component<RouteComponentProps<{ stationId: string }>> {
  static contextTypes = AppContextPropTypes;

  state: {
    station: null | StationRow;
    error: boolean;
  } = {
    station: null,
    error: false,
  };

  loadStation = async (stationId: string) => {
    try {
      const response = await axios.get(`/api/stations/${stationId}`);

      const station = response.data;
      this.setState({ station });
      this.context.setPageTitle(this.context.i18n(`stations.${stationId}`));
    } catch (e) {
      this.setState({ error: true });
    }
  };

  componentDidMount() {
    this.context.setPageTitle("Final Inspection");
    this.loadStation(this.props.match.params.stationId);
  }

  componentWillUnmount() {
    this.context.setPageTitle("");
  }

  render() {
    const { state } = this;
    const { station } = state;

    if (!station) {
      return <div>Loading&hellip;</div>;
    }

    const component = (station.config as StationConfig).component;

    if (component.type !== "GenericWorkflow") {
      throw new Error("only GenericWorkflow type is supported");
    }

    const componentProps = (component.props || {}) as any;

    return (
      <section>
        <GenericWorkflow station={station} {...componentProps} />

        {state.error && <FatalError />}
      </section>
    );
  }
}
