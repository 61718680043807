import styled from "styled-components";

export const Grid4 = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 5px;
  align-items: center;
`;

export const AlignedLabels = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  gap: 10px;

  > *:nth-child(odd) {
    text-align: right;
  }

  align-items: center;
`;

export const LabelGrid2 = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;

  > *:nth-child(odd) {
    text-align: right;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexRowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
