import PropTypes from "prop-types";

export const ProductPropTypes = PropTypes.shape({
  ptid: PropTypes.number.isRequired,
  productname: PropTypes.string.isRequired,
  metadata: PropTypes.any,
});

export const UserPropTypes = PropTypes.shape({
  uid: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  barcode: PropTypes.number.isRequired,
  permissions: PropTypes.number.isRequired,
});

export const MetricPropTypes = PropTypes.shape({
  leakLocation: PropTypes.number,
  dryWeight: PropTypes.number,
  wetWeight: PropTypes.number,
});

export const MetricTypePropTypes = PropTypes.shape({
  i18nLabel: PropTypes.string.isRequired,
  i18nSuffix: PropTypes.string,
  labelPrefix: PropTypes.string,

  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,

  // DOM
  autoFocus: PropTypes.bool,

  // optional for type='number'
  min: PropTypes.number,
  max: PropTypes.number,

  required: PropTypes.bool,

  widthPx: PropTypes.number,
});

export const StationPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
});

export const AppContextPropTypes = {
  i18n: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  user: UserPropTypes,
  setPageTitle: PropTypes.func.isRequired,
};

export const WorkflowPropsPropTypes = PropTypes.shape({
  station: PropTypes.object.isRequired,
}).isRequired;

export const WorkflowApiPropTypes = PropTypes.shape({
  resetState: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
}).isRequired;

export const WorkflowStatePropTypes = PropTypes.shape({
  product: PropTypes.object,
  serialNumber: PropTypes.string,
  metrics: PropTypes.object,
  pass: PropTypes.number,
}).isRequired;

export const WorkflowComponentPropTypes = {
  workflowProps: WorkflowPropsPropTypes,
  workflowState: WorkflowStatePropTypes,
  workflowApi: WorkflowApiPropTypes,
};
