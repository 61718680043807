import axios from "axios";
import React, { FC, Fragment, useEffect, useState } from "react";
import {
  FAILURE_REASON_ENTRY_ID,
  LEAK_TEST_STATION_ID,
  REPAIR_STATION_ID,
} from "../../../../../constants";
import {
  FailureReasonEntryType,
  LeakTestMetricsType,
  RepairStationMetricsType,
  SerialNumberMetricRow,
  WorkflowComponentProps,
} from "../../../../../ts/types";
import { FatalError, Translate } from "../../../../common";
import { useAppState } from "../../../../contexts/AppContext";

const initialState = {
  activity: "",
  error: false,
  loading: false,
  leakMetrics: null,
  failureReason: null,
};

type State = {
  loading: boolean;
  activity: string;
  error: boolean;
  leakMetrics: SerialNumberMetricRow<LeakTestMetricsType> | null;
  failureReason: SerialNumberMetricRow<FailureReasonEntryType> | null;
};

const leakRepairCodes = [
  "Clean",
  "Replace O-ring",
  "Replace Tube",
  "Replace Component",
  "Replace T-Fitting",
  "Replace Plug",
  "Swap Good Part",
];

export const RepairStationMetrics: FC<WorkflowComponentProps> = (props) => {
  const [state, setState] = useState<State>(initialState);
  const appState = useAppState();

  type LeakReason = {
    leakLocation: number;
    repairCode: string;
    activity: string;
  };
  const [leakReasons, setLeakReasons] = useState<LeakReason[]>([]);

  const fetchFailureReason = async () => {
    const { workflowState } = props;
    const newState = { ...state };

    try {
      const { data: failureReason } = await axios.get<SerialNumberMetricRow<
        FailureReasonEntryType
      > | null>(
        `/api/serialNumbers/${workflowState.serialNumber}/stationData/${FAILURE_REASON_ENTRY_ID}/latest`
      );

      if (failureReason) {
        newState.failureReason = failureReason;

        if (failureReason.metrics.reason === "Leak") {
          const { data: leakTest } = await axios.get<SerialNumberMetricRow<
            LeakTestMetricsType
          > | null>(
            `/api/serialNumbers/${workflowState.serialNumber}/stationData/${LEAK_TEST_STATION_ID}/latest`
          );
          newState.leakMetrics = leakTest;

          let newLeakReasons: LeakReason[] = [];

          if (leakTest) {
            newLeakReasons = [
              leakTest.metrics.leakLocation,
              ...(leakTest.metrics.otherLeaks || []),
            ].map((leakLocation) => ({
              leakLocation,
              repairCode: "",
              activity: "",
            }));
          }

          // pre-populate the last repairs
          const { data: lastRepair } = await axios.get<SerialNumberMetricRow<
            RepairStationMetricsType
          > | null>(
            `/api/serialNumbers/${workflowState.serialNumber}/stationData/${REPAIR_STATION_ID}/latest`
          );
          const lastLeakReasons = lastRepair?.metrics.leakReasons;
          if (lastLeakReasons) {
            lastLeakReasons.forEach((lastReason) => {
              const match = newLeakReasons.find((r) => r.leakLocation === lastReason.leakLocation);
              if (match) {
                match.activity = lastReason.activity;
                match.repairCode = lastReason.repairCode;
              }
            });
          }

          setLeakReasons(newLeakReasons);
        }
      } else {
        newState.failureReason = null;
      }
    } catch (e) {
      console.error(e);
      newState.error = true;
    } finally {
      setState(newState);
    }
  };

  const save = async (e: React.FormEvent) => {
    e.preventDefault();
    const { failureReason } = state;
    if (!failureReason) return;

    let reason = failureReason.metrics.reason;
    if (reason === "Other") {
      reason = failureReason.metrics.reasonOther || "Unknown";
    }

    const metrics: RepairStationMetricsType = {
      reason,
      activity: state.activity,
      leakReasons: leakReasons ?? [],
    };

    const pass = 1; // repair station always is a 'pass'

    try {
      const response = await axios.post<{ status: string }>(
        `/api/serialNumbers/${props.workflowState.serialNumber}/metrics`,
        {
          payload: {
            stationId: props.workflowProps.station.id,
            userId: appState.user?.uid,
            productId: props.workflowState.product.ptid,
            pass,
            metrics,
          },
        }
      );

      const result = response.data;

      if (result.status !== "success") {
        throw new Error("success expected");
      }

      props.workflowApi.updateState({ metrics, pass });

      setState(initialState);
      setLeakReasons([]);
    } catch (e) {
      setState({ ...state, error: true });
      console.error(e);
    } finally {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    if (props.workflowState.serialNumber) {
      fetchFailureReason();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workflowState.serialNumber]);

  const { workflowState } = props;

  const visible = workflowState.product && workflowState.serialNumber;

  if (!visible) {
    return null;
  }

  // if metrics already entered, show the summary
  if (workflowState.metrics) {
    return (
      <div>
        <p>
          <strong>Failure Reason: </strong> {workflowState.metrics.reason}
        </p>
        <p>
          <strong>Repair Activity: </strong>

          {leakReasons?.length ? "Leak Repairs" : workflowState.metrics.activity}
        </p>
      </div>
    );
  }

  // this station depends on having the reason previously entered at the "Failure Reason Entry" station
  if (!state.failureReason) {
    return (
      <div className="alert alert-danger">
        Error: "Failure Reason Entry" station must be completed first.
      </div>
    );
  }

  const { reason, reasonOther } = state.failureReason.metrics;

  // otherwise, show the form
  return (
    <div className="">
      <form className="form" onSubmit={save}>
        <div className="form-group mb-2">
          <label className="mr-1">
            <strong>
              <Translate msg="metrics.failureReason" />:{" "}
            </strong>
            <span>
              {reason}
              {reasonOther && ` - ${reasonOther}`}
            </span>
          </label>
        </div>

        {reason !== "Leak" && (
          <div className="form-group mb-2">
            <label className="mr-1">
              <strong>
                <Translate msg="repairActivity" />:
              </strong>
            </label>
            <br />
            <textarea
              required
              name="activity"
              cols={60}
              rows={5}
              onChange={(e) => setState({ ...state, activity: e.target.value })}
              value={state.activity}
            />
          </div>
        )}

        {reason === "Leak" && state.leakMetrics !== null && (
          <div
            style={{
              display: "inline-grid",
              gridTemplateColumns: "auto auto auto",
              gridGap: "10px",
            }}
          >
            <strong>
              <Translate msg="leakLocation" />
            </strong>
            <strong>
              <Translate msg="repairCode" />
            </strong>
            <strong>
              <Translate msg="repairActivity" />
            </strong>

            {reason === "Leak" &&
              leakReasons?.map((leakReason, i) => (
                <Fragment key={i}>
                  <input
                    type="text"
                    readOnly={true}
                    disabled={true}
                    value={leakReason.leakLocation}
                    style={{ width: "35px" }}
                    required
                  />
                  <select
                    className="form-control"
                    onChange={(e) => {
                      const newReasons = [...leakReasons];
                      newReasons[i].repairCode = e.target.value;
                      setLeakReasons(newReasons);
                    }}
                    value={leakReason.repairCode}
                    required
                  >
                    <option value={""}>-- select --</option>
                    {leakRepairCodes.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>

                  <input
                    type="text"
                    onChange={(e) => {
                      const newReasons = [...leakReasons];
                      newReasons[i].activity = e.target.value;
                      setLeakReasons(newReasons);
                    }}
                    value={leakReason.activity}
                    minLength={1}
                    required
                  />
                </Fragment>
              ))}
          </div>
        )}

        <div>
          <button className="btn btn-primary m-3">
            <Translate msg="submit" />
          </button>
        </div>
      </form>

      {state.error && <FatalError />}
    </div>
  );
};
