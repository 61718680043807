import React, { createContext, useReducer, Reducer } from "react";

export const initialState = {
  items: (JSON.parse(window.sessionStorage["printedItems"] || null) || []) as Array<string>,
};

type State = typeof initialState;

export type Actions = { type: "add"; payload: string } | { type: "remove"; payload: string };

const reduce: Reducer<State, Actions> = (state, action) => {
  if (action.type === "add") {
    const itemsSet = new Set<string>([...state.items, action.payload]);

    const items = Array.from(itemsSet);

    window.sessionStorage["printedItems"] = JSON.stringify(items);

    return { ...state, items };
  }
  return state;
};

export type ContextProps = [React.ReducerState<typeof reduce>, React.Dispatch<Actions>];

export const PrintedItemsContext = createContext<Partial<ContextProps>>([]);

export const PrintedItemsProvider: React.FunctionComponent = ({ children }) => {
  const reducer = useReducer(reduce, initialState);

  return <PrintedItemsContext.Provider value={reducer}>{children}</PrintedItemsContext.Provider>;
};
