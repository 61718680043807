import axios from "axios";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { BartenderLabel, ProductTypeApiEntity } from "../../../ts/types";
import { FatalError, Loading, Translate } from "../../common";
import { printBartenderLabel } from "../../printing/Printing";
import { AVAILABLE_PRINTERS } from "../packingStation/common";
import Form from "../workflows/common/form/Form";

/*
  Example product barcodes:
  90000709A07  (Summersea)
  90000584A06 (7 Kingdoms)
  90000896X04 (Eldora)

  Latest summersea:
  579, 1, 0, Server Module, 900-00709, A08, 90000709A08, 
  SERVER MODULE 2RP2-2VX1-1HVDC: SUMMERSEA, (EWJXV08T9)[A-Z0-9]{5}, , 2, 0, , , 60, 78, , , , , , , , , , , , , , , , , , 5, , , , , , , , , , 40, 41, 17, 18, 14, , , , , , , , , , , , 90000709, 2019-12-16 14:14:24, 

  Latest eldora:

*/

// TODO  make this a column on product_type - can't right now as it breaks the database sync process
const getProductSerialNumberLabel = (product: ProductTypeApiEntity): BartenderLabel | null => {
  const description = product.description && product.description.toUpperCase();
  if (description && description.includes("ELDORA")) {
    return "450-10001_X04_for_900-00896_Eldora.btw";
  } else if (description && description.includes("SUMMERSEA")) {
    return "450-00212_900-00709_Summersea.btw";
  } else if (product.productname === "900-00584") {
    return "450-00212_900-00584_7-Kingdoms.btw"; // e.g. serial numbers with WALL
  }
  return null;
};

const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  gap: 5px;

  > * {
  }
`;

const savedPrinter = window.localStorage.getItem("printer") || "";

export const SerialNumberGen: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [userError, setUserError] = useState<string | null>(null);
  const [userFeedback, setUserFeedback] = useState<string | null>(null);
  const [fatalError, setFatalError] = useState<string | null>(null);
  const [productNotFound, setProductNotFound] = useState(false);
  const [printer, setPrinter] = useState(savedPrinter);
  const [printerStatus, setPrinterStatus] = useState("");
  const [product, setProduct] = useState<ProductTypeApiEntity | null>(null);

  const [confirmFormVisible, setConfirmFormVisible] = useState(false);

  const [coolItSerialNumber, setCoolItSerialNumber] = useState("");
  const [customerSerialNumber, setCustomerSerialNumber] = useState("");
  const [confirmCoolItSerialNumber, setConfirmCoolItSerialNumber] = useState("");
  const [confirmCustomerSerialNumber, setConfirmCustomerSerialNumber] = useState("");

  const confirmCoolItSerialNumberInputRef = useRef<HTMLInputElement>(null);
  const confirmCustomerSerialNumberInputRef = useRef<HTMLInputElement>(null);

  const resetForm = () => {
    setUserError("");
    setUserFeedback("");
    setPrinterStatus("");
    setFatalError("");
    setCoolItSerialNumber("");
    setCustomerSerialNumber("");
    setConfirmCoolItSerialNumber("");
    setConfirmCustomerSerialNumber("");
    setConfirmFormVisible(false);
  };

  const resetState = () => {
    resetForm();
    setProduct(null);
    setProductNotFound(false);
  };

  const loadProduct = async (barcode: string) => {
    setProductNotFound(false);
    setProduct(null);
    setLoading(true);

    try {
      const response = await axios.get<ProductTypeApiEntity | null>(`/api/products/${barcode}`);

      resetState();
      setProduct(response.data);
      if (!response.data) {
        setProductNotFound(true);
      }
    } catch (e) {
      console.error(e);
      setFatalError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const generateAndPrintSerialNumbers = async () => {
    if (!product) throw new Error("product can't be null");

    resetForm();
    const customerBartenderLabel = getProductSerialNumberLabel(product);
    if (!customerBartenderLabel) {
      setUserError("Error:  this product has no printer label configured");
      return;
    }

    try {
      const {
        data: [customerSerialNumber],
      } = await axios.post<string[]>("/api/stations/serialNumberGen/customer", {
        payload: {
          quantity: 1,
          ptid: product.ptid,
        },
      });
      if (!customerSerialNumber) {
        setUserError("This product does not support customer serial number generation.");
        return;
      }
      setCustomerSerialNumber(customerSerialNumber);

      const {
        data: [coolItSerialNumber],
      } = await axios.post<string[]>("/api/stations/serialNumberGen/coolIt", {
        payload: {
          quantity: 1,
          ptid: product.ptid,
        },
      });
      setCoolItSerialNumber(coolItSerialNumber);

      let result = await printBartenderLabel({
        printer,
        labelTemplate: `SerialNumberGen_CoolIT.btw`,
        labelParams: {
          SERIAL_NUMBER: coolItSerialNumber,
          PRODUCT_BARCODE: product.productname,
        },
        copies: 1,
      });
      if (result.status !== "ok") {
        setPrinterStatus(result.msg || "Error");
      }

      result = await printBartenderLabel({
        printer,
        labelTemplate: customerBartenderLabel,
        labelParams: {
          SERIAL_NUMBER: customerSerialNumber,
        },
        copies: 1,
      });
      if (result.status !== "ok") {
        setPrinterStatus(result.msg || "Error");
      }
      setConfirmFormVisible(true);

      confirmCoolItSerialNumberInputRef.current &&
        confirmCoolItSerialNumberInputRef.current.focus();
    } catch (e) {
      setFatalError(e.toString());
    }
  };

  if (!product) {
    return (
      <div>
        <Form
          inputs={[
            {
              i18nLabel: "productBarcode",
              type: "text",
              name: "productBarcode",
              required: true,
              pattern: "[A-Za-z0-9]{3,30}",
            },
          ]}
          trimInputs={true}
          onSubmit={(formData) => {
            loadProduct(formData.productBarcode);
          }}
        />
        {productNotFound && (
          <div className="alert alert-warning">
            <Translate msg="productNotFound" />
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {fatalError && <FatalError />}

      {loading && <Loading />}

      <section>
        {product && (
          <div>
            <div className="alert-DISABLED">
              <table className="table table-sm ">
                <thead>
                  <tr>
                    <th>
                      <Translate msg="productId" />:
                    </th>
                    <th>
                      <Translate msg="productName" />:
                    </th>
                    <th>
                      <Translate msg="productDescription" />:
                    </th>
                    <th>
                      <Translate msg="productBarcode" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{product.ptid} </td>
                    <td>{product.productname}</td>
                    <td>{product.description}</td>
                    <td>{product.barcode} </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {product && product.latest_rev && product.latest_rev.barcode !== product.barcode && (
              <div className="alert alert-danger">
                <strong className="text-danger ml-3">
                  <Translate msg="productRevWarning" args={[product.latest_rev.barcode]} />
                </strong>
                <br />
                <button
                  className="mt-3 btn btn-primary"
                  onClick={async () => {
                    await loadProduct(product.latest_rev.barcode);
                  }}
                >
                  <Translate msg="productRevSwitch" args={[product.latest_rev.barcode]} />
                </button>
              </div>
            )}
          </div>
        )}
      </section>

      <div className="alert alert-warning d-flex align-items-center">
        <label className="d-flex align-items-center mr-3">
          Printer:
          <select
            name="printer"
            className="form-control mr-2 ml-2"
            style={{ width: 250 }}
            value={printer}
            onChange={(e) => {
              localStorage.setItem("printer", e.target.value);
              setPrinter(e.target.value);
            }}
          >
            <option value="">-- select-- </option>
            {AVAILABLE_PRINTERS.map((printer) => (
              <option value={printer} key={printer}>
                {printer}
              </option>
            ))}
          </select>
        </label>
        Print Status: {printerStatus || "Idle"}
      </div>

      <div className="d-flex">
        <button className="btn btn-primary m-1" onClick={generateAndPrintSerialNumbers}>
          Generate Serial Number
        </button>
      </div>

      {confirmFormVisible && (
        <div>
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              setUserError("");
              resetForm();

              if (
                confirmCoolItSerialNumber !== coolItSerialNumber ||
                confirmCustomerSerialNumber !== customerSerialNumber
              ) {
                setUserError(
                  "Scanned serial numbers do not match what was generated!\n Please discard labels and Generate again"
                );
                return;
              } else {
                try {
                  await axios.post(`/api/stations/serialNumberGen/validate`, {
                    payload: {
                      serialNumbers: [confirmCoolItSerialNumber, confirmCustomerSerialNumber],
                    },
                  });

                  setUserFeedback(`
                    Success!:\n\n
                    Cool IT Serial Number: ${coolItSerialNumber}\n
                    Customer Serial Number: ${customerSerialNumber}\n
                  `);
                } catch (e) {
                  console.error(e);
                  setFatalError("Fatal error: " + e.toString());
                }
              }
            }}
          >
            <p>Please confirm by scanning labels.</p>

            <Grid>
              Cool IT Serial Number:
              <input
                ref={confirmCoolItSerialNumberInputRef}
                required
                minLength={3}
                style={{ width: 400 }}
                maxLength={30}
                autoComplete="off"
                type="text"
                name="confirmCoolItSerialNumber"
                value={confirmCoolItSerialNumber}
                onChange={(e) => setConfirmCoolItSerialNumber(e.target.value)}
                onKeyDown={(e) => {
                  if (e.which === 13) {
                    confirmCustomerSerialNumberInputRef.current &&
                      confirmCustomerSerialNumberInputRef.current.focus();
                  }
                }}
              />
              Customer Serial Number:
              <input
                ref={confirmCustomerSerialNumberInputRef}
                required
                minLength={3}
                maxLength={30}
                style={{ width: 400 }}
                autoComplete="off"
                type="text"
                name="confirmCustomerSerialNumber"
                value={confirmCustomerSerialNumber}
                onChange={(e) => setConfirmCustomerSerialNumber(e.target.value)}
              />
            </Grid>
            <br />
            <button
              className="btn btn-primary"
              disabled={!confirmCustomerSerialNumber.trim() || !confirmCoolItSerialNumber.trim()}
            >
              Submit
            </button>
          </form>

          {/* {coolItSerialNumber && <pre>Debug Info: Cool IT SN: {coolItSerialNumber}</pre>}
          {customerSerialNumber && <pre>Debug Info: Cust SN: {customerSerialNumber}</pre>} */}
        </div>
      )}
      <div />

      {userError && <div className="alert alert-danger mt-3">{userError}</div>}
      {userFeedback && (
        <div className="alert alert-info mt-3">
          {userFeedback
            .split("\n")
            .filter((t) => !!t)
            .map((text) => (
              <div key={text}>{text}</div>
            ))}
        </div>
      )}

      <p className="mt-3">
        <a
          className="btn btn-secondary"
          onClick={() => {
            setProduct(null);
          }}
          href="#0"
        >
          <Translate msg="changeProduct" />
        </a>
      </p>
    </div>
  );
};
