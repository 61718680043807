import React from "react";
import { AppContextPropTypes, WorkflowComponentPropTypes } from "../../../../../propTypes";
import { FormSubmitData, WorkflowComponentProps } from "../../../../../ts/types";
import MetricsForm from "./MetricsForm";
import ShowMetricsSummary from "./ShowMetricsSummary";

/**
 * The Functional Test prompts the user for a dynamic list of inputs based
 * on how on the 'headcount' of the product
 */
export default class FunctionalTestMetrics extends React.Component<WorkflowComponentProps> {
  static propTypes = WorkflowComponentPropTypes;
  static contextTypes = AppContextPropTypes;

  evaluateFunctionalTestMetrics = async ({ metrics }: { metrics: FormSubmitData }) => {
    const { product } = this.props.workflowState;

    const { minVoltage, maxVoltage, minRpm, maxRpm, minCurrent, maxCurrent } = product.metadata;

    const rpmLimitsEnabled = minRpm !== null && maxRpm !== null;
    const voltageLimitsEnabled = minVoltage !== null && maxVoltage !== null;
    const currentLimitsEnabled = minCurrent !== null && maxCurrent !== null;

    // we will set to false if there is any metric outside the limit
    let pass = true;

    // note: when min/max is not set for the product, we always give it a pass
    for (let [metric, v] of Object.entries(metrics)) {
      const value = v as number;

      if (rpmLimitsEnabled && metric.startsWith("pumpRpm")) {
        if (minRpm === null || maxRpm === null) throw new Error("not enabled");
        pass = pass && value >= minRpm && value <= maxRpm;
      } else if (currentLimitsEnabled && metric.startsWith("current")) {
        if (minCurrent === null || maxCurrent === null) throw new Error("not enabled");
        pass = pass && value >= minCurrent && value <= maxCurrent;
      } else if (voltageLimitsEnabled && metric.startsWith("voltage")) {
        if (minVoltage === null || maxVoltage === null) throw new Error("not enabled");
        pass = pass && value >= minVoltage && value <= maxVoltage;
      } else {
        console.log("unknown metric or limits not set: ", metric);
      }
    }

    return { pass: pass ? 1 : 0 };
  };

  render() {
    const { props } = this;
    const { workflowState } = props;

    const visible = workflowState.serialNumber && workflowState.product;

    if (!visible) {
      return null;
    }

    const { product, metrics, pass } = workflowState;

    const currentInput = {
      i18nLabel: "metrics.current",
      i18nSuffix: "units.milliamps",
      type: "number",
      name: "current",
      required: true,
      min: 1,
      max: 1000,
      step: 1,
      widthPx: 70,
      autoFocus: false,
    };

    const pumpRpmInput = {
      i18nLabel: "metrics.pump",
      i18nSuffix: "units.rpm",
      type: "number",
      name: "pumpRpm",
      required: true,
      min: 100,
      max: 10000,
      step: 1,
      widthPx: 70,
      autoFocus: false,
    };

    const voltageInput = {
      i18nLabel: "metrics.voltage",
      i18nSuffix: "units.volts",
      type: "number",
      name: "voltage",
      required: true,
      min: 0.1,
      max: 1000,
      step: 0.01,
      widthPx: 70,
      value: 12,
      autoFocus: false,
    };

    const metricTypes = [];

    for (let i = 1; i <= (product.headcount || 0); i++) {
      metricTypes.push(
        { ...currentInput, name: `current_${i}`, labelPrefix: `Head ${i} ` },
        { ...pumpRpmInput, name: `pumpRpm_${i}`, labelPrefix: `Head ${i} ` },
        { ...voltageInput, name: `voltage_${i}`, labelPrefix: `Head ${i} ` }
      );
    }
    if (metricTypes[0]) {
      metricTypes[0].autoFocus = true;
    }

    // the the results if metrics already entered
    if (metrics) {
      console.log("metricTypes", metricTypes);
      return (
        <div>
          <ShowMetricsSummary metricTypes={metricTypes} metrics={metrics} pass={pass} />
        </div>
      );
    }

    // show the form
    return (
      <div>
        <MetricsForm
          {...props}
          metricTypes={metricTypes}
          evaluateMetrics={this.evaluateFunctionalTestMetrics}
        />
      </div>
    );
  }
}
