import axios from "axios";
import React from "react";
import { BASE_URL } from "../../../../../../App";
import { AppContextPropTypes } from "../../../../../../propTypes";
import {
  LeakLocationTypesLookup,
  WorkflowComponentProps,
  LeakTestMetricsType,
} from "../../../../../../ts/types";
import { Translate } from "../../../../../common";
import LeakLocationButton from "./ProductLeakLocation";

function onlyUnique<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}

export default class LeakLocationForm extends React.Component<
  WorkflowComponentProps & {
    leakLocationTypes: LeakLocationTypesLookup;
  }
> {
  static contextTypes = AppContextPropTypes;

  state: {
    loading: boolean;
    error: string | null;
    productLeakLocationIndex: string;
    otherLeaks: string[];
  } = {
    loading: false,
    error: null,
    productLeakLocationIndex: "",
    otherLeaks: Array(9).fill(""),
  };

  saveMetrics = async () => {
    const { props } = this;
    const { serialNumber } = props.workflowState;

    const leakLocationInt = parseInt(this.state.productLeakLocationIndex, 10);
    if (!(leakLocationInt >= 0 && leakLocationInt <= 99)) {
      console.error("Invalid");
      return;
    }

    await axios.post(`/api/serialNumbers/${serialNumber}`, {
      payload: {
        productTypeId: props.workflowState.product.ptid,
      },
    });

    const otherLeakLocations = this.state.otherLeaks
      .map((x) => parseInt(x))
      .filter((n) => !isNaN(n))
      .filter(onlyUnique);

    const pass = [leakLocationInt, ...otherLeakLocations].every((n) => n === 0);

    await axios.post(`/api/serialNumbers/${serialNumber}/metrics`, {
      payload: {
        stationId: props.workflowProps.station.id,
        userId: this.context.user.uid,
        productId: props.workflowState.product.ptid,
        pass: pass,
        metrics: {
          leakLocation: leakLocationInt,
          otherLeaks: otherLeakLocations,
        } as LeakTestMetricsType,
      },
    });

    this.props.workflowApi.updateState({
      leakLocation: leakLocationInt,
    });

    this.props.workflowApi.updateState({
      pass: pass ? 1 : 0,
    });
  };

  render() {
    const { props, state } = this;
    const { workflowState } = props;
    const { product } = workflowState;
    const { leakLocationTypes } = props;

    const multipleLeaks = !!product.metadata.leakLocationsOverride;

    return (
      <div className="LeakTest__Locations">
        <div className="d-flex flex-column">
          <div className="d-flex">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.saveMetrics();
              }}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <label>
                <strong>
                  <Translate msg={"leakLocation"} />:
                </strong>
              </label>
              <input
                className="form-control form-control-sm"
                required={true}
                autoFocus={true}
                type="number"
                onChange={(e) => {
                  this.setState({ productLeakLocationIndex: e.target.value.trim() });
                }}
                value={state.productLeakLocationIndex}
                style={{ width: `40px` }}
                min={0}
                max={
                  product.metadata.leakLocationsOverride ?? product.metadata.leakLocations.length
                }
                step={1}
              />
              {multipleLeaks &&
                state.otherLeaks.map((n, i) => {
                  return (
                    <input
                      key={i}
                      className="form-control form-control-sm"
                      type="number"
                      onChange={(e) => {
                        const updatedOtherLeaks = [...state.otherLeaks];
                        updatedOtherLeaks[i] = e.target.value.trim();
                        this.setState({ otherLeaks: updatedOtherLeaks });
                      }}
                      value={n}
                      style={{ width: `40px`, marginLeft: "3px", marginRight: "3px" }}
                      min={1}
                      max={product.metadata.leakLocationsOverride || 999}
                      step={1}
                    />
                  );
                })}
              <button type="submit" className="btn btn-primary" disabled={state.loading}>
                <Translate msg="submit" />
              </button>
            </form>

            {!multipleLeaks && parseInt(state.productLeakLocationIndex) >= 0 && (
              <div>
                <LeakLocationButton
                  leakLocationTypes={leakLocationTypes}
                  product={product}
                  productLeakLocationIndex={
                    isNaN(parseInt(state.productLeakLocationIndex))
                      ? -1
                      : parseInt(state.productLeakLocationIndex)
                  }
                  onClick={() => {}}
                />
              </div>
            )}
          </div>

          <div
            className={`d-flex mt-2 ${product.metadata.leakLocationsOverride ? "flex-column" : ""}`}
          >
            <div className="d-flex flex-column">
              {multipleLeaks ? (
                <div className="d-inline-flex align-items-center">
                  <LeakLocationButton
                    leakLocationTypes={leakLocationTypes}
                    product={product}
                    productLeakLocationIndex={0}
                    onClick={() => this.setState({ productLeakLocationIndex: 0 })}
                  />
                  &nbsp;If Leak present: enter the corresponding location number from the image.
                </div>
              ) : (
                [0, ...product.metadata.leakLocations.map((loc) => loc.index)].map(
                  (locationIndex) => {
                    // Note: we add '0' because 'no leak' is not in the API response

                    return (
                      <LeakLocationButton
                        key={locationIndex}
                        leakLocationTypes={leakLocationTypes}
                        product={product}
                        productLeakLocationIndex={locationIndex}
                        onClick={() => this.setState({ productLeakLocationIndex: locationIndex })}
                      />
                    );
                  }
                )
              )}
            </div>

            <div>
              {product.imageurl ? (
                <img
                  style={{ width: product.imageurl === "90000896" ? "auto" : "600px" }}
                  src={`${BASE_URL}api/images/${product.imageurl}-leakpoints`}
                  alt={"Product Leak Locations"}
                />
              ) : (
                <span>Warning: No leak test image configured.</span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
