import React from "react";
import { AppContextPropTypes, WorkflowComponentPropTypes } from "../../../../../propTypes";
import { WorkflowComponentProps } from "../../../../../ts/types";
import { Translate } from "../../../../common";

export default class FunctionalTestHints extends React.Component<WorkflowComponentProps> {
  static propTypes = WorkflowComponentPropTypes;
  static contextTypes = AppContextPropTypes;

  render() {
    const { props } = this;
    const { workflowState } = props;

    const visible = !!workflowState.metrics;

    if (!visible) {
      return null;
    }

    const { product } = workflowState;

    const { minCurrent, maxCurrent, minRpm, maxRpm, minVoltage, maxVoltage } = product.metadata;

    return (
      <div>
        <h5>
          <Translate msg="details" />
        </h5>
        <p>
          <strong>
            <Translate msg="metrics.expectedCurrentRange" />:{" "}
          </strong>
          {minCurrent === null || maxCurrent === null ? (
            "Not Set"
          ) : (
            <span>
              {minCurrent} - {maxCurrent}
            </span>
          )}
        </p>

        <p>
          <strong>
            <Translate msg="metrics.expectedRpmRange" />:{" "}
          </strong>
          {minRpm === null || maxRpm === null ? (
            "Not Set"
          ) : (
            <span>
              {minRpm} - {maxRpm}
            </span>
          )}
        </p>

        <p>
          <strong>
            <Translate msg="metrics.expectedVoltageRange" />:{" "}
          </strong>
          {minVoltage === null || maxVoltage === null ? (
            "Not Set"
          ) : (
            <span>
              {minVoltage} - {maxVoltage}
            </span>
          )}
        </p>
      </div>
    );
  }
}
