import axios from "axios";
import React from "react";
import { AppContextPropTypes } from "../../../../../propTypes";
import { WorkflowComponentProps } from "../../../../../ts/types";
import { Translate } from "../../../../common";

export class AccousticTestMetrics extends React.Component<WorkflowComponentProps> {
  static contextTypes = AppContextPropTypes;

  state: {
    horizontal_pass: 0 | 1 | null;
    vertical_pass: 0 | 1 | null;
    formSubmitted: boolean;
    error: boolean;
    loading: boolean;
  } = {
    horizontal_pass: null,
    vertical_pass: null,
    formSubmitted: false,
    error: false,
    loading: false,
  };

  render() {
    const { props, state } = this;
    const { workflowState } = props;

    const visible = workflowState.serialNumber && workflowState.product;

    if (!visible) {
      return null;
    }

    const { metrics, pass } = workflowState;
    const { horizontal_pass, vertical_pass } = state;

    if (metrics) {
      return (
        <div>
          <div className={`alert ${pass ? "alert-info" : "alert-danger"}`}>
            <strong>
              <Translate msg="result" />:
            </strong>{" "}
            {pass ? <Translate msg="pass" /> : <Translate msg="fail" />}
            {!pass && (
              <div>
                <br />
                <Translate msg="reworkRequired" />
              </div>
            )}
          </div>
        </div>
      );
    }

    // show the form
    return (
      <div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            const { state, context } = this;
            const { workflowProps, workflowState } = this.props;

            const { horizontal_pass, vertical_pass } = state;

            this.setState({ error: false, loading: true });

            if (horizontal_pass === null) throw new Error("null not expected");
            if (vertical_pass === null) throw new Error("null not expected");

            const metrics = { horizontal_pass, vertical_pass };
            const pass = vertical_pass && horizontal_pass ? 1 : 0;

            try {
              const response = await axios.post(
                `/api/serialNumbers/${workflowState.serialNumber}/metrics`,
                {
                  payload: {
                    stationId: workflowProps.station.id,
                    userId: context.user.uid,
                    productId: workflowState.product.ptid,
                    pass,
                    metrics,
                  },
                }
              );
              const result = response.data;
              if (result.status !== "success") {
                throw new Error("success expected");
              }

              this.props.workflowApi.updateState({ metrics, pass });
            } catch (e) {
              this.setState({ error: true });
              console.error(e);
            } finally {
              this.setState({ loading: false });
            }
          }}
        >
          <br />
          <div className="d-flex align-items-center">
            <strong style={{ marginRight: "10px" }}>垂直方向#1 (Orientation #1 – Vertical)</strong>{" "}
            <Translate msg="pass" />
            <input
              type="radio"
              name="verticalPass"
              onChange={(e) => this.setState({ vertical_pass: 1 })}
              value={1}
              required
              style={{ marginRight: "40px", marginLeft: "10px" }}
            />
            <Translate msg="fail" />
            <input
              type="radio"
              name="verticalPass"
              onChange={(e) => this.setState({ vertical_pass: 0 })}
              value={0}
              required
              style={{ marginRight: "40px", marginLeft: "10px" }}
            />
          </div>
          <br />

          <div className="d-flex align-items-center">
            <strong style={{ marginRight: "10px" }}>
              水平方向#2 (Orientation #2 – Horizontal)
            </strong>{" "}
            <Translate msg="pass" />
            <input
              type="radio"
              name="horizontalPass"
              onChange={(e) => this.setState({ horizontal_pass: 1 })}
              value={1}
              required
              style={{ marginRight: "40px", marginLeft: "10px" }}
            />
            <Translate msg="fail" />
            <input
              type="radio"
              name="horizontalPass"
              onChange={(e) => this.setState({ horizontal_pass: 0 })}
              value={0}
              required
              style={{ marginRight: "40px", marginLeft: "10px" }}
            />
          </div>
          <br />
          <button
            className="btn btn-primary"
            type="submit"
            disabled={horizontal_pass === null || vertical_pass === null}
          >
            <Translate msg="submit" />
          </button>
        </form>

        {state.error && <div>Error, please reload</div>}
      </div>
    );
  }
}
