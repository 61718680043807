import React from "react";
import { AppContextPropTypes } from "../../../../propTypes";
import { WorkflowComponentProps } from "../../../../ts/types";
import { Translate } from "../../../common";

export default class ChangeProduct extends React.Component<WorkflowComponentProps> {
  static contextTypes = AppContextPropTypes;

  handleChangeProduct = () => {
    this.props.workflowApi.resetState();
  };

  render() {
    const { workflowState } = this.props;

    const shouldRender = !!workflowState.product;

    if (!shouldRender) {
      return null;
    }

    return (
      <div className="mt-3">
        <p>
          <a className="btn btn-secondary" onClick={this.handleChangeProduct} href="#0">
            <Translate msg="changeProduct" />
          </a>
        </p>
      </div>
    );
  }
}
