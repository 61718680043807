import axios from "axios";
import React from "react";
import { WorkflowComponentPropTypes, AppContextPropTypes } from "../../../../propTypes";
import { Translate } from "../../../common";
import { WorkflowComponentProps, ProductTypeApiEntity } from "../../../../ts/types";

export const DisplayProductSummary = ({ product }: { product: ProductTypeApiEntity }) => (
  <div>
    <table className="table table-sm ">
      <thead>
        <tr>
          <th>
            <Translate msg="productId" />:
          </th>
          <th>
            <Translate msg="productName" />:
          </th>
          <th>
            <Translate msg="productDescription" />:
          </th>
          <th>
            <Translate msg="productBarcode" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{product.ptid} </td>
          <td>{product.productname}</td>
          <td>{product.description}</td>
          <td>{product.barcode} </td>
        </tr>
      </tbody>
    </table>
  </div>
);

/**
 * Displays information about a product
 */
export default class ShowProductSummary extends React.Component<
  WorkflowComponentProps & { enableRevisionWarning: boolean }
> {
  static propTypes = {
    ...WorkflowComponentPropTypes,
  };

  static contextTypes = AppContextPropTypes;

  loadLatestProductRevision = async () => {
    const latestRevBarcode = this.props.workflowState.product.latest_rev.barcode;

    try {
      const response = await axios.get(`/api/products/${latestRevBarcode}`);

      const product = response.data || null;

      this.props.workflowApi.resetState();
      this.props.workflowApi.updateState({ product });
    } catch (e) {
      console.error(e);
      this.props.workflowApi.updateState({ error: true });
    }
  };

  render() {
    const { props } = this;
    const { product, serialNumber } = props.workflowState;
    const { enableRevisionWarning } = props;

    return (
      <section>
        {product && (
          <div>
            <div className=" ">
              <table className="table table-sm ">
                <thead>
                  <tr>
                    <th>
                      <Translate msg="productId" />:
                    </th>
                    <th>
                      <Translate msg="productName" />:
                    </th>
                    <th>
                      <Translate msg="productDescription" />:
                    </th>
                    <th>
                      <Translate msg="productBarcode" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{product.ptid} </td>
                    <td>{product.productname}</td>
                    <td>{product.description}</td>
                    <td>{product.barcode} </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {enableRevisionWarning &&
              product &&
              product.latest_rev &&
              product.latest_rev.barcode !== product.barcode && (
                <div className="alert alert-danger">
                  <strong className="text-danger ml-3">
                    <Translate msg="productRevWarning" args={[product.latest_rev.barcode]} />
                  </strong>
                  <br />
                  <button className="mt-3 btn btn-primary" onClick={this.loadLatestProductRevision}>
                    <Translate msg="productRevSwitch" args={[product.latest_rev.barcode]} />
                  </button>
                </div>
              )}
            {serialNumber && (
              <p>
                <strong>
                  <Translate msg="serialNumber" />:
                </strong>{" "}
                {serialNumber}
              </p>
            )}
          </div>
        )}
      </section>
    );
  }
}
