import En from "./en";

/* Chinese */
export const Cn: typeof En = {
  menu: {
    admin: "管理员",
    reports: "报告",
    chooseStation: "选择工作站",
    lotNumberGeneration: "批号生成",
  },
  stations: {
    "1": "序列号输入站",
    "2": "泄漏测试站",
    "3": "干重站",
    "4": "湿重站",
    "5": "功能测试站",
    "6": "噪音测试站",
    "9": "不良品离线纪录",
    "10": "维修站",
    "11": "成品 – 子部件序列号联系 页面 ",
    "12": "序列号关联 ",
    "13": "对齐测试",
    "14": "序列号关联测试",
    "15": "声屏测试结果",
    "17": "停留/衰減測試",
    "99": "最终检验",
    corsair: "Functional Test",
  },
  failureReasons: {
    Leak: "泄漏",
    "Coldplate defective": "铲齿不良",
    "Functional defective": "功能不良",
    "Tube defective": "水管子不良",
    "Bad or incorrect label": "标签不良",
    "Radiator defective": "水箱不良",
    "TIM damaged": "导热膏刮伤",
    "Bad Threads": "螺丝或螺牙不良",
    "Assembled the wrong way": "组装错误",
    "Component felt off": "組件脫落",
    "Other Cosmetic Issues": "其它外观不良",
    "Missing or Loose Component": "缺少或松散的组件",
    "Subpart Entry Error": "错误或无序列号关联记录",
    "Station Sequence Order Error": "测试顺序错误",
    "Child Serial Entry Error": "错误或无子部件序列号",
    Other: "其它",
  },
  select: "选择",
  signIn: "登录",
  signOut: "退出",
  signInPrompt: "请扫描或输入您的运营商徽章号码。",
  loading: "装载...",
  serialNotFoundSendPrevious: "序列未找到。发送到上一个电台",
  reworkRequired: "产品失败。发送到返工站",
  warning: "警告",
  leakAt: " 泄漏在",
  productId: "产品 ID",
  productInfo: "产品信息",
  productName: "产品名称",
  productDescription: "产品描述",
  productBarcode: "产品条码",
  productNotFound: "产品未找到",
  productRevWarning: "最新版本是 $1",
  productRevSwitch: "更改为修订版 $1",
  serialNumber: "流水号 (序列号)",
  submit: "提交结果",
  leakLocationPrompt: "输入泄漏位置",
  leakLocation: "输入泄漏位置",
  newProduct: "新产品",
  changeProduct: "改变产品",
  leakTestStation: "泄漏测试站",
  logOff: "注销",
  noLeak: "没有泄漏",
  pass: "通过",
  fail: "失败",
  invalidFormat: "格式无效。提示： $1",
  invalidSerialFormat: "无效的序列号格式",
  nextSerialNumber: "下一个序列号",
  enter: "进入",
  user: "用户",
  result: "结果",
  details: "细节",
  repairActivity: "维修活动",
  repairCode: "維修碼",
  metrics: {
    formatPattern: "格式模式",
    decibels126: "在12.6伏特分貝",
    decibels120: "在12.0伏特分貝",
    decibels114: "在11.4伏特分貝",
    current: "当前",
    pump: "泵",
    voltage: "电压",
    coldPlate: "冷盘",
    subpart: "分部",
    parentSerialNumber: "父序列号",
    partSerialNumber: "子件序列号",
    dryWeight: "干重",
    wetWeight: "湿重",
    weightDelta: "重量三角洲",
    minWeightDelta: "产品最小重量增量",
    maxWeightDelta: "产品最大重量增量",
    expectedCurrentRange: "预期电流范围",
    expectedRpmRange: "预期的RPM范围",
    expectedVoltageRange: "预期电压范围",
    pressure: "压力",
    minAllowedDecibels: "允许的最小分贝数",
    maxAllowedDecibels: "允许的最大分贝数",
    failureReason: "失败原因",
    alignmentTestResult: "校准测试结果",
  },
  units: {
    decibels: "分贝",
    grams: "克",
    milliamps: "毫安",
    volts: "伏",
    celsius: "摄氏",
    fahrenheit: "华氏",
    psi: "PSI",
    rpm: "RPM",
  },
  errors: {
    fatal: "发生意外错误，请重新加载页面并重试。",
    duplicateEntry: "双重输入： $1",
    productFailedPrevious: "产品上一站失败，发送到维修站。",
    productNotTested: "产品未在前一站进行测试。",
  },
};

export default Cn;
